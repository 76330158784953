import { createContext, useContext, PropsWithChildren } from 'react';

import { GuestProduct, GuestType } from '../../contexts/productContext/product';
import type { TimeRange as BaseTimeRange } from '../../contexts/timeslotsContext/timeslots';
import { UpsellItem } from '../../contexts/upsellContext/upsell';

export type TimeRange = Partial<BaseTimeRange>;

const GuestProductInSelectionContext = createContext({} as GuestProduct);

export interface GuestRate extends GuestType {
  rate?: number;
}

export type ProductProp = GuestProduct | UpsellItem;

interface ProductInSelectionProps {
  product: ProductProp;
}

export const GiftCardProductInSelectionProvider = ({
  product,
  children,
}: PropsWithChildren<ProductInSelectionProps>) => {
  const value = { ...product };

  return (
    <GuestProductInSelectionContext.Provider value={value}>
      {children}
    </GuestProductInSelectionContext.Provider>
  );
};

export const useGiftCardProductInSelection = () => {
  const context = useContext(GuestProductInSelectionContext);
  if (context === undefined) {
    throw new Error(
      'useGiftCardProductInSelection must be used within a GiftCardProductInSelectionProvider'
    );
  }
  return context;
};
