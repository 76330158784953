import { TranslationsContextProps, useTranslator } from '@eo-locale/react';
import { ref, object, string, StringSchema, BooleanSchema, boolean, array } from 'yup';
import ArraySchema from 'yup/lib/array';
import { MixedSchema } from 'yup/lib/mixed';

import { dateIsInThePast, minFourChars, minTwoChars, telephoneSchema } from '../fieldValidation';

import {
  BuyerAdditionalField,
  PerAttendeeAdditionalField,
  Contact,
  AdditionalField,
  BasicTypes,
} from './contact';
import {
  isBasicField,
  isBuyerAdditionalField,
  isCheckboxField,
  isMultiCheckboxField,
  isPerAttendeeAdditionalField,
  isRadioField,
  isWaiverField,
} from './typeGuards';
import { invoiceSchema } from './invoiceSchema';

/** It creates a yup validation schema for the contact form */
export const useValidation = (isInvoiceVisible: boolean, contact?: Contact) => {
  const { translate } = useTranslator();

  if (!contact) return object().shape({});

  const commonValidation = {
    legalNotice: boolean().isTrue(translate('please-agree-to-all-the-terms-and-conditions')),
    newsletter: boolean(),
  };

  return object().shape({
    ...commonValidation,
    contact: object().shape({
      ...fieldSchema(translate, contact.basicInformation),
      ...additionalFields(translate, contact.additionalInformation),
    }),
    invoice: invoiceSchema(translate, isInvoiceVisible, contact.invoice?.content),
  });
};

const additionalFields = (
  translate: translateFn,
  fields: (BuyerAdditionalField | PerAttendeeAdditionalField)[] = []
) => {
  const fieldList = fields.map((field) => {
    if (isBuyerAdditionalField(field)) return fieldSchema(translate, field);

    if (isPerAttendeeAdditionalField(field)) {
      return Object.entries(field).reduce(
        (allFields, [, attendeeFields]) => ({
          ...allFields,
          ...fieldSchema(translate, attendeeFields),
        }),
        {}
      );
    }
  });

  return fieldList.reduce<
    Record<string, StringSchema<string | undefined | null> | BooleanSchema | ArraySchema<any>>
  >((total, current) => ({ ...total, ...current }), {});
};

type translateFn = TranslationsContextProps['translator']['translate'];

const fieldSchema = (translate: translateFn, fields: AdditionalField[]) =>
  fields.reduce<
    Record<
      string,
      StringSchema<string | undefined | null> | BooleanSchema | ArraySchema<any> | MixedSchema
    >
  >(
    (allFields, field) => ({
      ...allFields,
      [field.id]: fieldItemSchema(translate, field),
    }),
    {}
  );

/** Validations (together with translations) based on the field type */
const fieldItemSchema = (translate: translateFn, field: AdditionalField) => {
  if (field.hidden) {
    return string().nullable();
  }

  if (isWaiverField(field) && field.required) {
    return boolean().isTrue(translate('please-read-and-accept-the-waiver'));
  }
  if (isCheckboxField(field) && field.required) {
    return string().equals(['true'], translate('this-is-required-field'));
  }

  if (isMultiCheckboxField(field)) {
    return array()
      .min(field.required ? 1 : 0, translate(requiredKey(field), { optionValue: field.label }))
      .of(string().required())
      .required();
  }

  let schema = string();

  if (field.required)
    schema = schema.required(translate(requiredKey(field), { optionValue: field.label }));

  if (isRadioField(field)) {
    // if field others then allow any objects not only string

    return schema.nullable();
  }

  if (!isBasicField(field)) return schema;

  const validationPerType: Record<BasicTypes, StringSchema> = {
    firstName: schema.matches(...minTwoChars(translate)),
    lastName: schema.matches(...minTwoChars(translate)),
    postalCode: schema.matches(...minFourChars(translate)),
    email: schema.email(translate('please-provide-a-valid-e-mail-address')),
    telephone: schema.test(...telephoneSchema(translate)),
    date: schema.test(...dateIsInThePast(translate)),
    emailConfirm: schema.oneOf(
      [ref('email'), null],
      translate('please-make-sure-email-address-are-the-same')
    ),
    text: schema.trim(),
    textarea: schema.trim(),
    number: schema.trim(),
  };

  return validationPerType[field.type];
};

const requiredKey = (field: AdditionalField) => {
  if (isBasicField(field) && field.type === 'email')
    return 'so-that-we-can-send-you-confirmation-email';

  if (isBasicField(field) && field.type === 'emailConfirm')
    return 'please-provide-your-e-mail-address-again';

  return 'please-provide-your-variable';
};
