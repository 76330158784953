import { useEffect } from 'react';

import { useCheckout } from '../../contexts/apiContext/checkout/checkoutContext';
import { useBookingCreate } from '../../contexts/apiContext/checkout/mutations/useBookingCreate';
import { useCart } from '../../contexts/cartContext/cartContext';
import { useRouter } from '../../contexts/routerContext/routerContext';

import { Payment } from './Payment';
import { Skeleton } from './Skeleton';

export const Checkout = () => {
  const { paymentId, setPaymentId } = useCheckout();
  const bookingCreate = useBookingCreate();
  const cart = useCart();
  const { setCurrentStep } = useRouter();

  const paymentSuccessHandler = () => {
    setCurrentStep('purchaseSuccess');
  };

  const paymentFailureHandler = () => {
    setCurrentStep('purchaseFailure');
  };

  const paymentRedirectHandler = (url: string) => {
    window.location.href = url;
  };

  useEffect(() => {
    bookingCreate.mutate();
  }, []);

  useEffect(() => {
    if (bookingCreate.data?.bookingId) {
      cart.setBookingId.mutate(bookingCreate.data.bookingId!);
    }
  }, [bookingCreate.data?.bookingId]);

  useEffect(() => {
    if (bookingCreate.isSuccess) {
      if (bookingCreate.data.noPaymentNeeded) {
        paymentSuccessHandler();
      } else {
        setPaymentId(bookingCreate.data.paymentId!);
      }
    } else if (bookingCreate.isError) {
      paymentFailureHandler();
    }
  }, [bookingCreate]);

  if (!paymentId) {
    return <Skeleton />;
  }

  return (
    <Payment
      paymentId={paymentId}
      successHandler={paymentSuccessHandler}
      failureHandler={paymentFailureHandler}
      redirectHandler={paymentRedirectHandler}
    />
  );
};
