import { styled, useWidget } from '@guest-widgets/core';
import { Box, Typography } from '@mui/material';

import { CartItem } from '../contexts/apiContext/cart/cart';
import { ThumbWithFallbackImage } from '../common/ThumbWithFallback';
import { ItemDetails } from '../Cart/Items/ItemDetails';

import { SoldItemPrice } from './SoldItemPrice';

interface SoldItemProps {
  item: CartItem;
}

export const SoldItem = ({ item }: SoldItemProps) => {
  const { widgetArea } = useWidget();
  const isGiftCardProduct = item?.product?.type === 'giftcard';
  return widgetArea.isSmall ? (
    <ContainerSmall>
      <ImageContainerSmall>
        <ThumbWithFallbackImage
          src={item.product.image?.thumbnailUrl ?? ''}
          alt={item.product.image?.alt}
          noImageIconFontSize={180}
        />
      </ImageContainerSmall>
      <TitleAndPrice>
        <Typography variant="h2">{item.product.name}</Typography>
        <SoldItemPrice item={item} />
      </TitleAndPrice>
      {!isGiftCardProduct && <ItemDetails cartItem={item} />}
    </ContainerSmall>
  ) : (
    <Container>
      <ImageContainer>
        <ThumbWithFallbackImage
          src={item.product.image?.thumbnailUrl ?? ''}
          alt={item.product.image?.alt}
          noImageIconFontSize={120}
        />
      </ImageContainer>
      {!isGiftCardProduct && (
        <SoldItemInfo>
          <Typography variant="h2">{item.product.name}</Typography>
          <ItemDetails cartItem={item} />
        </SoldItemInfo>
      )}
      <SoldItemPrice item={item} />
    </Container>
  );
};

const Container = styled(Box)(({ theme: { spacing }, widgetArea }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: spacing(8),
  flexGrow: 1,
}));

const SoldItemInfo = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'space-between',
}));

const ImageContainer = styled('div')(({ theme: { spacing } }) => ({
  position: 'relative',
  width: spacing(34),
  height: spacing(34),
  borderRadius: spacing(1),
  overflow: 'hidden',
  marginRight: spacing(4),
  flexShrink: 0,
}));

const ContainerSmall = styled(Box)(({ theme: { spacing } }) => ({
  paddingTop: spacing(8),
  flexGrow: 1,
}));

const ImageContainerSmall = styled(ImageContainer)(({ theme: { spacing } }) => ({
  width: '100%',
  height: 'auto',
  aspectRatio: 5 / 3,
}));

const TitleAndPrice = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: spacing(6),
  paddingBottom: spacing(5),
}));
