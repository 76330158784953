/**
 * Gets the first child element inside the shadow DOM of the given element
 * @param element The element to get the shadow DOM child from
 * @returns The first child element inside the shadow DOM, or the element itself if no shadow DOM
 */
export const getFirstShadowDomChild = (element: HTMLElement): HTMLElement => {
  const rootNode = element.getRootNode();

  // If the element is not in a shadow DOM, return the element itself
  if (!(rootNode instanceof ShadowRoot)) {
    return element;
  }

  // Get the shadow root's first child
  const shadowRoot = rootNode as ShadowRoot;
  // Convert NodeList to Array to use find()
  const rootChild = Array.from(shadowRoot.childNodes).find((child): child is HTMLElement => {
    return child instanceof HTMLElement && child.classList.contains('root');
  });

  if (rootChild) {
    return rootChild;
  }

  // If no element with root class found, return the element itself
  return element;
};
