import React, { PropsWithChildren, useRef } from 'react';
import { useTranslator } from '@eo-locale/react';
import { styled } from '@mui/material/styles';
import { Tooltip, Box, Button } from '@mui/material';
import { DayProps, useDayRender, ActiveModifiers } from 'react-day-picker';
import { classes } from '@guest-widgets/shared/src/classes';

import { TrafficLightStatus } from '../../../../contexts/timeslotsContext/timeslots';

export const CalendarDayPicker = (props: DayProps & { disabled?: boolean }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { buttonProps, activeModifiers, isHidden } = useDayRender(
    props.date,
    props.displayMonth,
    buttonRef
  );

  if (isHidden) return <></>;
  const isDisabled =
    props.disabled !== undefined
      ? props.disabled
      : !activeModifiers.available && !activeModifiers['high-demand'];
  return (
    <DayTooltip modifiers={activeModifiers}>
      <StyledButton
        variant="text"
        ref={buttonRef}
        {...buttonProps}
        disabled={isDisabled}
        className={classes.booking.dayPicker.dayElement}
      />
    </DayTooltip>
  );
};

const DayTooltip = ({ modifiers, children }: PropsWithChildren<{ modifiers: ActiveModifiers }>) => {
  const { translate } = useTranslator();

  const titleMapper: Record<TrafficLightStatus, string> = {
    available: 'available',
    'high-demand': 'high-demand-likely-to-sell-out-soon',
    'sold-out': 'sold-out-might-be-available-on-site',
  };

  const title = Object.entries(titleMapper).find(([key]) => !!modifiers[key])?.[1];

  const trafficLightClassMapper: Record<TrafficLightStatus, string> = {
    available: classes.booking.dayPicker.trafficLights.available,
    'high-demand': classes.booking.dayPicker.trafficLights.highDemand,
    'sold-out': classes.booking.dayPicker.trafficLights.soldOut,
  };

  const trafficLightClass = Object.entries(trafficLightClassMapper).find(
    ([key]) => !!modifiers[key]
  )?.[1];

  return (
    <Tooltip
      title={title ? translate(title) : ''}
      enterDelay={600}
      disableInteractive
      className={
        classes.booking.dayPicker.day +
        (trafficLightClass ? ` ${trafficLightClass}` : '') +
        (modifiers.selected ? ` ${classes.booking.dayPicker.selected}` : '') +
        (modifiers.today ? ` ${classes.booking.dayPicker.today}` : '')
      }
    >
      <Box>{children}</Box>
    </Tooltip>
  );
};

const StyledButton = styled(Button)(({ theme: { spacing } }) => ({
  minWidth: '3.5ch',
  width: '61%',
  margin: spacing(1.5, 0),
  '& > span': {
    opacity: 0.55,
  },
}));
