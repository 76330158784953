import { styled, Typography } from '@mui/material';
import { Text, useTranslator } from '@eo-locale/react';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';
import { UseFormRegister, UseFormSetValue, UseFormStateReturn } from 'react-hook-form';
import { DetailGiftCardProductDtoPriceTypeEnum } from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { useEffect } from 'react';

import { AmountCurrencyInput } from '../../common/AmountCurrencyInput';
import { GuestProduct } from '../../contexts/productContext/product';
import { GiftCardFormState } from '../../hooks/product/giftcard/useGiftCardFormValidation';
import { BasicField } from '../../contexts/contactContext/contact';

interface GiftCardAmountInputProps {
  giftCardProduct: GuestProduct | null;
  register: UseFormRegister<GiftCardFormState>;
  formState: UseFormStateReturn<GiftCardFormState>;
  setValue: UseFormSetValue<GiftCardFormState>;
  amountField: BasicField;
}

export const GiftCardAmountInput = ({
  giftCardProduct,
  formState,
  register,
  amountField,
  setValue,
}: GiftCardAmountInputProps) => {
  const { translate: t } = useTranslator();
  const { formatCurrencyCodeToSymbol } = useNumberFormatter(
    giftCardProduct?.companyInformation?.locale?.language
  );

  return (
    <AmountWrapper>
      <AmountCurrencyInput
        amount={Number(giftCardProduct?.price ?? 0)}
        field={amountField}
        formState={formState}
        register={register}
        setValue={setValue}
        helperText={
          (formState.errors.amount?.message as string) ??
          (giftCardProduct?.variablePrice
            ? t('amount-must-be-between', {
                minAmount: giftCardProduct?.variablePrice?.min,
                maxAmount: giftCardProduct?.variablePrice?.max,
              })
            : '')
        }
        error={Boolean(formState.errors.amount)}
        currency={formatCurrencyCodeToSymbol(giftCardProduct?.currency!)}
        disabled={giftCardProduct?.priceType === DetailGiftCardProductDtoPriceTypeEnum.Fixed}
      />
      <RecipientText variant="body1">
        <Text id="gift-card-recipient-value" />
      </RecipientText>
    </AmountWrapper>
  );
};
const AmountWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '16px',
}));
const RecipientText = styled(Typography)(({ theme: { spacing, palette } }) => ({
  color: palette.grey[800],
  fontWeight: 400,
  lineHeight: '22px',
  fontSize: spacing(4),
}));
