export const queryKeys = {
  CART: 'cart',
  CONTACT: 'contactData',
  PRICING: 'pricingData',
  PRODUCT: 'productData',
  SETTINGS: 'settingsData',
  TIMESLOTS: 'timeslotData',
  UPSELL: 'upsellData',
  GIFTCARD: 'giftcard',
};
