import { LanguageLocaleCode } from '@guest-widgets/shared/src/i18n/i18n';

import { CartDeposit, CartItem, CartState } from '../cart';
import { mapToItem, mapToPublicPrice, mapToTotals } from '../mapToCart';
import { ItemRequest } from '../../product/dtos/request';
import { mapToValidateRequest } from '../mapToRequest';
import { guestApi } from '../../product/guestApi';
import { TotalPrice } from '../../../cartContext/cart';

export const refreshCartState = async (
  cartState: CartState,
  customerCode: string,
  currencyCode: string,
  locale: LanguageLocaleCode,
  discountCodes?: string[],
  deposit?: CartDeposit
): Promise<CartState> => {
  const { items } = cartState;

  if (items.length === 0) {
    return {
      ...cartState,
      totals: {
        grand: mapToPublicPrice(0, currencyCode),
        subtotal: mapToPublicPrice(0, currencyCode),
        taxes: [],
        taxtotal: mapToPublicPrice(0, currencyCode),
      },
    };
  }
  const cartRequestItems: ItemRequest[] = items.map((item: CartItem) => mapToValidateRequest(item));

  const { data } = await guestApi.validateCart(
    { items: cartRequestItems, discountCodes },
    customerCode,
    locale
  );

  const totals: TotalPrice = { totals: mapToTotals(data, currencyCode) };
  // Maps to items including name and guestTypes that are not returned in validateCart
  const cartItems = data.lineItems.map((responseItem) => {
    const localItem = items.find((item) => item.id === responseItem.lineId);
    return mapToItem(responseItem, localItem as CartItem, currencyCode);
  });

  return {
    ...cartState,
    items: cartItems,
    ...totals,
    isValid: data.isValid,
    errors: data.lineItems.filter((item) => item.error).map((item) => item.error!.message),
  };
};
