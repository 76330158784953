import { PropsWithChildren, useMemo } from 'react';
import { Text } from '@eo-locale/react';
import { Typography, Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import RefreshIcon from '@mui/icons-material/Refresh';
import WarningRounded from '@mui/icons-material/WarningRounded';
import { useWidget } from '@guest-widgets/core';
import { useLocalStorage } from '@guest-widgets/shared/src/hooks/useLocalStorage';
import { BookingSkeleton } from '@guest-widgets/shared/src/components/BookingSkeleton';
import { useApplication } from '@guest-widgets/shared/src/contexts/appplicationContext/applicationContext';
import { useGetGiftCardParamId } from '@guest-widgets/shared/src/hooks/useGetGiftCardParamId';

import { ErrorContainer, ERROR_ICON_SIZE } from './StyledSharedComponents';
import { Skeleton as CartSkeleton } from './Cart/Skeleton';
import { Skeleton as UpsellSkeleton } from './Upsell/Skeleton';
import { useRouter } from './contexts/routerContext/routerContext';
import { useCartApi } from './contexts/apiContext/cart/useCartApi';
import { Step } from './contexts/routerContext/router';
import { cartId, emptyCart } from './contexts/apiContext/cart/session/useSession';
import { FormSkeleton } from './common/FormSkeleton';
import { useRouterStore } from './stores/router/useRouterStore';

interface LoadingErrorWrapperProps {
  isLoading?: boolean;
  message?: string;
  isError: boolean;
  errorCode?: number;
  custom?: Step;
}
const HTTPStatus = {
  INTERNAL_SERVER_ERROR: 500,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
};
export const LoadingErrorWrapper = ({
  message,
  isLoading,
  isError,
  errorCode,
  children,
  custom,
}: PropsWithChildren<LoadingErrorWrapperProps>) => {
  const [cartState] = useLocalStorage(cartId, emptyCart);

  const { destroy } = useCartApi();
  const { widgetArea } = useWidget();
  const { refresh } = useApplication();

  const onRefresh = () => {
    if (isErrorClient) {
      destroy.mutate(undefined, {
        onSuccess: () => refresh(),
      });
    } else {
      refresh();
    }
  };

  const isErrorClient = useMemo((): boolean => {
    return (
      errorCode !== undefined &&
      errorCode >= HTTPStatus.BAD_REQUEST &&
      errorCode < HTTPStatus.INTERNAL_SERVER_ERROR
    );
  }, [errorCode]);

  const isNotFoundError = errorCode !== undefined && errorCode === HTTPStatus.NOT_FOUND;

  const isErrorServer =
    errorCode !== undefined && errorCode >= HTTPStatus.INTERNAL_SERVER_ERROR && errorCode < 600;

  const { currentStep } = useRouter();
  const { giftCardId } = useGetGiftCardParamId();
  // Accessing cart data here because Product is not a child of CartProvider
  const hasItems = cartState.items.length > 0;

  const showButton = !(isNotFoundError && !hasItems);

  // "custom" is "product" when you render an upsell in a drawer so it only shows one month
  const numberOfMonths = custom === 'product' ? 1 : widgetArea.basedOnSize(1, 2, 3);
  //TODO Temporary solution(waiting for giftcard list to be implemented)

  const _renderSkeleton: Record<Step, JSX.Element> = {
    product: giftCardId ? <FormSkeleton /> : <BookingSkeleton numberOfMonths={numberOfMonths} />,
    upsell: <UpsellSkeleton />,
    contact: <FormSkeleton />,
    cart: <CartSkeleton />,
    checkout: <CartSkeleton />,
    purchaseFailure: <CartSkeleton />,
    purchaseSuccess: <CartSkeleton />,
  };

  const getErrorMessage = () => {
    if (isNotFoundError) return <Text id="product-not-found-message" />;
    if (isErrorClient) return <Text id="outdated-cart-message" />;
    if (isErrorServer) return <Text id="server-unreachable" />;
    return message || <Text id="generic-error-message" />;
  };

  if (isLoading && !isError)
    return <>{!!custom ? _renderSkeleton[custom] : _renderSkeleton[currentStep]}</>;

  if (isError)
    return (
      <ErrorContainer>
        <WarningRounded color="error" style={{ fontSize: ERROR_ICON_SIZE }} />
        <Typography variant="h2">
          <Text
            id={
              isNotFoundError
                ? 'product-not-found'
                : isErrorClient
                ? 'outdated-cart'
                : 'unable-to-load'
            }
          />
        </Typography>
        <Typography align="center">{getErrorMessage()}</Typography>
        {showButton ? (
          <Button
            variant="outlined"
            startIcon={
              isNotFoundError ? null : isErrorClient ? <ChevronLeftIcon /> : <RefreshIcon />
            }
            color="primary"
            onClick={onRefresh}
          >
            {isNotFoundError ? (
              <Text id="clear-cart" />
            ) : isErrorClient ? (
              <Text id="continue-shopping" />
            ) : (
              <Text id="reload-page" />
            )}
          </Button>
        ) : null}
      </ErrorContainer>
    );

  return <>{children}</>;
};
