import { AttachmentType, DateRestriction, UpsellItem } from '../../upsellContext/upsell';
import { LineItem, Upsell } from '../product/dtos/response';
import { removeTimezone } from '../product/formatDate';
import { mapToProduct } from '../product/mapToProduct';
import { SupportedCurrencies } from '../../settingsContext/settings';
import { CartItem } from '../cart/cart';
import { mapToGuestTypes } from '../product/mapToGuestTypes';
import { AllocationType } from '../../productContext/product';
import { PlatformEnum } from '../../../stores/company/types';
import { useCustomerPlatformStore } from '../../../stores/company/useCustomerPlatformStore';

import { Interval, mapAllocationToInterval } from './mapAllocationToInterval';
import mapConfigToSelectors from './mapConfigToSelectors';

const requiredUpsellIsSupportedFlag = import.meta.env.VITE_IS_REQUIRED_UPSELL_SUPPORTED === 'true';

export const mapToUpsells = (lineItem: LineItem, cartItem: CartItem): UpsellItem[] => {
  const { upsells } = lineItem;

  if (!upsells) return [];

  const parentAllocationType = cartItem.product.allocationType;
  const isAWSPlatform = useCustomerPlatformStore.getState().customerPlatform === PlatformEnum.AWS;

  const isRequiredUpsellExcluded = (attachmentType: string) =>
    !requiredUpsellIsSupportedFlag && attachmentType === AttachmentType.REQUIRED;
  const isPreselectedExcluded = (attachmentType: string) =>
    attachmentType === AttachmentType.PRESELECTED;

  const isExcludedByDateRestriction = (dateRestriction: DateRestriction) => {
    return ![DateRestriction.PARENT_DATES, DateRestriction.UNRESTRICTED].includes(dateRestriction);
  };

  const filteredUpsells = upsells.filter(
    ({ configuration: { dateRestriction, attachmentType } }) => {
      if (
        isRequiredUpsellExcluded(attachmentType) ||
        isPreselectedExcluded(attachmentType) ||
        (!isAWSPlatform && isExcludedByDateRestriction(dateRestriction as DateRestriction))
      ) {
        return false;
      }

      return true;
    }
  );

  const mappedUpsells = filteredUpsells.map((upsell) => {
    const inheritGuestTypes = upsell.configuration.restrictGuestTypeAdjustment;

    const guestTypes = mapToGuestTypes({
      guestTypes: upsell.product.guestTypes,
      parentQuantity: inheritGuestTypes ? cartItem.guestTypes : undefined,
    });
    const product = mapToProduct(upsell.price.gross.currencyCode as SupportedCurrencies, {
      ...upsell.product,
    });

    return {
      ...product,
      guestTypes,
      price: Number(upsell.price.gross.amount),
      parent: mapToParent(lineItem, upsell),
      configuration: mapToConfiguration(upsell, parentAllocationType),
      totalQuantity: 0,
      start: mapParentTimeToUpsell(upsell, cartItem)?.start,
      end: mapParentTimeToUpsell(upsell, cartItem)?.end,
    };
  });

  return mappedUpsells.filter((upsell) => upsell?.configuration?.uiSelectors?.render);
};

const mapToParent = (lineItem: LineItem, upsell: Upsell) => ({
  productId: upsell.configuration.productId.toString(),
  lineId: lineItem.lineId,
  start: removeTimezone(lineItem.start),
  end: removeTimezone(lineItem.end),
});

const mapToConfiguration = (upsell: Upsell, parentAllocationType: AllocationType) => ({
  attachmentType: upsell.configuration.attachmentType,
  dateRestriction: upsell.configuration.dateRestriction as DateRestriction,
  sameParentQuantity: upsell.configuration.restrictGuestTypeAdjustment,
  isRequired: upsell.configuration.attachmentType === AttachmentType.REQUIRED,
  allowTimeAdjustment: upsell.configuration.allowTimeAdjustment,
  inheritGuestTypes: upsell.configuration.restrictGuestTypeAdjustment,
  uiSelectors: mapToUiSelectors(upsell, parentAllocationType),
});

const mapToUiSelectors = (upsell: Upsell, parentAllocType: AllocationType) => {
  const { configuration, product } = upsell;
  const { dateRestriction } = configuration;

  const useParentDates = dateRestriction === DateRestriction.PARENT_DATES;
  // 'night' allocationType has the same behavior as 'day' allocationType (for parent and upsell)
  const parentAllocationType = parentAllocType === 'night' ? 'day' : parentAllocType;
  const upsellAllocationType = product.allocationType === 'night' ? 'day' : product.allocationType;
  // If upsellAllocationType is 'day' or 'night', allowTimeAdjustment should be always false (no time selection)
  const allowTimeAdjustment =
    upsellAllocationType === 'day' ? false : configuration.allowTimeAdjustment;
  // If useParentDates is false, doesn't matter how many guest types the product has
  const oneOrZeroGuestTypes = !useParentDates ? undefined : product.guestTypes?.length <= 1;

  const keyConfigurations = [
    Boolean(useParentDates),
    parentAllocationType,
    upsellAllocationType,
    Boolean(allowTimeAdjustment),
    Boolean(oneOrZeroGuestTypes),
  ].join(',');

  return mapConfigToSelectors.get(keyConfigurations)!;
};

const mapParentTimeToUpsell = (upsell: Upsell, parent: CartItem): Interval | undefined => {
  const { dateRestriction, allowTimeAdjustment } = upsell.configuration;
  const useParentDates = dateRestriction === DateRestriction.PARENT_DATES;

  const normalizeAllocationType = (type: string) => (type === 'night' ? 'day' : type);
  const parentAllocationType = normalizeAllocationType(parent.product.allocationType);
  const upsellAllocationType = normalizeAllocationType(upsell.product.allocationType);

  const parentStart = removeTimezone(parent.start);
  const parentEnd = removeTimezone(parent.end);

  const keyAllocations = `${parentAllocationType},${upsellAllocationType},${allowTimeAdjustment}`;

  return mapAllocationToInterval(keyAllocations, parentStart, parentEnd, useParentDates);
};
