import { useEffect } from 'react';
import { Box } from '@mui/material';
import { addDays, subDays } from 'date-fns';
import { booking } from '@guest-widgets/shared/src/classes/booking';
import { useWidget } from '@guest-widgets/core';

import { QuantitySelection } from '../../Product/Guest/QuantitySelection';
import { Time } from '../../Product/Guest/Time';
import {
  TimeslotsContextProvider,
  useTimeslots,
} from '../../contexts/timeslotsContext/timeslotsContext';
import { useUpsellInSelection } from '../upsellInSelectionContext';
import {
  ProductInSelectionProvider,
  useProductInSelection,
} from '../../Product/Guest/productInSelectionContext';
import { DateRestriction, UpsellItem } from '../../contexts/upsellContext/upsell';

import { useInnerDrawer } from './useInnerDrawer';

interface DateTimeQtyProps {
  item: UpsellItem;
}

const TimeQtyInner = ({ item }: DateTimeQtyProps) => {
  const { isMobileSize } = useWidget();
  const { handleQuantityChange, setAddToCartErrors } = useUpsellInSelection();
  const { date, time, quantity, setDate, setTime, requiresTimeSelection } = useProductInSelection();
  const { getTimeslotDayByDate } = useTimeslots();
  const { upsellApi } = useInnerDrawer();

  const { error, data } = upsellApi;
  const { error: pricingError } = data || {};
  const rateLimitError = ((error as Error)?.message ?? '').includes('429');
  const hasError = !!error || !!pricingError || rateLimitError;

  const quantityDependency = quantity
    ? Object.values(quantity).reduce((acc, value) => acc + value, 0)
    : 0;
  const { allocationType, configuration, start, end } = item;
  const endDateRange =
    allocationType === 'day' && date?.to?.day ? addDays(date.to.day, 1) : date?.to?.day;

  const { uiSelectors, dateRestriction, sameParentQuantity } = configuration;
  const showTime = uiSelectors?.showSteps?.includes('time');
  const showQuantity = uiSelectors?.showSteps?.includes('quantity');

  // Sets date and time to same as parent time when there is no date or time selector
  // (same parent day and not allowed change time cases)
  useEffect(() => {
    const isSameParentDate = dateRestriction === DateRestriction.PARENT_DATES;

    if (isSameParentDate && start && end) {
      const dateStart = new Date(start);
      const dateEnd = new Date(end);
      // When allocation type is "day", the "end" value in the cart adds 1 day because of API restrictions,
      // to use this value in the app we need to subtract 1 day
      const fixedEndDayByType = allocationType === 'day' ? subDays(dateEnd, 1) : dateEnd;

      setDate({
        from: getTimeslotDayByDate(dateStart),
        to: getTimeslotDayByDate(fixedEndDayByType),
      });

      if (requiresTimeSelection && !showTime) {
        setTime({ startTime: dateStart, endTime: fixedEndDayByType });
      }
    }
  }, []);

  // Updates upsell when date, time or quantity are chaged in modal
  useEffect(() => {
    if (quantity === undefined) return;

    const start = time?.startTime ?? date?.from?.day;
    const end = time?.endTime ?? endDateRange;

    handleQuantityChange({ upsell: item, quantity, start, end, isModal: true });
  }, [quantityDependency, date?.from?.day.valueOf(), date?.to?.day.valueOf()]);

  // Resets errors when time or quantity is changed
  useEffect(() => {
    setAddToCartErrors([]);
  }, [
    time?.startTime?.toLocaleTimeString(),
    time?.endTime?.toLocaleTimeString(),
    Object.values(quantity ?? {}).join('_'),
  ]);

  return (
    <Box pt={isMobileSize() ? 2 : 0} className={booking.steps}>
      {showTime && <Time hasError={hasError} error={pricingError} />}
      {showQuantity && <QuantitySelection disableButtons={sameParentQuantity} />}
    </Box>
  );
};

export const TimeQty = ({ item }: DateTimeQtyProps) => (
  <TimeslotsContextProvider product={item}>
    <ProductInSelectionProvider product={item}>
      <TimeQtyInner item={item} />
    </ProductInSelectionProvider>
  </TimeslotsContextProvider>
);
