import { Box, BoxProps, Typography, styled } from '@mui/material';
import { LineClamp } from '@guest-widgets/shared/src/components/LineClamp';
import { ReactNode } from 'react';

import { ThumbWithFallback } from '../ThumbWithFallback';
import { UpsellCardMessage } from '../../Upsell/UpsellCardMessage';
import { ErrorMessage } from '../../Upsell/ErrorMessage';
import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { CartItem } from '../../contexts/apiContext/cart/cart';
import { useItemCardInfo } from '../../hooks/useItemCardInfos';
import { useRouter } from '../../contexts/routerContext/routerContext';

import { PriceWrapper } from './StyledComponents';

export type ItemType = CartItem | UpsellItem;
type Props = {
  messageKeys?: string[];
  item: ItemType;
  children: ReactNode;
};

const ItemCartWrapper = ({ messageKeys = [], item, children }: Props) => {
  const { handleModal, error, image, product, isUpsellStep, isUpsellSelected } = useItemCardInfo(
    item
  );
  const { currentStep } = useRouter();

  return (
    <Info isSelected={isUpsellSelected} inCart={currentStep === 'cart'}>
      <Container>
        <ImageContainer onClick={handleModal} sx={{ cursor: isUpsellStep ? 'pointer' : 'default' }}>
          <ThumbWithFallback src={image?.thumbnailUrl ?? ''} alt={image?.alt} />
        </ImageContainer>
        <Box onClick={handleModal} sx={{ cursor: isUpsellStep ? 'pointer' : 'default' }}>
          <Typography variant="h4">
            <LineClamp lines={2}> {product?.name} </LineClamp>
          </Typography>
        </Box>
        <PriceWrapper>{children}</PriceWrapper>
      </Container>
      {Boolean(messageKeys?.length) &&
        messageKeys.map((messageKey) => (
          <UpsellCardMessage key={messageKey} messageKey={messageKey} />
        ))}
      {!!error && <ErrorMessage message={error} />}
    </Info>
  );
};

export default ItemCartWrapper;
const Container = styled('div')(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: '96px auto',
  gridTemplateRows: 'auto auto',
  columnGap: spacing(2),
  borderRadius: 8,
  height: 96,
  '&:before': {
    border: 'none',
  },
}));

const ImageContainer = styled(Box)({
  cursor: 'pointer',
  gridRowStart: 1,
  gridRowEnd: 'span 2',
  borderRadius: 4,
});
const Info = styled(
  ({ isSelected, inCart, ...props }: { isSelected: boolean; inCart: boolean } & BoxProps) => (
    <Box {...props} />
  )
)(({ theme: { spacing, palette }, isSelected, inCart }) => ({
  overflowX: 'hidden',
  paddingLeft: 0,
  borderRadius: spacing(2),
  ...(inCart ? {} : { border: `1px solid ${isSelected ? palette.grey[800] : palette.grey[400]}` }),
  padding: spacing(2),
}));
