import { styled } from '@guest-widgets/core';
import { Typography } from '@mui/material';
import QuantityErrorIcon from '@mui/icons-material/ProductionQuantityLimits';
import { QuantitySelector } from '@guest-widgets/shared/src/components/QuantitySelector/QuantitySelector';

import { PriceWithBreakLine } from '../../common/PriceWithBreakLine';
import { useUpsellInSelection } from '../upsellInSelectionContext';
import { ErrorMessage } from '../../Error';
import { AttachmentType, UpsellItem } from '../../contexts/upsellContext/upsell';
import { Quantity } from '../../contexts/apiContext/product/dtos/request';

import { useInnerDrawer } from './useInnerDrawer';

interface SimpleQtyProps {
  item: UpsellItem;
  itemPrice: string;
}

export const SimpleQty = ({ item, itemPrice }: SimpleQtyProps) => {
  const { handleQuantityChange } = useUpsellInSelection();
  const {
    upsellApi: { data },
  } = useInnerDrawer();

  const { totalQuantity, configuration } = item;

  const onQuantityChange = (qty: number) => {
    const quantity: Quantity = { [item.guestTypes[0].id]: qty };
    handleQuantityChange({ upsell: item, quantity, isModal: true });
  };

  return (
    <>
      <PriceWrapper>
        <Typography variant="body2" mr={2}>
          <PriceWithBreakLine price={itemPrice} />
        </Typography>
        <QuantitySelector
          quantity={totalQuantity}
          handleQuantityChange={(qty) => onQuantityChange(qty)}
          required={configuration.attachmentType === AttachmentType.REQUIRED}
        />
      </PriceWrapper>
      <ErrorMessage
        mt={0}
        mb={4}
        show={data?.error?.errorType === 'quantity' && !!totalQuantity}
        message={data?.error?.message}
        icon={QuantityErrorIcon}
      />
    </>
  );
};

export const PriceWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing(4),
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
}));
