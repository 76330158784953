import { useQuery } from '@tanstack/react-query';
import { companyApi } from '@guest-widgets/shared/src/apis/guestExperience';

import { useSettings } from '../contexts/settingsContext/settingsContext';
import { setCustomerPlatform } from '../stores/company/useCustomerPlatformStore';

export const useCustomerPlatformApi = () => {
  const { customerCode, locale } = useSettings();

  const queryDeps = ['company-platform', locale, customerCode];
  const enabled = Boolean(locale && customerCode);

  const queryFn = async () => {
    const response = await companyApi.getCustomerPlatform(customerCode, locale);

    setCustomerPlatform(response.platform);
    return response;
  };

  return useQuery({ queryKey: queryDeps, queryFn, enabled });
};
