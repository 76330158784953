import React from 'react';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';
import { Text } from '@eo-locale/react';
import { Box, Typography } from '@mui/material';

import { SelectorSwitcher } from '../SelectorSwitcher';
import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { useUpsellInSelection } from '../upsellInSelectionContext';
import { PriceWithBreakLine } from '../../common/PriceWithBreakLine';
import { PriceText } from '../../common/ItemCard/StyledComponents';
import { useUpsell } from '../../contexts/upsellContext/upsellContext';
import ItemCartWrapper from '../../common/ItemCard/ItemCartWrapper';
import { AddButton, ModifyButton } from '../../common/ItemCard/Buttons';

interface BaseUpsellItemProps {
  item: UpsellItem;
  FirstElement?: React.ComponentType<any>;
  SecondElement?: React.ComponentType<any>;
  messageKeys?: string[];
}

export const BaseUpsellItem = ({
  item,
  messageKeys,
  FirstElement: CustomFirstElement,
  SecondElement: CustomSecondElement,
}: BaseUpsellItemProps) => {
  const { price, totalQuantity, companyInformation, currency, configuration } = item;
  const { setIsDrawerOpen, setUpsellInModal, isUpsellInCart } = useUpsellInSelection();
  const { setIsInitialDate, setIsInitialTime } = useUpsell();
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);

  const itemPrice = price != null ? formatPrice(price, currency) : '';

  const handleModal = () => {
    if (!item || !Object.values(item).length) return;
    setIsInitialDate(true);
    setIsInitialTime(true);
    setUpsellInModal(item);
    setIsDrawerOpen(true);
  };

  const FirstElement = CustomFirstElement || (() => <AddButton onClick={handleModal} />);
  const SecondElement = CustomSecondElement || (() => <ModifyButton onClick={handleModal} />);
  const isSelectedUpsell = configuration.isRequired || isUpsellInCart(item).isInCart;

  const SelectorSwitcherType = isSelectedUpsell
    ? configuration.uiSelectors.drawerLayout === 'singleQty'
      ? 'QuantitySelector'
      : 'Edit'
    : 'Add';

  return (
    <ItemCartWrapper item={item} messageKeys={messageKeys}>
      <Box display="flex" flexDirection="column" onClick={handleModal} sx={{ cursor: 'pointer' }}>
        {isSelectedUpsell && configuration.sameParentQuantity && (
          <Typography variant="caption" sx={{ opacity: 0.6, marginBottom: 0.5 }}>
            <Text id="for-variable-guests" quantity={totalQuantity} />
          </Typography>
        )}
        <PriceText variant="h3" onClick={handleModal} sx={{ cursor: 'pointer' }}>
          <PriceWithBreakLine price={itemPrice} />
        </PriceText>
      </Box>
      <SelectorSwitcher type={SelectorSwitcherType}>
        {isSelectedUpsell ? SecondElement : FirstElement}
      </SelectorSwitcher>
    </ItemCartWrapper>
  );
};
