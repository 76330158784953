import { LanguageLocaleCode } from '@guest-widgets/shared/src/i18n/i18n';
import { createParams } from '@guest-widgets/shared/src/apis/createParams';

import { ApiInvalidStructureException } from '../apiContext';
import { unwrap, getApiErrorMessage, createAxiosInstanceGuest } from '../axiosApiHelpers';

import type { TimeslotResponse } from './dtos/response';
import type { TimeslotRequest, UpsellsTimeslotRequest } from './dtos/request';

export const timeslotApi = {
  async getDaysWithTimeslots(
    customerCode: string,
    locale: LanguageLocaleCode,
    request: TimeslotRequest
  ): Promise<TimeslotResponse> {
    const axiosInstance = createAxiosInstanceGuest(customerCode);

    try {
      const response = await unwrap(
        axiosInstance.post<TimeslotResponse>(`availability`, request, {
          params: {
            with: ['rules'],
            ...createParams(locale),
          },
        })
      );

      // todo: GUEX-138 Remove timeslot sort once sorting is done on booking manager
      if (response?.data[0]) {
        response?.data[0]?.availabilityDates?.map(({ timeslots }) =>
          timeslots?.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
        );
      }

      return response;
    } catch (error: unknown) {
      throw new ApiInvalidStructureException(getApiErrorMessage(error));
    }
  },

  async getUpsellsDaysWithTimeslots(
    customerCode: string,
    locale: LanguageLocaleCode,
    request: UpsellsTimeslotRequest
  ): Promise<TimeslotResponse> {
    const axiosInstance = createAxiosInstanceGuest(customerCode);

    try {
      const response = await unwrap(
        axiosInstance.post<TimeslotResponse>(`upsells/availability`, request, {
          params: {
            with: ['rules'],
            ...createParams(locale),
          },
        })
      );

      return response;
    } catch (error: unknown) {
      throw new ApiInvalidStructureException(getApiErrorMessage(error));
    }
  },
};
