import { CartItem } from '../contexts/apiContext/cart/cart';
import { UpsellItem } from '../contexts/upsellContext/upsell';
import { useUpsell } from '../contexts/upsellContext/upsellContext';
import { useRouter } from '../contexts/routerContext/routerContext';
import { useUpsellInSelection } from '../Upsell/upsellInSelectionContext';

import { useInnerUpsell } from './upsell/useInnerUpsell';

export type ItemType = CartItem | UpsellItem;

const isCartItem = (item: ItemType): item is CartItem => {
  return 'product' in item && !!item.product;
};

export const useItemCardInfo = (item: ItemType) => {
  const {
    setUpsellInModal,
    setIsDrawerOpen,
    setIsModal,
    addToCartErrors,
    isUpsellInCart,
  } = useUpsellInSelection();
  const { setIsInitialDate, setIsInitialTime } = useUpsell();
  const { isSelectedUpsell } = useInnerUpsell();
  const { currentStep } = useRouter();

  const image = isCartItem(item) ? item.product.image : item.images?.[0];
  const product = isCartItem(item) ? item.product : item;
  const isUpsellStep = currentStep === 'upsell';
  const isSameParentQuantity = !isCartItem(item) && item.configuration?.sameParentQuantity;
  const error = !isCartItem(item) && isSelectedUpsell(item) ? addToCartErrors[0] : undefined;

  const handleModal = () => {
    if (!isUpsellStep || isCartItem(item) || !Object.values(item).length) return;

    setIsInitialDate(true);
    setIsInitialTime(true);
    setIsModal(true);
    setUpsellInModal(item);
    setIsDrawerOpen(true);
  };

  const isUpsellSelected = !isCartItem(item) ? isUpsellInCart(item).isInCart : false;
  return {
    handleModal,
    isSameParentQuantity,
    error,
    product,
    image,
    isUpsellStep,
    isUpsellSelected,
  };
};
