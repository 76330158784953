import {
  AdditionalField,
  BasicField,
  BasicTypes,
  CheckboxField,
  ContactForm,
  ContactFormFieldValue,
  CountryField,
  DropdownField,
  DynamicContact,
  HtmlField,
  MultiCheckboxField,
  RadioField,
} from '../../contactContext/contact';
import { ContactField, ContactFieldType } from '../product/dtos/response';

import { actionFactory } from './actionFactory';
import { DynamicFieldsAction } from './types';

const mapBaseSettings = (
  field: ContactField
): Pick<
  BasicField,
  'id' | 'required' | 'label' | 'tip' | 'onChange' | 'hidden' | 'value' | 'system'
> => {
  return {
    id: field.id,
    required: field.customer.required,
    label: field.label,
    tip: field.tip,
    value: field.defaultValue ?? '',
    hidden: field.hidden ?? field.filtered?.startHidden ?? false,
    system: field.system ?? false,
  };
};

type SystemFieldId =
  | 'customer_name'
  | 'customer_email'
  | 'customer_phone'
  | 'customer_address'
  | 'customer_city'
  | 'customer_postal_zip'
  | 'customer_region'
  | 'customer_country';

const mapSystemFieldToBasicType: Partial<Record<SystemFieldId, BasicTypes>> = {
  customer_email: 'email',
  customer_phone: 'telephone',
  customer_postal_zip: 'postalCode',
  customer_name: 'firstName',
};

const mapTextField = (field: ContactField, value?: ContactFormFieldValue): BasicField => {
  const type =
    field.system && field.id in mapSystemFieldToBasicType
      ? (mapSystemFieldToBasicType[field.id as SystemFieldId] as BasicTypes)
      : 'text';

  return {
    ...mapBaseSettings(field),
    type,
    ...(typeof value === 'string' ? { value } : {}),
  };
};

const mapTextArea = (field: ContactField, value?: ContactFormFieldValue): BasicField => {
  const actualValue = value || field.defaultValue;

  return {
    ...mapBaseSettings(field),
    type: 'textarea',
    ...(typeof actualValue === 'string' ? { value: actualValue } : {}),
  };
};

const mapCheckbox = (field: ContactField, value?: ContactFormFieldValue): CheckboxField => {
  //  But it is still not clear how to handle field.defaultValue
  const defaultValue = field.defaultValue ? true : false;

  return {
    ...mapBaseSettings(field),
    type: 'checkbox',
    checked: !!value,
    value: defaultValue,
    defaultValue,
  };
};

const mapSelect = (
  field: ContactField,
  value?: ContactFormFieldValue
): DropdownField | CountryField => {
  const defaultValue =
    field.choices &&
    field.defaultChoices &&
    field.defaultChoices.length > 0 &&
    field.defaultChoices[0] in field.choices
      ? field.choices[field.defaultChoices[0]]
      : undefined;

  return {
    ...mapBaseSettings(field),
    type: getDropdownType(field.id),
    options: field.choices?.map((label) => ({ label, value: label })) || [],
    ...(typeof value === 'string'
      ? { value }
      : typeof defaultValue === 'string'
      ? { value: defaultValue }
      : {}),
  };
};

const getDropdownType = (fieldID: string): 'country' | 'dropdown' => {
  return fieldID === 'customer_country' ? 'country' : 'dropdown';
};

const mapRadio = (field: ContactField, value?: ContactFormFieldValue): RadioField => {
  const defaultValue =
    field.choices &&
    field.defaultChoices &&
    field.defaultChoices.length > 0 &&
    field.defaultChoices[0] in field.choices
      ? field.choices[field.defaultChoices[0]]
      : undefined;

  return {
    ...mapSelect(field, value),
    type: 'radio',
    others: field.displayOther ?? false,
    defaultValue: defaultValue,
  };
};

const mapHtml = (field: ContactField, value?: ContactFormFieldValue): HtmlField => {
  return {
    ...mapBaseSettings(field),
    type: 'html',
    value: String(value || field.defaultValue),
  };
};

const mapMultiCheckbox = (
  field: ContactField,
  value?: ContactFormFieldValue
): MultiCheckboxField => {
  const choices = field.choices || [];
  const defaultChoices = field.defaultChoices || [];
  return {
    ...mapBaseSettings(field),
    type: 'multicheckbox',
    options: choices.map((label) => ({ label, value: label })),
    value: value || defaultChoices.map((valueIndex) => choices[valueIndex]),
  } as MultiCheckboxField;
};

const mapperByType: Record<
  ContactFieldType,
  (field: ContactField, value?: ContactFormFieldValue) => AdditionalField
> = {
  text: mapTextField,
  textarea: mapTextArea,
  select: mapSelect,
  checkbox: mapCheckbox,
  radio: mapRadio,
  html: mapHtml,
  multicheckbox: mapMultiCheckbox,
};

const supportedTypes: Partial<ContactField['type']>[] = [
  'text',
  'textarea',
  'select',
  'checkbox',
  'radio',
  'multicheckbox',
  'html',
];

export const mapToContact = (fields: ContactField[], form?: ContactForm): DynamicContact => {
  const dynamicActions: DynamicFieldsAction[] = [];

  const fieldsWithValues = fields
    .filter((field) => field.customer.form && !field.archived)
    .filter((field) => supportedTypes.includes(field.type))
    .map<AdditionalField>((field) => {
      const contactField = mapperByType[field.type](field, form?.contact?.[field.id]);

      const actions = actionFactory(field);
      dynamicActions.push(...actions);
      return contactField;
    });

  return {
    basicInformation: [],
    additionalInformation: [fieldsWithValues],
    dynamicActions,
  };
};
