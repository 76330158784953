import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { RouterState, Step } from './types';

const initialSteps: Array<Step> = [
  'product',
  'upsell',
  'contact',
  'cart',
  'checkout',
  'purchaseFailure',
  'purchaseSuccess',
];

export const initialRouterState = {
  currentStep: 'product' as Step,
  steps: initialSteps,
};

export const useRouterStore = create<RouterState>()(
  devtools(
    () => ({
      ...initialRouterState,
    }),
    { anonymousActionType: 'router' }
  )
);
