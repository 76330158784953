import { Step } from './types';
import { initialRouterState, useRouterStore } from './useRouterStore';

export const setSteps = (steps: Step[]) => {
  useRouterStore.setState({ steps });
};

export const setCurrentStep = (currentStep: Step) => {
  useRouterStore.setState((state) => ({
    stepBefore: state.currentStep,
    currentStep: currentStep,
  }));
};

const changeStep = (direction: 'next' | 'previous') => {
  const factor = direction === 'next' ? 1 : -1;
  useRouterStore.setState((state) => {
    const stepIndex = state.steps.indexOf(state.currentStep);
    return {
      stepBefore: state.currentStep,
      currentStep: state.steps[stepIndex + factor],
    };
  });
};

export const nextStep = () => {
  changeStep('next');
};

export const previousStep = () => {
  changeStep('previous');
};

export const resetRouterStore = () => {
  useRouterStore.setState({ ...initialRouterState });
};
