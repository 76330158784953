import { Button, Dialog, Stack, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Text } from '@eo-locale/react';
import { classes } from '@guest-widgets/shared/src/classes';

type Props = {
  open: boolean;
  onClose: () => void;
  policyContent: string;
  onAcceptPolicy: () => void;
};

const TosDialog = ({ open, onClose, policyContent, onAcceptPolicy }: Props) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogContent>
        <CloseIconWrapper onClick={onClose}>
          <CloseIcon />
        </CloseIconWrapper>
        <StyledDiv dangerouslySetInnerHTML={{ __html: policyContent as string }} />
        <StyledButton
          variant="contained"
          color="primary"
          fullWidth
          className={classes.booking.buttons.policyAccept}
          onClick={onAcceptPolicy}
        >
          <Text id="accept" />
        </StyledButton>
      </DialogContent>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)(({ theme: { spacing } }) => ({
  '& .MuiStack-root': {
    padding: spacing(4),
  },
  '& .MuiDialog-paper': {
    borderRadius: spacing(4),
    overflowY: 'hidden',
    textAlign: 'left',
  },
  '& .MuiDialogContent-root': {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-moz-scrollbar': {
      display: 'none',
    },
  },
}));

const DialogContent = styled(Stack)(({ theme: { spacing } }) => ({
  display: 'flex',
  minWidth: spacing(62),
  flexDirection: 'column',
  paddingInline: spacing(6),
  paddingBlock: spacing(8),
  maxHeight: '90vh',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '&::-moz-scrollbar': {
    display: 'none',
  },
}));

const CloseIconWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  position: 'absolute',
  width: spacing(10),
  height: spacing(10),
  top: spacing(4),
  right: spacing(4),
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}));

const StyledDiv = styled('div')(({ theme: { spacing, typography } }) => ({
  fontFamily: typography.fontFamily,
  lineHeight: spacing(6),
  fontSize: spacing(4),
  '& *': {
    margin: 0,
    padding: 0,
  },
  '& ol': {
    margin: 0,
    padding: spacing(6),
  },
}));

const StyledButton = styled(Button)(({ theme: { spacing, typography } }) => ({
  marginTop: spacing(4),
  padding: spacing(2, 5),
}));
export default TosDialog;
