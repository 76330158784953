import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Text } from '@eo-locale/react';
import { styled } from '@guest-widgets/core';

interface ButtonsProps {
  onClick: () => void;
}

export const DeleteButton = ({ onClick }: ButtonsProps) => (
  <Button
    variant="outlined"
    onClick={onClick}
    sx={{ minWidth: 'unset', padding: 2, borderWidth: 1 }}
  >
    <DeleteIcon sx={{ fontSize: 18 }} />
  </Button>
);

export const AddButton = ({ onClick }: ButtonsProps) => (
  <Button
    variant="outlined"
    onClick={onClick}
    sx={{ minWidth: 'unset', padding: '7px', borderWidth: 1 }}
  >
    <AddIcon sx={{ fontSize: 20 }} />
  </Button>
);

export const ModifyButton = ({ onClick }: ButtonsProps) => (
  <ModifyStyled variant="outlined" onClick={onClick}>
    <Text id="edit" />
  </ModifyStyled>
);

const ModifyStyled = styled(Button)(({ theme: { typography } }) => ({
  fontSize: typography.body2.fontSize,
  padding: '5px',
  borderWidth: 1,
}));
