import { useEffect } from 'react';

import { useSettings } from '../../contexts/settingsContext/settingsContext';
import { useCountryStore } from '../../stores/country';

export const useFetchCountries = () => {
  const { countries, fetchCountries } = useCountryStore();
  const { locale } = useSettings();

  useEffect(() => {
    fetchCountries(locale);
  }, [locale]);

  return { countries };
};
