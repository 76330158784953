import React from 'react';
import { Text } from '@eo-locale/react';
import { Typography, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useApplication } from '@guest-widgets/shared/src/contexts/appplicationContext/applicationContext';

import { ErrorContainer, ERROR_ICON_SIZE } from '../StyledSharedComponents';
import { useCartApi } from '../contexts/apiContext/cart/useCartApi';

export const PurchaseFailure = () => {
  const { setBookingId } = useCartApi();
  const { refresh } = useApplication();

  const onRefresh = () => {
    setBookingId.mutate(undefined, {
      onSuccess: () => refresh(),
    });
  };

  return (
    <ErrorContainer>
      <ErrorOutlineIcon color="error" style={{ fontSize: ERROR_ICON_SIZE }} />
      <Typography variant="h2">
        <Text id="purchase-not-performed" />
      </Typography>
      <Typography align="center">
        <Text id="for-some-unknown-reason-the-purchase-was-not-performed" />
      </Typography>
      <Button variant="outlined" color="primary" onClick={() => onRefresh()}>
        <Text id="reload-page" />
      </Button>
    </ErrorContainer>
  );
};
