import { Box, Collapse } from '@mui/material';
import { styled } from '@guest-widgets/core';

import { CartItem } from '../../contexts/apiContext/cart/cart';
import { useCart } from '../../contexts/cartContext/cartContext';
import { useUpsellMapper } from '../../hooks/upsell/useUpsellMapper';
import { UpsellItem } from '../../Upsell/ItemUpsellPrice/UpsellItem';

import { ItemDetails } from './ItemDetails';
import { CartItemComponent } from './CartItem';

export interface ItemInfoProps {
  item: CartItem;
}

export const ItemInfo = ({ item }: ItemInfoProps) => {
  const { cartWithSteps } = useCart();
  const { mapCartItemToUpsell } = useUpsellMapper();

  const upsellItems =
    cartWithSteps.data?.cart.items.filter(
      (cartItem) => cartItem.id.includes('.') && item.id === cartItem.id.split('.')[0]
    ) ?? [];

  return (
    <Container>
      <CartItemComponent item={item} />
      {item?.product?.type !== 'giftcard' && <ItemDetails cartItem={item} />}
      <Collapse in={!!upsellItems.length} unmountOnExit>
        <UpsellsContainer>
          {upsellItems.map((upsell) => (
            <Collapse key={upsell.product.productId} in={!!upsell.totalQuantity} unmountOnExit>
              <UpsellItem item={mapCartItemToUpsell(upsell)} />
              <UpsellItemDetailsContainer>
                <ItemDetails cartItem={upsell} />
              </UpsellItemDetailsContainer>
            </Collapse>
          ))}
        </UpsellsContainer>
      </Collapse>
    </Container>
  );
};

export const UpsellItemDetailsContainer = styled(Box)(({ theme: { spacing } }) => ({
  marginTop: spacing(2),
}));

export const Container = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: spacing(2),
}));

const UpsellsContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  marginLeft: spacing(8),
  paddingBlock: spacing(4),
}));
