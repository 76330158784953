import { useLocalStorage } from '@guest-widgets/shared/src/hooks/useLocalStorage';
import { useQueryRouter } from '@guest-widgets/shared/src/components/Router/QueryRouter';
import { v4 as uuidv4 } from 'uuid';
import { urlSearchParams } from '@guest-widgets/shared/src/utils/urlSearchParams';

import { useSettings } from '../../../settingsContext/settingsContext';
import { CartDeposit, CartItem, CartState, CartWithSteps, DepositOption } from '../cart';
import { useProduct } from '../../../productContext/productContext';

import { refreshCartState } from './refreshCartState';
import { addToCart } from './addToCart';
import {
  generateCartItemId,
  generateUpsellItemId,
  getLastCartItemId,
  getLastUpsellItemId,
  resetLastCartItemId,
} from './cartItemId';

export interface AddToCartResult {
  cartWithSteps: CartWithSteps;
  addedItemError?: string;
}

export interface CartSession {
  clearErrors(): void;
  getShoppingCart: () => CartWithSteps;
  addToCart: (items: CartItem[]) => Promise<AddToCartResult>;
  removeItem: (item: CartItem) => Promise<CartWithSteps>;
  destroy: () => CartWithSteps;
  setErrors: (errors: string[], isValid: boolean) => void;
  setProductContact: (productId: string, contactData: Record<string, string>) => void;
  refreshCart: () => Promise<CartWithSteps>;
  applyDiscountCode: (discountCode: string) => Promise<{ discountIsValid: boolean }>;
  removeDiscountCode: () => Promise<void>;
  cartState: CartState;
  generateItemId: () => string;
  getLastItemId: () => number;
  generateUpsellId: (parentLineId: string) => string;
  getLastUpsellId: () => number;
  setDeposit: (deposit: CartDeposit) => void;
  setBookingId: (bookingId: string | undefined) => void;
}

export const emptyCart: CartState = {
  id: uuidv4(),
  isValid: true,
  items: [],
  errors: [],
  totals: {
    grand: { gross: { amount: 0, currencyCode: 'EUR' } },
    subtotal: { gross: { amount: 0, currencyCode: 'EUR' } },
    deposits: [{ option: DepositOption.FULL, price: { amount: 0, currencyCode: 'EUR' } }],
    taxes: [],
    taxtotal: { gross: { amount: 0, currencyCode: 'EUR' } },
  },
  bookingId: undefined,
};
/**It creates a new checkout client */
export const useSession = (): CartSession => {
  const { customerCode, locale } = useSettings();
  const { currency: currencyCode } = useProduct();
  const { delete: deleteQueryProp } = useQueryRouter();
  const steps: CartWithSteps['steps'] = ['product', 'cart', 'contact', 'checkout'];
  const [cartState, setCartState] = useLocalStorage(cartId, emptyCart);
  const [discountCodes, setDiscountCodes] = useLocalStorage<string[]>(`${cartId}-discounts`, []);
  const [_, setDeposit] = useLocalStorage<CartDeposit>(`${cartId}-deposit`, {} as CartDeposit);

  const generateItemId = () => {
    return generateCartItemId();
  };

  const getLastItemId = () => {
    return getLastCartItemId();
  };

  const generateUpsellId = (parentLineId: string) => {
    return generateUpsellItemId(parentLineId);
  };

  const getLastUpsellId = () => {
    return getLastUpsellItemId();
  };

  const getShoppingCart = () => ({ cart: cartState, steps } as CartWithSteps);

  const addItemToCart = async (newCartItems: CartItem[]) => {
    const addToCartResult = await addToCart({
      cartState,
      newCartItems,
      currencyCode,
      customerCode,
      locale,
      steps,
      discountCodes,
    });

    setCartState(addToCartResult.cartWithSteps.cart);
    return addToCartResult;
  };

  const applyDiscountCode = async (discountCode: string) => {
    // TODO: We need to update the existing discount application to adjust the new one,
    // especially since multiple discounts are not functioning correctly.
    const newState = await refreshCartState(cartState, customerCode, currencyCode, locale, [
      discountCode,
    ]);

    const appliedDiscountForItem = newState.items.find((item) => {
      return item.discount?.name;
    });

    if (!!appliedDiscountForItem) {
      setDiscountCodes([discountCode]);
      setCartState(newState);
      window.dispatchEvent(new Event('storage'));
    }

    return {
      discountIsValid: !!appliedDiscountForItem,
    };
  };

  const removeDiscountCode = async () => {
    // TODO: simply send empty array as discounts because multiple discount is not working
    const newState = await refreshCartState(cartState, customerCode, currencyCode, locale, []);
    setDiscountCodes([]);
    window.dispatchEvent(new Event('storage'));
    setCartState(newState);
  };

  const removeItem = async (itemToRemove: CartItem) => {
    const newStateCandidate = {
      ...cartState,
      items: cartState.items.filter(
        (item) => !(item.id === itemToRemove.id || item.id.startsWith(`${itemToRemove.id}.`))
      ),
    };

    const appliedDiscountForItems = cartState.items.filter((item) => item.discount?.name);
    if (
      appliedDiscountForItems.length === 1 &&
      appliedDiscountForItems.find((discountItem) => discountItem.id === itemToRemove.id)
    ) {
      setDiscountCodes([]);
      window.dispatchEvent(new Event('storage'));
    }

    const newState = await refreshCartState(
      newStateCandidate,
      customerCode,
      currencyCode,
      locale,
      discountCodes
    );

    // remove item from cart
    setCartState(newState);
    return { cart: newState, steps } as CartWithSteps;
  };

  const refreshCart = async () => {
    const searchParams = urlSearchParams();
    if (searchParams.get('rwstep') === 'purchaseSuccess') {
      deleteQueryProp('rwstep');
      return;
    }

    const newState = await refreshCartState(
      cartState,
      customerCode,
      currencyCode,
      locale,
      discountCodes
    );

    setCartState(newState);
    return { cart: newState, steps } as CartWithSteps;
  };

  const destroy = () => {
    // destroy cart
    setCartState(emptyCart);
    resetLastCartItemId();
    setDiscountCodes([]);
    setDeposit({} as CartDeposit);
    return { cart: emptyCart, steps } as CartWithSteps;
  };

  const clearErrors = () =>
    setCartState({
      ...cartState,
      errors: [],
    });

  const setErrors = (errors: string[], isValid: boolean) =>
    setCartState({
      ...cartState,
      errors,
      isValid,
    });

  const setProductContact = (productId: string, contactData: Record<string, string>) => {
    setCartState((prevState) => {
      const updatedProduct = {
        ...prevState.items.find((item) => item.product.productId === productId),
      };
      if (!updatedProduct) {
        return prevState; // Return the previous state if the product is not found
      }
      updatedProduct.form = contactData;
      const updatedItems = prevState.items.map((item) =>
        item.product.productId === productId ? ((updatedProduct as unknown) as CartItem) : item
      );
      return {
        ...prevState,
        items: updatedItems,
      };
    });
  };

  const storeDeposit = (deposit: CartDeposit) => {
    setDeposit(deposit);
    window.dispatchEvent(new Event('storage'));
  };

  const setBookingId = (bookingId: string | undefined) => {
    setCartState((prevState) => ({
      ...prevState,
      bookingId,
    }));
  };

  return {
    cartState,
    generateItemId,
    getLastItemId,
    generateUpsellId,
    getLastUpsellId,
    getShoppingCart,
    addToCart: addItemToCart,
    applyDiscountCode,
    removeDiscountCode,
    removeItem,
    refreshCart,
    destroy,
    clearErrors,
    setErrors,
    setProductContact,
    setDeposit: storeDeposit,
    setBookingId,
  };
};

export const cartId = 'cart-guest-v1';
