import { useState, useEffect } from 'react';

import { isHtmlField, isTosField } from '../../contexts/contactContext/typeGuards';
import { useContact } from '../../contexts/contactContext/contactFormContext';
import { AdditionalFieldsType, BuyerAdditionalField } from '../../contexts/contactContext/contact';

function useExtractCustomerTosField() {
  const [customerTosFields, setCustomerTosField] = useState<BuyerAdditionalField | null>(null);
  const [remainingData, setRemainingData] = useState<AdditionalFieldsType>([]);
  const { additionalFields } = useContact();

  function divideTosFieldsArray(fields: BuyerAdditionalField) {
    return fields.reduce(
      (acc, field) => {
        if (
          (isHtmlField(field) && field.id === 'booking_policy' && field.system) ||
          isTosField(field)
        ) {
          acc.tosFields.push(field);
        } else {
          acc.otherFields.push(field);
        }
        return acc;
      },
      { tosFields: [], otherFields: [] } as {
        tosFields: BuyerAdditionalField;
        otherFields: BuyerAdditionalField;
      }
    );
  }

  const extractCustomerTosField = () => {
    const remainingData: AdditionalFieldsType = [];

    additionalFields?.forEach((item) => {
      if (Array.isArray(item)) {
        const { tosFields, otherFields } = divideTosFieldsArray(item);
        setCustomerTosField(tosFields);
        remainingData.push(otherFields);
      } else if (typeof item === 'object' && item !== null) {
        remainingData.push(item);
      }
    });
    setRemainingData(remainingData);
  };
  useEffect(() => {
    extractCustomerTosField();
  }, [additionalFields?.length]);

  return { customerTosFields, remainingData };
}

export default useExtractCustomerTosField;
