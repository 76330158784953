import { LanguageLocaleCode } from '@guest-widgets/shared/src/i18n/i18n';
import { createParams } from '@guest-widgets/shared/src/apis/createParams';

import { ApiInvalidStructureException } from '../apiContext';
import {
  createAxiosInstance,
  unwrap,
  getApiErrorMessage,
  createAxiosInstanceGuest,
} from '../axiosApiHelpers';

import type {
  ValidateResponse,
  Booking,
  BookingCreateResponse,
  ContactField,
  ContactFieldsResponse,
  BookingPaymentResponse,
} from './dtos/response';
import type {
  BookingCreateRequest,
  ItemRequest,
  ValidateRequest,
  PricingRequest,
} from './dtos/request';

export const guestApi = {
  async getPricing(
    customerCode: string,
    locale: LanguageLocaleCode,
    request: PricingRequest
  ): Promise<ValidateResponse> {
    return this.validate(
      {
        lineItems: [...request.lineItems],
        discountCodes: request.discountCodes,
      },
      customerCode,
      locale
    );
  },
  async createBooking(
    customerCode: string,
    locale: LanguageLocaleCode,
    request: BookingCreateRequest
  ): Promise<Booking> {
    const axiosInstance = createAxiosInstance(customerCode);

    try {
      const response = await unwrap(
        axiosInstance.post<BookingCreateResponse>(`booking-create`, request, {
          params: {
            language: locale.replace('-', '_'),
          },
        })
      );

      const { code, cfx, total } = response.data;
      return {
        code,
        cfx,
        total,
      };
    } catch (error: unknown) {
      throw new ApiInvalidStructureException(getApiErrorMessage(error));
    }
  },
  async getBookingPaymentId(
    customerCode: string,
    locale: LanguageLocaleCode,
    bookingCode: string,
    widgetId: number
  ): Promise<string> {
    const axiosInstance = createAxiosInstance(customerCode);

    try {
      const domainURLSuccess = createDomainURL('purchaseSuccess', widgetId.toString());
      const domainURLFailure = createDomainURL('purchaseFailure', widgetId.toString());

      const requestBody = {
        returnUrl: domainURLSuccess,
        cancelUrl: domainURLFailure,
      };
      const response = await unwrap(
        axiosInstance.post<BookingPaymentResponse>(
          `bookings/${bookingCode}/create-payment-request`,
          requestBody,
          {
            params: {
              language: locale.replace('-', '_'),
            },
          }
        )
      );

      return response.data.paymentId;
    } catch (error: unknown) {
      throw new ApiInvalidStructureException(getApiErrorMessage(error));
    }
  },
  async validate(
    request: ValidateRequest,
    partnerCode: string,
    locale: LanguageLocaleCode,
    params?: string[]
  ): Promise<ValidateResponse> {
    const axiosInstance = createAxiosInstanceGuest(partnerCode);

    try {
      return await unwrap(
        axiosInstance.post<ValidateResponse>(
          `cart/validate`,
          {
            ...request,
          },
          {
            params: {
              with: ['pricing', ...(params ?? [])],
              ...createParams(locale),
            },
          }
        )
      );
    } catch (error: unknown) {
      throw new ApiInvalidStructureException(getApiErrorMessage(error));
    }
  },
  validateContact(request: BookingCreateRequest, partnerCode: string, locale: LanguageLocaleCode) {
    return this.validate(
      {
        lineItems: request.cart.lineItems,
        bookingFields: request.cart.bookingFields,
      },
      partnerCode,
      locale
    );
  },
  validateCart(
    { discountCodes, items }: { items: ItemRequest[]; discountCodes?: string[] },
    partnerCode: string,
    locale: LanguageLocaleCode
  ) {
    return this.validate(
      {
        lineItems: items,
        discountCodes,
      },
      partnerCode,
      locale,
      ['upsells']
    );
  },
  async getContactFields(
    customerCode: string,
    locale: LanguageLocaleCode,
    productIds: string[]
  ): Promise<ContactField[]> {
    const axiosInstance = createAxiosInstanceGuest(customerCode);

    try {
      const response = await unwrap(
        axiosInstance.get<ContactFieldsResponse>('form-fields', {
          params: {
            applyTo: productIds,
            formType: 'booking',
            archived: false,
            ...createParams(locale),
          },
        })
      );

      return response.data;
    } catch (e: unknown) {
      throw new ApiInvalidStructureException(getApiErrorMessage(e));
    }
  },
};

const createDomainURL = (step: string, widgetId: string): string => {
  const url = new URL(window.location.href);
  url.searchParams.append('rwstep', step);
  url.searchParams.append('rwid', widgetId);
  return url.toString();
};
