import { createTheme, PaletteColorOptions, Theme, ThemeOptions } from '@mui/material';
import { PaletteColor } from '@mui/material/styles';
import {
  BOOKING_BUTTON_HEIGHT_FACTOR,
  calcBorderRadius,
  calcCssSize,
} from '@guest-widgets/shared/src/utils/themeCalculator';
import { grey } from '@mui/material/colors';
import merge from 'lodash/merge';

import { Settings } from '../settingsContext/settings';

import { calcLineHeight } from './calcLineHeight';
import { ThemeFactor } from './constants';

export const bookingTheme = (baseTheme: Theme, settings: Partial<Settings>) => {
  const { typography, spacing, palette } = baseTheme;

  const {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    body1,
    body2,
    caption,
    subtitle1,
    subtitle2,
    button,
    overline,
  } = typography;

  const { configuration } = settings;

  const fontSizeFactor = 1;
  const borderRadius = configuration ? configuration.borderRadius : undefined;

  /** Pseudo element to override borders with heritance and opacity */
  const pseudoBorder = {
    content: '""',
    width: '100%',
    height: 1,
    borderBottomColor: 'inherit',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    position: 'absolute',
    bottom: 0,
    opacity: 0.23,
  };

  const newTheme: ThemeOptions = {
    typography: {
      fontFamily: typography.fontFamily,
      h1: {
        fontFamily: typography.fontFamily,
        fontSize: calcCssSize(h1.fontSize, fontSizeFactor),
        lineHeight: calcLineHeight(h1.lineHeight),
      },
      h2: {
        fontFamily: typography.fontFamily,
        fontSize: calcCssSize(h2.fontSize, fontSizeFactor),
        lineHeight: calcLineHeight(h2.lineHeight),
      },
      h3: {
        fontFamily: typography.fontFamily,
        fontSize: calcCssSize(h3.fontSize, fontSizeFactor),
        lineHeight: calcLineHeight(h3.lineHeight),
      },
      h4: {
        fontFamily: typography.fontFamily,
        fontSize: calcCssSize(h4.fontSize, fontSizeFactor),
        lineHeight: calcLineHeight(h4.lineHeight),
      },
      h5: {
        fontFamily: typography.fontFamily,
        fontSize: calcCssSize(h5.fontSize, fontSizeFactor),
        lineHeight: calcLineHeight(h5.lineHeight),
      },
      h6: {
        fontFamily: typography.fontFamily,
        fontSize: calcCssSize(h6.fontSize, fontSizeFactor),
        lineHeight: calcLineHeight(h6.lineHeight),
      },
      body1: {
        fontFamily: typography.fontFamily,
        fontSize: calcCssSize(body1.fontSize, fontSizeFactor),
        lineHeight: calcLineHeight(body1.lineHeight),
      },
      body2: {
        fontFamily: typography.fontFamily,
        fontSize: calcCssSize(body2.fontSize, fontSizeFactor),
        lineHeight: calcLineHeight(body2.lineHeight),
      },
      caption: {
        fontFamily: typography.fontFamily,
        fontSize: calcCssSize(caption.fontSize, fontSizeFactor),
        lineHeight: calcLineHeight(caption.lineHeight),
      },
      subtitle1: {
        fontFamily: typography.fontFamily,
        fontSize: calcCssSize(subtitle1.fontSize, fontSizeFactor),
        lineHeight: calcLineHeight(subtitle1.lineHeight),
      },
      subtitle2: {
        fontFamily: typography.fontFamily,
        fontSize: calcCssSize(subtitle2.fontSize, fontSizeFactor),
      },
      button: {
        fontFamily: typography.fontFamily,
        fontSize: calcCssSize(button.fontSize, fontSizeFactor),
      },
      overline: {
        fontFamily: typography.fontFamily,
        fontSize: calcCssSize(overline.fontSize, fontSizeFactor),
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          ...baseTheme.components?.MuiButton?.styleOverrides,
          root: {
            fontSize: calcCssSize(
              typography.fontSize,
              fontSizeFactor * ThemeFactor.BUTTON_FONT_SIZE
            ),
            textTransform: 'none',
            borderRadius: calcBorderRadius(BOOKING_BUTTON_HEIGHT_FACTOR, borderRadius),
            '&.Mui-disabled': {
              borderColor: 'inherit',
              color: 'inherit',
              opacity: 0.3,
              '&:before': {
                opacity: 0,
              },
            },
          },
          containedSizeLarge: {
            fontSize: calcCssSize(
              typography.fontSize,
              fontSizeFactor * ThemeFactor.BUTTON_FONT_SIZE_CONTAINED
            ),
            paddingLeft: spacing(2),
            paddingRight: spacing(2),
            color: palette.getContrastText(palette.primary.main),
          },
          outlined: {
            fontSize: calcCssSize(
              typography.fontSize,
              fontSizeFactor * ThemeFactor.BUTTON_FONT_SIZE_OUTLINED
            ),
            border: '2px solid inherit',
            '&:before': {
              content: '""',
              width: '100%',
              height: '100%',
              borderColor: 'inherit',
              borderWidth: 2,
              borderStyle: 'solid',
              position: 'absolute',
              opacity: 0.33,
            },
          },
          outlinedPrimary: {
            borderWidth: 2,
            '&:hover': {
              borderWidth: 2,
            },
            '&:before': {
              opacity: 0,
            },
          },
          outlinedError: {
            borderWidth: 2,
            '&:hover': {
              borderWidth: 2,
            },
            '&:before': {
              opacity: 0,
            },
          },
          outlinedSizeMedium: {
            paddingTop: '0.31em',
            paddingBottom: '0.31em',
          },
          textSizeSmall: {
            fontSize: calcCssSize(
              typography.fontSize,
              fontSizeFactor * ThemeFactor.BUTTON_FONT_SIZE_SMALL
            ),
          },
          iconSizeMedium: {
            '& > *:first-child': {
              fontSize: calcCssSize(
                typography.fontSize,
                fontSizeFactor * ThemeFactor.BUTTON_FONT_SIZE_ICON
              ),
            },
          },
          iconSizeSmall: {
            '& > *:first-child': {
              fontSize: calcCssSize(
                typography.fontSize,
                fontSizeFactor * ThemeFactor.BUTTON_FONT_SIZE_ICON_SMALL
              ),
            },
          },
          text: {
            '&.Mui-disabled': {
              color: 'inherit',
            },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&.MuiAccordionSummary-root': {
              borderRadius: 4,
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          button: {
            fontSize: calcCssSize(typography.fontSize, fontSizeFactor * ThemeFactor.LINK_FONT_SIZE),
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: calcCssSize(
              typography.fontSize,
              fontSizeFactor * ThemeFactor.BUTTON_FONT_SIZE
            ),
          },
        },
      },
      MuiBadge: {
        styleOverrides: {
          badge: {
            fontSize: calcCssSize(
              typography.fontSize,
              fontSizeFactor * ThemeFactor.BADGE_FONT_SIZE
            ),
            padding: '0.8% 1.6%', // to make the badge bigger when we increase the font size
            height: 'auto',
            minHeight: 20,
            borderRadius: 15,
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            '& fieldset': {
              borderColor: 'inherit',
              opacity: 0.23,
              borderWidth: '1px',
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            '&:before': {
              borderBottomColor: 'inherit',
              opacity: 0.42,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: 'inherit',
          },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          shrink: true,
        },
        styleOverrides: {
          outlined: {
            color: 'inherit',
            opacity: 0.75,
            '&.MuiInputLabel-shrink': {
              transform: 'translate(0, -6px) scale(1)',
              lineHeight: 1.3,
              '&.Mui-focused': {
                color: 'inherit',
                '&.Mui-error': {
                  color: palette.error.main,
                  borderColor: palette.error.main,
                  opacity: 1,
                },
              },
            },
          },
          formControl: {
            position: 'unset',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 4,
            '&:not(&.Mui-error):hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'inherit',
              opacity: 0.5,
            },
            '&:not(&.Mui-error).Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'inherit',
              opacity: 1,
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              opacity: 1,
            },
          },
          notchedOutline: {
            borderColor: 'inherit',
            top: 0,
            '& legend': {
              display: 'none',
            },
          },
          input: {
            paddingTop: '0.78em',
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: '0.78em',
          },
          multiline: {
            padding: 0,
          },
          adornedStart: {
            paddingLeft: 18,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: calcCssSize(
              typography.fontSize,
              fontSizeFactor * ThemeFactor.HELPER_TEXT_FONT_SIZE
            ),
          },
          contained: {
            marginLeft: 0,
            marginRight: 0,
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: calcCssSize(typography.fontSize, fontSizeFactor * ThemeFactor.ICON_FONT_SIZE),
          },
          fontSizeSmall: {
            fontSize: calcCssSize(
              typography.fontSize,
              fontSizeFactor * ThemeFactor.ICON_FONT_SIZE_SMALL
            ),
          },
          fontSizeLarge: {
            fontSize: calcCssSize(
              typography.fontSize,
              fontSizeFactor * ThemeFactor.ICON_FONT_SIZE_LARGE
            ),
          },
          colorDisabled: {
            color: 'inherit',
            opacity: 0.12,
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            border: '1px solid inherit',
            '&:before': {
              height: 0,
              opacity: 0.23,
              borderTopColor: 'inherit',
              borderTopStyle: 'solid',
              borderTopWidth: 1,
            },
            '&.Mui-expanded': {
              margin: 0,
              '&:before': {
                opacity: 0.23,
              },
            },
            '&:last-child': {
              '&:after': {
                ...pseudoBorder,
              },
            },
          },
          rounded: {
            '&:first-child': {
              border: 'none',
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            paddingRight: 0,
            borderRadius: 4,
            '&.Mui-expanded': {
              minHeight: 'inherit',
            },
          },
          content: {
            margin: spacing(4.5, 0),
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            paddingLeft: spacing(2),
            paddingRight: spacing(2),
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: 'inherit',
            color: 'inherit',
            '&.MuiMenu-paper': {
              borderRadius: 4,
            },
          },
          outlined: {
            border: '1px solid inherit',
            '&:before': {
              content: '""',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              borderColor: 'inherit',
              borderWidth: 1,
              borderStyle: 'solid',
              position: 'absolute',
              opacity: 0.23,
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: 'inherit',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            color: 'inherit',
          },
          icon: {
            top: `calc(50% - ${12.5 * (configuration?.fontSize || fontSizeFactor)}px)`, // Multiply by font size factor to guarantee that the icon is always centered
            color: 'inherit',
            opacity: 0.5,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: 'inherit',
            opacity: 0.33,
          },
          colorSecondary: {
            '&.Mui-checked': {
              color: 'inherit',
              opacity: 0.73,
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: 'inherit',
            opacity: 0.33,
          },
          colorSecondary: {
            '&.Mui-checked': {
              color: 'inherit',
              opacity: 0.73,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: 'inherit',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: palette.background.default,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          input: {
            '&:first-child[class]': {
              paddingTop: '0.78em',
              paddingLeft: 6,
              paddingBottom: '0.78em',
            },
          },
          inputRoot: {
            '&[class]': {
              padding: 'unset',
              paddingLeft: 10,
            },
          },
          popper: {
            backgroundColor: palette.background.default,
            color: palette.text.primary,
          },
          endAdornment: {
            right: 9,
          },
          paper: {
            borderRadius: 4,
          },
        },
      },
      MuiSkeleton: {
        styleOverrides: {
          root: {
            backgroundColor: grey.A100,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            maxWidth: 120,
          },
          tooltipPlacementBottom: {
            '&[class]': {
              margin: 16,
            },
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          root: {
            '& .MuiStepConnector-line': {
              borderWidth: 2,
              borderColor: 'inherit',
              opacity: 0.23,
            },
            '&.Mui-active .MuiStepConnector-line, &.Mui-completed .MuiStepConnector-line': {
              borderColor: palette.primary.main,
              opacity: 1,
            },
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          labelContainer: {
            color: 'inherit',
          },
          label: {
            color: 'inherit',
            '&.Mui-active': {
              color: 'inherit',
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: 'inherit',
            borderWidth: 1,
            borderStyle: 'solid',
            opacity: 0.23,
          },
        },
      },
    },
  };

  return createTheme(merge({}, baseTheme, newTheme));
};
