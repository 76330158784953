import {
  TextField as MuiTextField,
  Button,
  Box,
  Typography,
  Chip as MuiChip,
  SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import ClearIcon from '@mui/icons-material/ClearRounded';
import SellIcon from '@mui/icons-material/Sell';
import { styled } from '@guest-widgets/core';
import { cssTextFieldValues } from '@guest-widgets/shared/src/components/QuantitySelector/helpers';
import { InputProps } from '@guest-widgets/shared/src/components/QuantitySelector/styles';
import { Text, useTranslator } from '@eo-locale/react';

import { useDiscount } from '../../hooks/cart/useDiscount';

export const Discount = () => {
  const { translate: t } = useTranslator();

  const {
    discountErrorMessage,
    discountCode,
    applyCode,
    handleChangeCode,
    localDiscountCode,
    removeDiscountCode,
  } = useDiscount();

  const hasError = !!discountErrorMessage;

  return (
    <DiscountContainer>
      <Typography color="inherit" variant="h4">
        <Text id="promo-code-coupon" />
      </Typography>
      <DiscountFormContainer>
        <TextField
          size={'medium'}
          fullWidth
          value={localDiscountCode || ''}
          onChange={handleChangeCode}
          placeholder={t('code')}
          type="text"
          sx={{ input: { padding: '0.66em' } }}
          error={hasError}
          helperText={hasError ? t(discountErrorMessage) : ''}
        />
        <Button
          size={'medium'}
          variant={!!localDiscountCode ? 'contained' : 'outlined'}
          color={hasError ? 'error' : 'primary'}
          disabled={!localDiscountCode}
          sx={{ padding: '0.44em 1.5em' }}
          onClick={() => {
            applyCode();
          }}
        >
          <Text id={'apply'} />
        </Button>
      </DiscountFormContainer>
      {!!discountCode && (
        <AppliedDiscount>
          <Chip
            label={discountCode}
            color="success"
            size="small"
            icon={<FlippedSellIcon fontSize="small" />}
            deleteIcon={<ClearIcon />}
            onDelete={removeDiscountCode}
          />
        </AppliedDiscount>
      )}
    </DiscountContainer>
  );
};

const DiscountContainer = styled(Box)(({ theme: { spacing, border } }) => ({
  marginTop: spacing(4),
  paddingBottom: spacing(5),
  borderBottom: border,
}));

const DiscountFormContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: spacing(4),
  marginTop: spacing(4),
}));

const TextField = styled(({ size, ...props }) => <MuiTextField {...props} />)(
  ({ size, theme: { spacing } }: InputProps) => ({
    '& input': {
      padding: cssTextFieldValues(size, spacing).padding,
      filter: 'invert(0.5)',
    },
  })
);

const AppliedDiscount = styled(Box)(({ theme: { spacing } }) => ({
  marginTop: spacing(1),
  display: 'flex',
  gap: spacing(1),
  alignItems: 'start',
}));

const FlippedSellIcon = styled(SellIcon)({
  transform: 'scaleX(-1)',
}) as OverridableComponent<SvgIconTypeMap>;

// todo: #64B473 find a best way to define this color and reuse it. Search with this color code in entire booking widget.
const Chip = styled(MuiChip)(({ theme: { palette } }) => ({
  backgroundColor: '#64B473',
  color: palette.text.primary,
  '& .MuiChip-deleteIcon': {
    color: palette.text.primary,
    transition: 'opacity 0.3s ease-in-out',
    '&:hover': {
      color: palette.text.primary,
      opacity: 0.6,
    },
  },
}));
