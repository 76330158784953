import { ListProductDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';

import {
  UpdateCartData,
  PageViewData,
  ViewProductData,
  ProductsData,
  ViewCartData,
  PurchaseData,
  BeginCheckoutData,
} from '../../customerTrackingCreator';
import { calculateItemDetails } from '../helpers';

import {
  AddToCartDataPaq,
  PageViewDataPaq,
  ProductListPaq,
  ViewCartPaq,
  ViewProductPaq,
} from './matomoTypes';

export class MatomoEventMapper {
  mapPageView = ({ title, virtualPageViewData }: PageViewData): PageViewDataPaq => {
    if (virtualPageViewData) {
      const { path } = virtualPageViewData;
      const location = window.location.origin + path;
      return {
        page_title: virtualPageViewData.title,
        page_location: location,
        page_referrer: document.referrer,
      };
    }
    return {
      page_title: title!,
      page_location: location.origin,
      page_referrer: document.referrer,
    };
  };

  mapToViewProduct = (data: ViewProductData): ViewProductPaq => {
    return {
      productSKU: data.settings.productId,
      name: data.product.name || '',
      category: data.settings.providerName || '',
      price: data.product.price || 0,
    };
  };
  mapToSelectProduct = ({ products: [product] }: ProductsData): ViewProductPaq => {
    return {
      productSKU: product.id,
      name: product.title || '',
      category: '',
      price: Number(product.price?.gross?.amount) || 0,
    };
  };

  mapToUpdateCart = ({ item }: UpdateCartData): AddToCartDataPaq => {
    const { start, end, guestTypes, product, price, id } = item;
    const { value, totalQuantity } = calculateItemDetails(item, price?.total.amount);
    let category: string = '';
    Object.keys(guestTypes)
      .filter((guestTypeKey) => {
        return guestTypes[guestTypeKey] !== 0;
      })
      .forEach((guestType) => {
        category += `${guestType}-${start}-${end}`;
      });

    return {
      productSKU: id,
      name: product.name ?? '',
      category,
      price: Number((value / totalQuantity).toFixed(2)),
      quantity: totalQuantity,
    };
  };

  mapToViewProductList = (products: ListProductDto[], listId?: string): ProductListPaq => {
    return {
      item_list_id: listId,
      item_list_name: listId,
      items: products.map((product) => {
        return {
          productSKU: product.id,
          name: product.title || '',
          category: '',
          price: Number(product.price?.gross?.amount) || 0,
        };
      }),
    };
  };

  mapToViewCart = ({ items, currency }: ViewCartData): ViewCartPaq => {
    const value = items.reduce((val, item) => {
      return (val += Number(item.price.total.amount) ?? 0);
    }, 0);

    return {
      value,
      currency,
      items: items.map((item) => {
        return {
          productSKU: item.id,
          name: item.product.name || '',
          category: '',
          price: Number(item.price.total.amount) || 0,
        };
      }),
    };
  };

  mapPurchase = (data: PurchaseData) => {
    const { bookingNumber: orderId, totals } = data;

    const discount = totals?.discountTotal?.amount ?? 0;
    const grandTotal = totals?.grand?.gross?.amount ?? 0;
    const subTotal = totals?.subtotal?.gross?.amount ?? 0;
    const taxTotal = totals?.taxtotal?.gross?.amount ?? 0;

    return [
      orderId,
      grandTotal,
      subTotal,
      taxTotal,
      false, //shiping is not available yet
      discount,
    ];
  };

  mapBeginCheckout = (beginCheckoutData: BeginCheckoutData) => {
    return {
      totalPrice: beginCheckoutData.totals?.grand?.gross?.amount,
      currency: beginCheckoutData.currency,
    };
  };
}
