import { useState, MouseEvent } from 'react';
import { Link, Typography, styled } from '@mui/material';
import { Text } from '@eo-locale/react';

import { BuyerAdditionalField } from '../contexts/contactContext/contact';
import { useContact } from '../contexts/contactContext/contactFormContext';
import { isTosField } from '../contexts/contactContext/typeGuards';
import useExtractCustomerTosField from '../hooks/contact/useExtractToField';

import { CheckboxView } from './Fields/CheckboxView';
import TosDialog from './TosDialog';

const TOService = () => {
  const { customerTosFields } = useExtractCustomerTosField();

  const [openPolicyDialog, setOpenPolicyDialog] = useState(false);
  const {
    form: { setValue },
  } = useContact();

  const openPolicyAgreementDialog = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenPolicyDialog(true);
  };

  const closePolicyAgreementDialog = () => setOpenPolicyDialog(false);

  const onAcceptPolicy = () => {
    if (Array.isArray(customerTosFields) && customerTosFields.length > 1) {
      setValue('contact.customer_policy_agree', true, { shouldValidate: true });
    }
    closePolicyAgreementDialog();
  };

  if (!customerTosFields || customerTosFields.length === 0) return null;

  const customerPolicyCheckbox = customerTosFields.find(isTosField);

  const Label = (
    <>
      {customerPolicyCheckbox ? (
        <Text id="read-consent" />
      ) : (
        <Acknowledge>
          <Text id="clicking-next-acknowledge" />
        </Acknowledge>
      )}{' '}
      <StyledLink onClick={openPolicyAgreementDialog}>
        <Text id="customer-policy-agree" />
      </StyledLink>
    </>
  );

  return (
    <TosWrapper>
      {customerPolicyCheckbox ? (
        <CheckboxView field={customerPolicyCheckbox} customLabel={Label} />
      ) : (
        Label
      )}
      <TosDialog
        open={openPolicyDialog}
        onClose={closePolicyAgreementDialog}
        policyContent={customerTosFields[0]?.value as string}
        onAcceptPolicy={onAcceptPolicy}
      />
    </TosWrapper>
  );
};

// Styled Components
const TosWrapper = styled('div')(({ theme: { spacing } }) => ({
  marginTop: spacing(4),
  marginBottom: spacing(4),
}));

const Acknowledge = styled(Typography)(({ theme: { spacing } }) => ({
  marginTop: spacing(4),
  fontSize: 14,
  lineHeight: spacing(6),
  display: 'inline',
}));

const StyledLink = styled(Link)(({ theme: { spacing } }) => ({
  textDecoration: 'underline',
  display: 'inline',
  cursor: 'pointer',
}));

export default TOService;
