import { PropsWithChildren, createContext, useContext, useEffect, useRef } from 'react';
import { UseMutationResult, UseQueryResult, useIsMutating } from '@tanstack/react-query';

import { ContactContextProvider } from '../contactContext/contactContext';
import { CheckoutContextProvider } from '../apiContext/checkout/checkoutContext';
import { useCartApi } from '../apiContext/cart/useCartApi';
import { CartItem, CartDeposit, CartWithSteps } from '../apiContext/cart/cart';
import { AddToCartResult } from '../apiContext/cart/session/useSession';
import { LoadingErrorWrapper } from '../../LoadingErrorWrapper';
import { ApiInvalidStructureException } from '../apiContext/apiContext';
import { useLoadingError } from '../../hooks/useLoadingError';

export type CartContextProviderProps = PropsWithChildren<{
  initialState?: CartWithSteps;
}>;

export interface CartContext {
  cartWithSteps: UseQueryResult<CartWithSteps, unknown>;
  hasItems: boolean;
  addToCart: UseMutationResult<AddToCartResult, unknown, CartItem[], unknown>;
  refreshCart: UseMutationResult<CartWithSteps, unknown, void, unknown>;
  removeItem: UseMutationResult<CartWithSteps, unknown, CartItem, unknown>;
  destroy: UseMutationResult<CartWithSteps, unknown, void, unknown>;
  applyDiscountCode: UseMutationResult<
    { discountIsValid: boolean },
    unknown,
    { discountCode: string },
    unknown
  >;
  removeDiscountCode: UseMutationResult<void, unknown, void, unknown>;
  setProductContact: UseMutationResult<
    CartWithSteps,
    unknown,
    { productId: string; contactData: Record<string, string> },
    unknown
  >;
  setDeposit: UseMutationResult<void, unknown, CartDeposit, unknown>;
  setBookingId: UseMutationResult<void, unknown, string | undefined, unknown>;
}

const cartContext = createContext({} as CartContext);

export const CartContextProvider = ({ children, ...rest }: CartContextProviderProps) => {
  const isMutating = useIsMutating({ mutationKey: ['refreshCart'] });
  const cartApi = useCartApi();
  const cartError = useRef<string | undefined>();

  useEffect(() => {
    cartApi.refreshCart.mutate();
  }, []);

  useEffect(() => {
    if (cartApi.refreshCart.isError) {
      cartError.current =
        cartApi.refreshCart.error instanceof ApiInvalidStructureException
          ? cartApi.refreshCart.error.message
          : undefined;
      cartApi.destroy.mutate();
    }
  }, [cartApi.refreshCart.isError]);

  const { isLoading, errorCode } = useLoadingError(
    cartApi.cartWithSteps.error,
    cartApi.cartWithSteps.fetchStatus,
    cartApi.cartWithSteps.status
  );

  const value = {
    ...cartApi,
    hasItems: !!cartApi.cartWithSteps?.data?.cart?.items?.length,
  };
  return (
    <cartContext.Provider value={value} {...rest}>
      <CheckoutContextProvider>
        <ContactContextProvider>
          <LoadingErrorWrapper
            isError={cartApi.cartWithSteps.isError || !!cartApi.refreshCart.isError}
            isLoading={isLoading || !!isMutating}
            errorCode={errorCode}
          >
            {children}
          </LoadingErrorWrapper>
        </ContactContextProvider>
      </CheckoutContextProvider>
    </cartContext.Provider>
  );
};

export const useCart = () => useContext(cartContext);
