import { createContext, PropsWithChildren, useContext, useState } from 'react';
import type { UseQueryResult } from '@tanstack/react-query';
import { isSameDay, startOfMonth } from 'date-fns';

import { useTimeslotApi } from '../apiContext/timeslot/useTimeslotApi';
import { LoadingErrorWrapper } from '../../LoadingErrorWrapper';
import { CartContext, useCart } from '../cartContext/cartContext';
import { useLoadingError } from '../../hooks/useLoadingError';
import { GuestProduct } from '../productContext/product';
import { UpsellItem } from '../upsellContext/upsell';
import { isUpsell } from '../../Product/Guest/productInSelectionContext';

import { AvailabilityTime, AvailabilityTimeDayTicket, Timeslots, TimeslotsDay } from './timeslots';

type BasicTimeslotFilters = {
  numberOfMonths?: number;
  dateFrom?: Date;
  nextAvailableDay?: boolean;
  showSoldOut?: boolean;
};

export interface TimeslotContext {
  timeslot: UseQueryResult<Timeslots, unknown>;
  filters: BasicTimeslotFilters;
  product: GuestProduct;
  setFilters: (filter: BasicTimeslotFilters) => void;
  getTimeslotDayByDate: (date: Date) => TimeslotsDay | undefined;
}

const timeslotContext = createContext({} as TimeslotContext);

export interface TimeslotContextProviderProps {
  initialData?: Timeslots;
  product: GuestProduct | UpsellItem;
}

export const TimeslotsContextProvider = ({
  children,
  initialData,
  product,
}: PropsWithChildren<TimeslotContextProviderProps>) => {
  const { trafficLights, displayTime, productId } = product || {};
  let start: string | undefined;
  const cartApi = useCart() as CartContext;
  const productIsUpsell = isUpsell(product);
  const [filters, setFilters] = useState<BasicTimeslotFilters>({
    showSoldOut: displayTime?.showUnavailable,
    nextAvailableDay: true,
  });
  const hasProduct = !!productId;

  if (productIsUpsell) {
    start = product.start;
  }

  const timeslot = useTimeslotApi({
    initialData,
    productId,
    // If the product is an upsell and have a start date (from cart), we need to request the timeslots from this date
    dateFrom: productIsUpsell && start ? startOfMonth(new Date(start)) : undefined,
    ...filters,
    enabled: hasProduct,
    trafficLights,
    cart: cartApi.cartWithSteps?.data?.cart,
    ...(productIsUpsell ? { upsellParentLineId: product.parent.lineId } : {}),
  });

  const { isLoading, errorCode } = useLoadingError(
    timeslot.error,
    timeslot.fetchStatus,
    timeslot.status
  );

  const getTimeslotDayByDate = (date: Date) => {
    return timeslot.data?.days.find(({ day }) => isSameDay(day, date));
  };

  const value: TimeslotContext = {
    timeslot,
    filters,
    product,
    setFilters: (newFilters) => setFilters((current) => ({ ...current, ...newFilters })),
    getTimeslotDayByDate,
  };

  return (
    <timeslotContext.Provider value={value}>
      <LoadingErrorWrapper
        isError={timeslot.isError}
        isLoading={isLoading}
        errorCode={errorCode}
        custom={productIsUpsell ? 'product' : undefined}
      >
        {children}
      </LoadingErrorWrapper>
    </timeslotContext.Provider>
  );
};

export const useTimeslots = () => useContext(timeslotContext);

export const isDayTicketTimeslot = (
  timeslot: AvailabilityTime
): timeslot is AvailabilityTimeDayTicket => !!(timeslot as AvailabilityTimeDayTicket).timeRange;

export const getTimeslotStartTime = (timeslot: AvailabilityTime): Date => {
  return isDayTicketTimeslot(timeslot) ? timeslot.timeRange.startTime : timeslot.time;
};
