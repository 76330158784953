import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslator } from '@eo-locale/react';

export type GiftCardFormState = {
  amount: number;
  to: string;
  from: string;
  email: string;
  message: string;
  deliveryDate: string;
};

export const useGiftCardFormValidation = (
  minAmount?: number,
  maxAmount?: number,
  defaultValues: Partial<GiftCardFormState> = {
    from: '',
    to: '',
    email: '',
    message: '',
  }
) => {
  const { translate } = useTranslator();

  const schema = yup.object({
    amount: yup
      .number()
      .required(translate('this-is-required-field'))
      .when([], {
        is: () => minAmount && maxAmount,
        then: (field) =>
          field.test(
            'is-valid-amount',
            translate('amount-must-be-between', { minAmount, maxAmount }),
            (value: any) => value >= minAmount! && value <= maxAmount!
          ),
        otherwise: (field) => field,
      }),
    to: yup
      .string()
      .min(2, translate('this-is-required-field'))
      .required(translate('this-is-required-field')),
    from: yup
      .string()
      .min(2, translate('this-is-required-field'))
      .required(translate('this-is-required-field')),
    email: yup
      .string()
      .email(translate('please-provide-a-valid-e-mail-address'))
      .required(translate('this-is-required-field')),
    message: yup
      .string()
      .max(100, translate('message-cannot-exceed-characters', { characterNumber: 100 }))
      .required(translate('this-is-required-field')),

    deliveryDate: yup.string().when({
      is: (exists: any) => {
        return exists !== undefined;
      },
      then: (schema) => schema.required(translate('this-is-required-field')),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const { register, handleSubmit, formState, setValue, getValues, control, watch } =
    useForm<GiftCardFormState>({
      resolver: yupResolver(schema),
      defaultValues,
    });

  return {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    watch,
  };
};
