import { useEffect, useState } from 'react';

import { useInnerUpsell } from '../../hooks/upsell/useInnerUpsell';
import { useUpsellMapper } from '../../hooks/upsell/useUpsellMapper';
import { useRemoveFromCart } from '../../hooks/cart/useRemoveFromCart';
import { useUpsellInSelection } from '../upsellInSelectionContext';
import { CartItem } from '../../contexts/apiContext/cart/cart';
import { useUpsell } from '../../contexts/upsellContext/upsellContext';
import { useUpsellApi } from '../../contexts/apiContext/upsell/useUpsellApi';

export const useInnerDrawer = () => {
  const { upsells } = useUpsell();
  const { addToCartHandler, upsellStatus, createCartItem } = useInnerUpsell();
  const { upsellInModal, setIsDrawerOpen, handleQuantityChange } = useUpsellInSelection();
  const [upsellToValidate, setUpsellToValidate] = useState<CartItem>(createCartItem(upsellInModal));
  const { mapUpsellToCartItem, mapGuestTypeToZero, mapGuestTypeToQuantity } = useUpsellMapper();
  const {
    openRemoveDialog,
    onCloseRemoveDialog,
    onDeleteCartItem,
    onOpenRemoveDialog,
  } = useRemoveFromCart({
    item: mapUpsellToCartItem(upsellInModal),
  });

  const { configuration } = upsellInModal;
  const withCalendar = configuration.uiSelectors.drawerLayout === 'twoColumns';
  const rateLimitError = (upsellStatus?.error as Error)?.message.includes('429');
  const { id, start, end, totalQuantity, guestTypes } = upsellInModal;

  // Validate upsell when total quantity changes
  useEffect(() => {
    if (start && end && totalQuantity) {
      setUpsellToValidate(createCartItem(upsellInModal));
    }
  }, [id, totalQuantity, start, end]);

  const upsellApi = useUpsellApi({ upsellsToValidate: [upsellToValidate], upsells });

  const removeFromCart = () => {
    handleQuantityChange({
      upsell: upsellInModal,
      quantity: mapGuestTypeToZero(guestTypes),
    });
    onDeleteCartItem();
    setIsDrawerOpen(false);
  };

  const addToCart = () => {
    handleQuantityChange({
      upsell: upsellInModal,
      quantity: mapGuestTypeToQuantity(guestTypes),
    });
    addToCartHandler([upsellInModal]);
  };

  return {
    withCalendar,
    rateLimitError,
    upsellApi,
    removeFromCart,
    addToCart,
    openRemoveDialog,
    onCloseRemoveDialog,
    onDeleteCartItem,
    onOpenRemoveDialog,
  };
};
