import { Text } from '@eo-locale/react';
import { Box, Typography } from '@mui/material';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';

import { useProduct } from '../../contexts/productContext/productContext';
import { PriceWithBreakLine } from '../../common/PriceWithBreakLine';

interface SubtotalProps {
  price: number;
  currency: string;
}

export const Subtotal = ({ price, currency }: SubtotalProps) => {
  const { companyInformation } = useProduct();
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: theme.border,
      })}
      py={4}
    >
      <Typography variant="body1">
        <Text id="subtotal" />
      </Typography>
      <Typography align="right">
        <PriceWithBreakLine price={formatPrice(price, currency)} />
      </Typography>
    </Box>
  );
};
