import { BasicField } from '../../contexts/contactContext/contact';

export const fields: BasicField[] = [
  {
    id: '1',
    name: 'amount',
    label: 'Amount',
    type: 'number',
    placeholder: 'giftcard-amount-placeholder',
    required: true,
  },
  {
    id: '2',
    name: 'to',
    label: 'to-giftcert',
    type: 'text',
    placeholder: 'giftcard-recipient-to-placeholder',
    required: true,
  },
  {
    id: '3',
    name: 'from',
    label: 'from-giftcert',
    type: 'text',
    placeholder: 'giftcard-from-placeholder',
    required: true,
  },
  {
    id: '4',
    name: 'email',
    label: 'email',
    type: 'email',
    placeholder: 'recipient-email-placeholder',
    required: true,
  },
  {
    id: '5',
    name: 'message',
    label: 'message',
    type: 'textarea',
    placeholder: 'giftcard-message-placeholder',
    required: true,
  },
];
