import { useMutation } from '@tanstack/react-query';
import { useTranslator } from '@eo-locale/react';
import { useLocalStorage } from '@guest-widgets/shared/src/hooks/useLocalStorage';
import { useWidget } from '@guest-widgets/core';

import { guestApi } from '../../product/guestApi';
import { useSettings } from '../../../settingsContext/settingsContext';
import { useCart } from '../../../cartContext/cartContext';
import { CartState } from '../../cart/cart';
import { ContactSubmitException } from '../exceptions';
import { mapToBookingCreateRequest } from '../../cart/mapToRequest';
import { ContactSubmitResponse } from '../../contact/types';
import { useContactApi } from '../../contact/useContactApi';
import { cartId } from '../../cart/session/useSession';
import { useProduct } from '../../../productContext/productContext';
import { isNextGenCheckoutEnabled } from '../../../../common/utils';

export const useBookingCreate = () => {
  const { customerCode, locale } = useSettings();
  const { cartWithSteps } = useCart();
  const { translate } = useTranslator();
  const { contactForm } = useContactApi();
  const { instanceId } = useWidget();

  const { companyInformation } = useProduct();

  const cart: CartState | undefined = cartWithSteps.data?.cart;

  const [discountCodes] = useLocalStorage<string[]>(`${cartId}-discounts`, []);
  return useMutation({
    mutationFn: async (): Promise<ContactSubmitResponse> => {
      if (!cart || cart.items.length === 0) {
        throw new ContactSubmitException(translate('cart-is-empty'));
      }

      if (!contactForm) {
        throw new ContactSubmitException(translate('contact-form-is-empty'));
      }

      const booking = await guestApi.createBooking(
        customerCode,
        locale,
        mapToBookingCreateRequest(cart, contactForm, discountCodes)
      );

      const bookingLink = `https://${companyInformation?.url}/reserve/booking/${booking.code}?CFX=${booking.cfx}`;

      if (booking.total === 0) {
        return Promise.resolve({ noPaymentNeeded: true, bookingLink, bookingId: booking.code });
      }

      const paymentId = isNextGenCheckoutEnabled(companyInformation)
        ? await guestApi.getBookingPaymentId(customerCode, locale, booking.code, instanceId)
        : '';

      return Promise.resolve({
        noPaymentNeeded: false,
        paymentId,
        bookingLink,
        bookingId: booking.code,
      });
    },
  });
};
