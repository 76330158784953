import type { GuestProduct } from '../../productContext/product';
import { SupportedCurrencies } from '../../settingsContext/settings';
import { ApiInvalidStructureException } from '../apiContext';

import { mapToGuestTypes } from './mapToGuestTypes';

/**
 * Mapper from Checkfront API request to product domain
 */
export const mapToProduct = (
  userCurrency: SupportedCurrencies,
  response: any = {}
): GuestProduct => {
  if (response.id) {
    return {
      name: response.title,
      currency: userCurrency,
      productId: response.id,
      type: response.type,
      // TODO: hardcoded temporary to view Guest product.
      isAvailable: true,
      allocationType: response.allocationType,
      guestTypes: mapToGuestTypes({ guestTypes: response.guestTypes }),
      displayTime: response.displayTime,
      interval: response.interval,
      companyInformation: response.company!,
      information: response.information,
      images: response.images,
      price: response.price?.gross?.amount ?? 0,
      allowBookingMultiDays: response.allowBookingMultiDays,

      //Giftcard produt
      startDateFixed: response.startDateFixed
        ? new Date(response.startDateFixed)
        : response.startDateFixed,
      endDateDuration: response.endDateDuration,
      endDateType: response.endDateType,
      startDateType: response.startDateType,
      variablePrice: response.variablePrice,
      hideAmount: response.hideAmount,
      endDateFixed: response.endDateFixed ? new Date(response.endDateFixed) : response.endDateFixed,
      priceType: response.priceType,
    };
  }

  throw new ApiInvalidStructureException(response?.message);
};
