import { GuestType } from '../../productContext/product';

import { Quantity } from './dtos/request';

interface GuestTypeProps {
  guestTypes: GuestType[];
  quantity?: Quantity;
  parentQuantity?: Quantity;
}

export const mapToGuestTypes = ({ guestTypes = [], quantity, parentQuantity }: GuestTypeProps) => {
  if (!Array.isArray(guestTypes)) {
    return guestTypes;
  }

  if (guestTypes?.length === 0) {
    guestTypes.push({
      label: 'Qty',
      id: 'qty',
    });
  }

  return guestTypes
    .filter((guestType) => !guestType.staffOnly)
    .reduce((acc: GuestType[], current: GuestType, index: number) => {
      if (quantity === undefined) {
        return index === 0
          ? [...acc, { ...current, quantity: MINIMUM_REQUIRED_TO_VALIDATE }]
          : [...acc, { ...current, quantity: 0 }];
      }

      return [...acc, { ...current, quantity: quantity[current.id] ?? 0 }];
    }, [] as GuestType[]);
};

/** Required value to generic validate request */
// TODO will be removed when mergin this hook with useQuantityGuestRestriction Hook
const MINIMUM_REQUIRED_TO_VALIDATE = 1;
