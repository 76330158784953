import { createContext, useContext, useState, PropsWithChildren } from 'react';

import type { TimeRange as BaseTimeRange } from '../contexts/timeslotsContext/timeslots';
import { useUpsell } from '../contexts/upsellContext/upsellContext';
import { UpsellItem } from '../contexts/upsellContext/upsell';
import { Quantity } from '../contexts/apiContext/product/dtos/request';
import { formatDate } from '../contexts/apiContext/product/formatDate';
import { AllocationType } from '../contexts/productContext/product';

export type TimeRange = Partial<BaseTimeRange>;

interface UpsellAttributesProps {
  upsell: UpsellItem;
  quantity: Quantity;
  start?: Date;
  end?: Date;
  isModal?: boolean;
}

interface UpsellInSelectionContext {
  upsellItem: UpsellItem;
  upsellInModal: UpsellItem;
  isDrawerOpen: boolean;
  isModal: boolean;
  addToCartErrors: string[];
  handleQuantityChange: ({ upsell, quantity, start, end }: UpsellAttributesProps) => UpsellItem;
  setUpsellItem: (upsellItem: UpsellItem) => void;
  setUpsellInModal: (upsellInModal: UpsellItem) => void;
  setIsDrawerOpen: (isOpen: boolean) => void;
  setIsModal: (isModal: boolean) => void;
  setAddToCartErrors: (errors: string[]) => void;
  isUpsellInCart: (upsell: UpsellItem) => { isInCart: boolean; hasChanged: boolean };
}

const UpsellInSelectionContext = createContext({} as UpsellInSelectionContext);

export const UpsellInSelectionProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [upsellItem, setUpsellItem] = useState<UpsellItem>({} as UpsellItem);
  const [upsellInModal, setUpsellInModal] = useState<UpsellItem>({} as UpsellItem);
  const [addToCartErrors, setAddToCartErrors] = useState<string[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const { upsells, setUpsells, currentItem, cartStatus } = useUpsell()!;
  const { items: itemsInCart } = cartStatus.data?.cart ?? {};

  /** Set all attributes selected on Upsell when quantity is changed */
  const setUpsellAttributes = ({
    upsell,
    quantity,
    start,
    end,
    isModal = false,
  }: UpsellAttributesProps): UpsellItem => {
    const totalQuantity = Object.values(quantity).reduce((acc, value) => acc + value, 0);

    if (!Object.keys(upsell).length) return upsell;

    if (totalQuantity === 0) {
      // Get the original upsell to use its guestTypes
      const originalUpsell = currentItem?.upsells?.find(
        (cartUpsell) => cartUpsell.productId === upsell.productId
      );
      const newUpsell = {
        ...upsell!,
        guestTypes: [...originalUpsell!.guestTypes],
        totalQuantity: 0,
      };

      return handleUpsellUpdate(newUpsell, isModal);
    }

    const newUpsell = updateUpsell(upsell, quantity, totalQuantity, start, end);

    return handleUpsellUpdate(newUpsell, isModal);
  };

  const updateUpsell = (
    upsell: UpsellItem,
    quantity: Quantity,
    totalQuantity: number,
    start?: Date,
    end?: Date
  ): UpsellItem => {
    const keys = Object.keys(quantity);

    const newGuestTypes = upsell.guestTypes.map((guestType) => {
      const key = keys.find((key) => key === guestType.id);
      if (key) {
        return { ...guestType, quantity: quantity[key] };
      }
      return guestType;
    });

    return {
      ...upsell,
      id: upsell.id,
      start: formatDate(start ?? upsell.start),
      end: formatDate(end ?? upsell.end),
      guestTypes: newGuestTypes,
      totalQuantity,
    };
  };

  const updateAllUpsells = (newUpsell: UpsellItem) => {
    const newUpsells = upsells.map((upsell) => {
      if (upsell.productId === newUpsell.productId) {
        return newUpsell;
      }
      return upsell;
    });

    setUpsells([...newUpsells]);
  };

  const handleUpsellUpdate = (newUpsell: UpsellItem, isModal: boolean) => {
    if (isModal) {
      setUpsellInModal(newUpsell);
      return newUpsell;
    }

    setUpsellItem(newUpsell);
    updateAllUpsells(newUpsell);
    return newUpsell;
  };

  /** Used to manage visibility of buttons and button labels in Upsell Drawers */
  const isUpsellInCart = (upsell: UpsellItem) => {
    const sameInCart = itemsInCart?.find((item) => item.id === upsell.id);
    const hasSamePeriod = sameInCart?.start === upsell.start && sameInCart?.end === upsell.end;

    return { isInCart: !!sameInCart, hasChanged: !hasSamePeriod };
  };

  const value: UpsellInSelectionContext = {
    upsellItem,
    upsellInModal,
    isDrawerOpen,
    isModal,
    addToCartErrors,
    handleQuantityChange: setUpsellAttributes,
    setUpsellItem,
    setUpsellInModal,
    setIsDrawerOpen,
    setIsModal,
    setAddToCartErrors,
    isUpsellInCart,
  };

  return (
    <UpsellInSelectionContext.Provider value={value}>{children}</UpsellInSelectionContext.Provider>
  );
};

export const useUpsellInSelection = () => {
  const context = useContext(UpsellInSelectionContext);
  if (context === undefined) {
    throw new Error('useUpsellInSelection must be used within a UpsellInSelectionProvider');
  }
  return context;
};

export const isTimeSelectionNeeded = (
  upsellAllocationType: AllocationType,
  parentAllocationType: AllocationType,
  useParentDates: boolean,
  allowTimeAdjustment: boolean
): boolean => {
  if (!['timeslot', 'flextime'].includes(upsellAllocationType)) {
    return false;
  }

  if (upsellAllocationType === 'timeslot' && useParentDates) {
    // Tested in legacy CF widget. Time selector never appear for timeslot upsell with "Use parent dates" restriction.
    return false;
  }

  const isAllowTimeAdjustmentApplicable =
    (upsellAllocationType === 'timeslot' && parentAllocationType === 'timeslot') ||
    upsellAllocationType === 'flextime';

  return (
    !isAllowTimeAdjustmentApplicable || (isAllowTimeAdjustmentApplicable && allowTimeAdjustment)
  );
};
