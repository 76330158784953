import { PublicMoney, PublicPrice, Tax } from '../../cartContext/cart';
import {
  LineItem,
  Pricing,
  ValidateData,
  ValidatePricing,
  ValidatePricingTax,
} from '../product/dtos/response';
import { removeTimezone } from '../product/formatDate';
import { mapToUpsells } from '../upsell/mapToUpsells';

import { CartDeposit, CartItem, CartItemPrice, DepositOption } from './cart';

export const mapToTotals = (objData: ValidateData, currency: string) => {
  return {
    grand: mapToPublicPrice(objData.pricing?.total, currency),
    subtotal: mapToPublicPrice(objData.pricing?.subTotal, currency),
    taxes: mapToTaxes(objData.pricing?.taxes || [], currency),
    taxtotal: mapToPublicPrice(objData.pricing?.taxTotal, currency),
    discount:
      objData?.lineItems.find((lineItem) => lineItem.pricing?.discount)?.pricing?.discount ??
      undefined,
    deposits: mapToDeposit(objData.pricing, currency),
    discountTotal: mapToDiscountTotal(objData, currency),
  };
};

const mapToDiscountTotal = (objData: ValidateData, currency: string) => {
  const totalDiscount = objData?.lineItems.reduce(
    (total, item) => total + calculateDiscountValue(item),
    0
  );

  return mapToPublicMoney(totalDiscount);
};

const calculateDiscountValue = (item: LineItem): number => {
  const { pricing } = item;
  if (!pricing?.discount) return 0;

  const { discount, subTotal = 0 } = pricing;
  const amount = discount.amount ?? discount.priceAdjustmentAmount ?? 0;

  if (amount <= 0) return 0;

  const isPercentage = [discount.adjustmentType, discount.priceAdjustmentType].includes('percent');
  const isFixed = [discount.adjustmentType, discount.priceAdjustmentType].includes('fixed');

  if (isPercentage) {
    const originalPrice = subTotal / (1 - amount);

    return originalPrice - subTotal;
  }

  if (isFixed) {
    return amount * 100;
  }

  return 0;
};

export const mapToPublicMoney = (item?: number, currency?: string): PublicMoney => ({
  amount: item ? item / 100 : 0,
  currencyCode: currency ? currency : 'EUR',
});

export const mapToPublicPrice = (amount?: number, currency?: string): PublicPrice => ({
  gross: mapToPublicMoney(amount, currency),
});

export const mapToItem = (
  responseItem: LineItem,
  localItem: CartItem,
  currency: string
): CartItem => {
  // Covers the case where the guestTypes are not returned in validateCart (qty case)
  const guestTypes = !!Object.keys(responseItem.guestTypes).length
    ? responseItem.guestTypes
    : localItem.guestTypes;
  const totalQuantity = responseItem.guestCount
    ? responseItem.guestCount
    : Object.values(localItem.guestTypes).reduce((acc, curr) => acc + curr, 0);

  return {
    id: responseItem.lineId,
    product: {
      ...localItem.product,
    },
    start: removeTimezone(responseItem.start),
    end: removeTimezone(responseItem.end),
    price: mapToItemPrice(responseItem.pricing, currency),
    guestTypes,
    isAvailable: responseItem.isAvailable,
    totalQuantity,
    ...(responseItem.error ? { error: responseItem.error?.message } : {}),
    ...(responseItem.pricing?.discount
      ? {
          discount: {
            code: localItem.discount?.code ?? '',
            name: responseItem.pricing?.discount?.name,
            amount:
              responseItem.pricing?.discount?.amount ??
              responseItem.pricing?.discount?.priceAdjustmentAmount,
            adjustmentType:
              responseItem.pricing?.discount?.adjustmentType ??
              responseItem.pricing?.discount?.priceAdjustmentType,
            discountType: responseItem.pricing?.discount?.discountType,
          },
        }
      : {}),
    ...(responseItem.upsells?.length ? { upsells: mapToUpsells(responseItem, localItem) } : {}),
    giftCardFields: localItem.giftCardFields,
  };
};

export const mapToItemPrice = (pricing?: Pricing, currency?: string): CartItemPrice => ({
  subtotal: mapToPublicMoney(pricing?.subTotal, currency),
  total: mapToPublicMoney(pricing?.subTotal, currency),
  tax: mapToPublicMoney(pricing?.taxTotal, currency),
  inclusiveTaxTotal: mapToPublicMoney(pricing?.inclusiveTaxTotal, currency),
});

const mapToDeposit = (pricing?: ValidatePricing, currency?: string): CartDeposit[] => [
  {
    option: DepositOption.DEPOSIT,
    price: mapToPublicMoney(pricing?.depositCents, currency),
  },
  {
    option: DepositOption.FULL,
    price: mapToPublicMoney(pricing?.balanceDueCents ?? 0, currency),
  },
];

const mapToTaxes = (responseTaxes: ValidatePricingTax[], currency: string): Tax[] =>
  responseTaxes
    .filter((tax) => !tax.inclusive)
    .sort((a, b) => a.weight - b.weight)
    .map(({ name, amountCents, amountPercent, type }) => ({
      name,
      amount: mapToPublicMoney(amountCents, currency),
      amountPercent,
      type,
    }));
