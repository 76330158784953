import { ContactForm } from '../../contactContext/contact';
import {
  BookingCreateRequest,
  BookingField,
  GiftCardFieldsRequest,
  ItemRequest,
} from '../product/dtos/request';

import { CartItem, CartState, GiftCardFields } from './cart';

export const mapToValidateRequest = (item: CartItem): ItemRequest => ({
  lineId: item.id.toString(),
  productId: parseFloat(item.product.productId),
  start: item.start,
  end: item.end,
  guestTypes: item.guestTypes,
  productType: item.product.type,
  ...(item.giftCardFields ? { giftCardFields: mapToGiftCardFields(item.giftCardFields) } : {}),
  ...(item.discount ? { discountCodes: [item.discount.code] } : {}),
});
export const mapToGiftCardFields = (fields: GiftCardFields): GiftCardFieldsRequest => ({
  //  giftcard amount in booking create should be integer cents
  amount: fields.amount * 100,
  fromName: fields.from,
  toName: fields.to,
  toEmail: fields.email,
  message: fields.message,
  deliveryDate: fields.deliveryDate,
});

export const mapToBookingField = (contactForm: ContactForm): BookingField[] => {
  return Object.entries(contactForm.contact)
    .map(([fieldId, value]) => ({
      fieldId,
      // convert array to string and trim
      value:
        typeof value === 'string'
          ? value.trim()
          : Array.isArray(value)
          ? value.join(',').trim()
          : value,
    }))
    .filter(({ value }) => {
      if ((typeof value === 'string' || Array.isArray(value)) && value.length === 0) {
        return false;
      }
      return true;
    });
};

export const mapToBookingCreateRequest = (
  cart: CartState,
  contactForm: ContactForm,
  discountCodes: string[] = []
): BookingCreateRequest => {
  return {
    cart: {
      lineItems: cart.items.map((item) => mapToValidateRequest(item)),
      bookingFields: mapToBookingField(contactForm),
      discountCodes,
    },
  };
};
