import React, { PropsWithChildren, useEffect, useState } from 'react';
import MuiPopper, { PopperProps as PopperMuiProps } from '@mui/material/Popper';
import { PopupState } from 'material-ui-popup-state/core';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Text } from '@eo-locale/react';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { classes } from '../../classes';

export interface PopperProps extends PopperMuiProps {
  popperTitle?: JSX.Element | string;
  popupState: PopupState;
}

export const Popper = ({
  children,
  popperTitle,
  popupState,
  ...props
}: PropsWithChildren<PopperProps>) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(popupState.isOpen);
  }, [popupState.isOpen]);

  const handleClickAway = () => {
    popupState.setOpen(false);
  };

  return (
    <ClickAwayListener mouseEvent={isOpen ? 'onClick' : false} onClickAway={handleClickAway}>
      <PopperContainer
        disablePortal
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          },
        ]}
        {...props}
        className={`${classes.catalog.filters.popup.root} ${props.className || ''}`}
      >
        {popperTitle && (
          <Title className={classes.catalog.filters.popup.header} align="center" variant="h6">
            {typeof popperTitle === 'string' ? <Text id={popperTitle} /> : popperTitle}
          </Title>
        )}
        <Container className={classes.catalog.filters.popup.body}>{children}</Container>
      </PopperContainer>
    </ClickAwayListener>
  );
};

const PopperContainer = styled(MuiPopper)(({ theme }) => ({
  zIndex: 100,
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(4),
  borderRadius: theme.shape?.borderRadius ?? 4,
  boxShadow: `${theme.spacing(0, 2, 5)} rgba(0, 0, 0, 0.25)`,
}));

const Title = styled(Typography)(({ theme }) => ({
  borderBottom: theme.border,
  paddingBottom: theme.spacing(3),
  margin: theme.spacing(0, -4, 3, -4),
}));

const Container = styled('div')({
  minWidth: 220,
});
