import React from 'react';

interface NppCheckoutAttributes {
  paymentRequesterReference?: string;
  checkoutConfiguration?: string;
  customerDetails?: string;
  squareCustomConfiguration?: string;
  translates?: string;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'npp-checkout': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> &
        NppCheckoutAttributes;
    }
  }
}

export const addNppCheckoutScript = (callback: () => void) => {
  if (window.customElements.get('npp-checkout')) {
    callback();
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('src', `${import.meta.env.VITE_REZDY_CHECKOUT_URL}/checkout.min.js`);
  script.addEventListener('load', callback);
  document.body.appendChild(script);
};

export interface NppCheckoutElement extends HTMLElement {
  pay: () => void;
}

export enum NppEventName {
  PAYMENT_SUCCESS = 'npp-payment-success',
  PAYMENT_REDIRECT = 'npp-redirect-payment',
  PAYMENT_FAILED = 'npp-payment-failed',
  CUSTOMER_VALIDATION_FAILURE = 'npp-customer-details-validation-failure',
  PAYMENT_READY = 'npp-selected-payment-method-ready',
}

export type NppPaymentReadyEvent = Event & {
  detail: {
    paymentRequesterReference: string;
  };
};

export type NppSuccessEvent = Event & {
  detail: {
    paymentRequesterReference: string;
    paymentReference: string;
  };
};

export type NppRedirectEvent = Event & {
  detail: {
    paymentRequesterReference: string;
    paymentReference: string;
    paymentStatus: string;
    redirectLink: string;
  };
};

export type NppFailedEvent = Event & {
  detail: {
    paymentRequesterReference: string;
    message: string;
  };
};
