import { useProduct } from '../../contexts/productContext/productContext';

import { GiftCardProductInSelectionProvider } from './giftCardInSelectionContext';
import { GiftCardProductInner } from './GiftCardProductInner';

export const GiftCardProduct = () => {
  const product = useProduct();

  return (
    <GiftCardProductInSelectionProvider product={product}>
      <GiftCardProductInner />
    </GiftCardProductInSelectionProvider>
  );
};
