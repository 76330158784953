import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useWidget } from '@guest-widgets/core';
import uniqueId from 'lodash/uniqueId';
import { styled } from '@mui/material/styles';
import { getOutterShadowHost } from '@guest-widgets/shared/src/utils/getOutterShadowHost';
import { handleTrackingEvent } from '@guest-widgets/shared/src/utils/customerTracking/utils/trackingEventsHandler';
import { TrackingEventType } from '@guest-widgets/shared/src/utils/customerTracking/types';

import { Stepper } from '../../Stepper';
import { StyledContainer } from '../../StyledSharedComponents';
import { Totals } from '../../common/Totals';
import { usePayment } from '../../hooks/cart/usePayment';
import { useCart } from '../../contexts/cartContext/cartContext';
import defaultLanguage from '../../../i18n/locales/en_US.json';
import { useSettings } from '../../contexts/settingsContext/settingsContext';

import { Navigation } from './Navigation';
import {
  addNppCheckoutScript,
  NppCheckoutElement,
  NppSuccessEvent,
  NppRedirectEvent,
  NppFailedEvent,
  NppEventName,
  NppPaymentReadyEvent,
} from './nppCheckout';

declare global {
  interface Window {
    getNppCheckoutConfiguration?: () => void;
  }
}

export interface PaymentProps {
  paymentId: string;
  successHandler: () => void;
  redirectHandler: (url: string) => void;
  failureHandler: () => void;
}

export const Payment = ({ paymentId, successHandler, redirectHandler }: PaymentProps) => {
  const { containerElement } = useWidget();
  const { locale } = useSettings();
  const [checkoutUI, setCheckoutUI] = useState(defaultLanguage['checkout-ui']);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [paymentReady, setPaymentReady] = useState(false);

  useEffect(() => {
    const loadLanguageData = async () => {
      try {
        const formattedLocale = locale.replace('-', '_');
        const languageModule = await import(`../../../i18n/locales/${formattedLocale}.json`);
        const languageData = languageModule.default['checkout-ui'];
        // Merge languageData into checkoutUI
        setCheckoutUI((prevCheckoutUI) => ({
          ...prevCheckoutUI,
          ...languageData,
        }));
      } catch (error) {
        console.error(`Error loading language file for locale ${locale}:`, error);
      }
    };

    loadLanguageData();
  }, []);

  useEffect(() => {
    if (!('getNppCheckoutConfiguration' in window)) {
      window.getNppCheckoutConfiguration = () => ({
        payButton: {
          hide: true,
        },
        css: {
          paymentTotalContainer: {
            display: 'none',
          },
        },
      });
    }

    document.addEventListener(NppEventName.PAYMENT_READY, onPaymentReady);

    addNppCheckoutScript(() => setInitialized(true));

    document.addEventListener(NppEventName.PAYMENT_SUCCESS, onPaymentSuccess);
    document.addEventListener(NppEventName.PAYMENT_REDIRECT, onPaymentRedirect);
    document.addEventListener(NppEventName.PAYMENT_FAILED, onPaymentFailure);

    //TODO: This event present in documentation and examples, but isn't reachable when testing failed payments
    document.addEventListener(NppEventName.CUSTOMER_VALIDATION_FAILURE, onPaymentFailure);

    return () => {
      document.removeEventListener(NppEventName.PAYMENT_READY, onPaymentReady);
      document.removeEventListener(NppEventName.PAYMENT_SUCCESS, onPaymentSuccess);
      document.removeEventListener(NppEventName.PAYMENT_REDIRECT, onPaymentRedirect);
      document.removeEventListener(NppEventName.PAYMENT_FAILED, onPaymentFailure);
      document.removeEventListener(NppEventName.CUSTOMER_VALIDATION_FAILURE, onPaymentFailure);
    };
  }, []);

  const { priceToPay } = usePayment();

  const { cartWithSteps } = useCart();

  const outerShadowHost = useMemo<Node>(() => getOutterShadowHost(containerElement), [
    containerElement,
  ]);

  const onPaymentReady: EventListener = (e) => {
    if ((e as NppPaymentReadyEvent).detail.paymentRequesterReference !== paymentId) {
      return;
    }
    setPaymentReady(true);
  };

  const onPaymentSuccess: EventListener = (e) => {
    if ((e as NppSuccessEvent).detail.paymentRequesterReference !== paymentId) {
      return;
    }
    successHandler();
  };

  const onPaymentRedirect: EventListener = (e) => {
    if ((e as NppRedirectEvent).detail.paymentRequesterReference !== paymentId) {
      return;
    }
    redirectHandler((e as NppRedirectEvent).detail.redirectLink);
  };

  const onPaymentFailure: EventListener = (e) => {
    if ((e as NppFailedEvent).detail.paymentRequesterReference !== paymentId) {
      return;
    }
    setPaymentInProgress(false);
    //TODO: It is unclear if we should re-create booking and payment reference on error
    // For now payments can be re-tried with same payment reference, so we should not switch to failed screen
    //failureHandler();
  };

  const onPayButtonClick = () => {
    const checkoutElement = (outerShadowHost as Element).getElementsByTagName('npp-checkout')?.[0];
    if (checkoutElement) {
      setPaymentInProgress(true);
      handleTrackingEvent(TrackingEventType.CHECKOUT, {
        items: cartWithSteps?.data?.cart?.items || [],
        totals: cartWithSteps?.data?.cart?.totals,
        currency: priceToPay.currencyCode,
      });
      (checkoutElement as NppCheckoutElement).pay();
    }
  };

  const slotName = uniqueId('npp-checkout');

  return (
    <div>
      <Stepper />
      {initialized && (
        <Container>
          {createPortal(
            <div slot={slotName}>
              <npp-checkout
                paymentRequesterReference={paymentId}
                checkoutConfiguration="getNppCheckoutConfiguration"
                translates={JSON.stringify([{ language: locale, messages: checkoutUI }])}
              ></npp-checkout>
            </div>,
            outerShadowHost as Element
          )}
          <slot name={slotName}></slot>
        </Container>
      )}
      <StyledHr />
      <Totals />
      <Navigation
        paymentReady={paymentReady}
        action={onPayButtonClick}
        paymentInProgress={paymentInProgress}
        priceToPay={priceToPay}
      />
    </div>
  );
};

const StyledHr = styled('div')(({ theme: { border, spacing } }) => ({
  borderBottom: border,
  marginBottom: spacing(2),
  marginTop: spacing(-2),
}));

const Container = styled(StyledContainer)(({ theme: { spacing } }) => ({
  marginTop: spacing(2),
}));
