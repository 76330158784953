import React, { createContext, useContext, useState, PropsWithChildren } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { isSameDay, addDays } from 'date-fns';

import { AllocationType, GuestProduct, GuestType } from '../../contexts/productContext/product';
import type { TimeslotDayRange } from '../Steps/Day/Calendar/CalendarRange/useCalendarRange';
import type { TimeRange as BaseTimeRange } from '../../contexts/timeslotsContext/timeslots';
import { mapToQuantity } from '../../contexts/apiContext/product/mapToPricing';
import { formatDate } from '../../contexts/apiContext/product/formatDate';
import { Quantity } from '../../contexts/apiContext/product/dtos/request';
import { CartItem } from '../../contexts/apiContext/cart/cart';
import { usePricingApi } from '../../contexts/apiContext/product/usePricingApi';
import type { Pricing } from '../../contexts/apiContext/product/product';
import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { useUpsellItemsFromCart } from '../../hooks/upsell/useUpsellItemsFromCart';

export type TimeRange = Partial<BaseTimeRange>;

interface GuestProductInSelectionContext extends GuestProduct {
  /** Indicates whether all information needed in order to add the product into the cart has being provided */
  isComplete: boolean;
  /** Indicates whether all information needed in order to activate quantity selector */
  isDateTimeSelectionComplete: boolean;
  /** Indicates whether the product requires a date range selection */
  requiresDateRange: boolean;
  /** Indicates whether the product requires a time selection */
  requiresTimeSelection: boolean;
  time?: TimeRange;
  date?: TimeslotDayRange;
  quantity?: Quantity;
  pricingApi: UseQueryResult<Pricing>;
  setDate: (date?: TimeslotDayRange) => void;
  setTime: (time?: TimeRange) => void;
  setQuantity: (quantity?: Quantity) => void;
  quantityChanged?: boolean;
  setQuantityChanged?: React.Dispatch<React.SetStateAction<boolean>>;
}

const GuestProductInSelectionContext = createContext({} as GuestProductInSelectionContext);

export interface GuestRate extends GuestType {
  rate?: number;
}

export type ProductProp = GuestProduct | UpsellItem;

interface ProductInSelectionProps {
  product: ProductProp;
}

export const ProductInSelectionProvider = ({
  product,
  children,
}: PropsWithChildren<ProductInSelectionProps>) => {
  const { allocationType, productId, name, allowBookingMultiDays } = product;
  const [date, setDate] = useState<TimeslotDayRange>();
  const [time, setTime] = useState<TimeRange>();
  const [quantityChanged, setQuantityChanged] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<Quantity>();

  const flextimeMultiDays = allowBookingMultiDays && allocationType === 'flextime';
  const requiresDateRange = ['day', 'night'].includes(allocationType) || flextimeMultiDays;
  const requiresTimeSelection =
    ['timeslot', 'flextime'].includes(allocationType) || flextimeMultiDays;
  const endDateRange =
    allocationType === 'day' && date?.to?.day ? addDays(date.to.day, 1) : date?.to?.day;

  const isDateTimeSelectionComplete = (() => {
    const mapper: Record<AllocationType, boolean> = {
      day: Boolean(date?.from && (date.isAvailable ?? true)),
      night: Boolean(
        date?.from &&
          date.to &&
          !isSameDay(date.from.day, date.to.day) &&
          (date.isAvailable ?? true)
      ),
      flextime: Boolean(
        date?.from && date.to && !date.isCalendarSelection && (date.isAvailable ?? true)
      ),
      timeslot: Boolean(date?.from && date?.to && time?.startTime),
    };

    return mapper[allocationType];
  })();

  const isComplete = isDateTimeSelectionComplete && isQuantitySelected(quantity);

  const guestTypes = mapToQuantity(product.guestTypes);
  const experience = {
    id: isUpsell(product) ? product.id : undefined,
    product: { productId, name },
    guestTypes,
    start: formatDate(date?.from?.day),
    end: formatDate(endDateRange),
  } as CartItem;

  const pricingApi = usePricingApi({
    experience,
    quantity,
    guestTypesWithQuantity: product.guestTypes,
    isDateTimeSelectionComplete,
  });

  const setDateHandler = (value: TimeslotDayRange | undefined) => {
    if (!value || typeof value === 'function') return;

    if (requiresDateRange) {
      return setDate({ ...value, isCalendarSelection: true });
    }

    setDate(value);
  };

  const setTimeHandler = (value: TimeRange | undefined) => {
    if (!value || typeof value === 'function') return;

    setTime(value);

    if (date && value?.startTime && value?.endTime)
      setDate(mapToDateRange(date, value.startTime, value.endTime));
  };

  useUpsellItemsFromCart({
    upsell: product,
    setDateHandler,
    setTimeHandler,
    setQuantity,
    time,
    requiresTimeSelection,
  });

  const value: GuestProductInSelectionContext = {
    ...product,
    date,
    setDate: setDateHandler,
    time,
    setTime: setTimeHandler,
    quantity,
    setQuantity,
    isComplete,
    isDateTimeSelectionComplete,
    requiresDateRange,
    requiresTimeSelection,
    pricingApi,
    // keep last changed quantity value
    quantityChanged,
    setQuantityChanged,
  };

  return (
    <GuestProductInSelectionContext.Provider value={value}>
      {children}
    </GuestProductInSelectionContext.Provider>
  );
};

export const useProductInSelection = () => {
  const context = useContext(GuestProductInSelectionContext);
  if (context === undefined) {
    throw new Error('useProductInSelection must be used within a GuestProductInSelectionProvider');
  }
  return context;
};

const isQuantitySelected = (quantity?: Quantity) =>
  Object.values(quantity || {}).reduce((a, b) => a + b, 0) > 0;

const mapToDateRange = (
  date: TimeslotDayRange,
  startTime: Date,
  endTime: Date
): TimeslotDayRange => {
  const from = {
    ...date.from,
    day: startTime,
    availabilityTimes: date.from?.availabilityTimes || [],
  };
  const to = {
    ...date.to,
    day: endTime,
    availabilityTimes: date.to?.availabilityTimes || [],
  };

  return { ...date, from, to, isCalendarSelection: false };
};

export const isUpsell = (product: ProductProp): product is UpsellItem => {
  return !!(product as UpsellItem)?.id?.includes('.');
};
