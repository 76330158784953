export const useGetUpsellMessageKeys = (isRequired?: boolean, sameParentQuantity?: boolean) => {
  const messageKeys: string[] = [];

  if (sameParentQuantity) {
    messageKeys.push('automatically-adjusted-to-match-your-selection');
  }
  if (isRequired) {
    messageKeys.push('required-option');
  }
  return { messageKeys };
};
