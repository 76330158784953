import { useWidget } from '@guest-widgets/core';

import { useSettings } from '../contexts/settingsContext/settingsContext';
import type { Settings } from '../contexts/settingsContext/settings';
import { Step } from '../contexts/routerContext/router';

/** Get widget step based on querystring param */
export const useRouteFromQueryString = (availableSteps: Array<Step>) => {
  const { queryString } = useSettings();
  const { instanceId } = useWidget();

  const routedStep = getStep(instanceId, queryString);

  if (routedStep && availableSteps.includes(routedStep) && routableSteps.includes(routedStep)) {
    return routedStep;
  }

  return undefined;
};

/**Steps that user can route via querystring */
const routableSteps: Array<Step> = ['checkout', 'purchaseFailure', 'purchaseSuccess'];

type MapToQuery = (instanceId: number, query?: Settings['queryString']) => Step | undefined;

const getStep: MapToQuery = (instanceId, query) => {
  if (query?.rwid !== instanceId.toString()) return;

  return query.rwstep === 'payment' ? 'checkout' : (query.rwstep as Step);
};
