import { useProduct } from '../contexts/productContext/productContext';

import { CurrencyAndLanguage } from './CurrencyAndLanguage/CurrencyAndLanguage';
import { Unavailable } from './Unavailable';
import { GuestProduct } from './Guest/GuestProduct';
import { GiftCardProduct } from './GiftCard/GiftCardProduct';

export const Product = () => {
  const product = useProduct();

  if (!product) return null;
  const { isAvailable, type } = product;

  if (!isAvailable && type !== 'giftcard') {
    return <Unavailable />;
  }

  return (
    <>
      {type === 'giftcard' ? <GiftCardProduct /> : <GuestProduct />}
      <CurrencyAndLanguage />
    </>
  );
};
