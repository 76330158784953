import { styled, TextField, Typography } from '@mui/material';
import { Text, useTranslator } from '@eo-locale/react';
import {
  UseFormRegister,
  UseFormStateReturn,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { BasicField } from '../../contexts/contactContext/contact';
import { GiftCardFormState } from '../../hooks/product/giftcard/useGiftCardFormValidation';

interface GiftCardFieldsProps {
  register: UseFormRegister<GiftCardFormState>;
  setValue: UseFormSetValue<GiftCardFormState>;
  watch: UseFormWatch<GiftCardFormState>;
  fields: BasicField[];
  formState: UseFormStateReturn<GiftCardFormState>;
}

export const GiftCardFields = ({
  fields,
  register,
  setValue,
  watch,
  formState,
}: GiftCardFieldsProps) => {
  const { translate: t } = useTranslator();

  return (
    <InputsWrapper>
      <GiftDetailsTitle>
        <Text id="gift-card-details" />
      </GiftDetailsTitle>
      {fields.map((field) => {
        const name = field.name! as keyof GiftCardFormState;
        const isTextarea = field.type === 'textarea';

        return (
          <TextField
            required={field.required}
            key={field.id}
            {...register(name)}
            label={t(field.label)}
            placeholder={t(field.placeholder ?? '')}
            type={isTextarea ? 'text' : field.type}
            multiline={isTextarea}
            rows={isTextarea ? 4 : 1}
            error={Boolean(formState.errors[name])}
            helperText={formState.errors[name]?.message}
            fullWidth
            value={isTextarea ? watch(name) || '' : undefined}
            onChange={isTextarea ? (e) => setValue(name, e.target.value) : undefined}
          />
        );
      })}
    </InputsWrapper>
  );
};

const GiftDetailsTitle = styled(Typography)(({ theme: { spacing } }) => ({
  fontWeight: 600,
  fontSize: spacing(4),
}));

const InputsWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
}));
