import { ShownSteps, UiSelectors } from '../../upsellContext/upsell';
const bypassUpsellsApiFix = import.meta.env.VITE_BYPASS_UPSELLS_API_FIX === 'true';

interface UpsellConfig {
  useParentDates: boolean;
  parentAllocationType: 'day' | 'timeslot' | 'flextime';
  upsellAllocationType: 'day' | 'timeslot' | 'flextime';
  allowTimeAdjustment: boolean;
  oneOrZeroGuestTypes: boolean;
}

type Combination = [
  boolean, // useParentDates
  'day' | 'timeslot' | 'flextime', // parentAllocationType
  'day' | 'timeslot' | 'flextime', // upsellAllocationType
  boolean, // allowTimeAdjustment
  boolean // oneOrZeroGuestTypes
];

// Temporary solotuin for the upsell items that should not be rendred in because of apifix
// TODO removed once api is fixed
const keysWithBypassUpsellsApiFix = [
  'true,day,flextime,true,true',
  'true,day,flextime,true,false',
  'true,timeslot,day,false,true',
  'true,timeslot,day,false,false',
  'true,timeslot,flextime,true,true',
  'true,timeslot,flextime,true,false',
  'true,flextime,day,false,true',
  'true,flextime,day,false,false',
  'true,flextime,flextime,true,true',
  'true,flextime,flextime,true,false',
  'false,day,flextime,false,false',
];

// keys where time is shown or even if allowTimeAdjustment is true
const showTimeExceptions = [
  'true,timeslot,timeslot,true,true',
  'true,timeslot,timeslot,true,false',
  'false,day,timeslot,false,false',
];

// Possible values for each key in the combination
const possibleValues: Record<keyof UpsellConfig, (boolean | 'day' | 'timeslot' | 'flextime')[]> = {
  useParentDates: [true, false],
  parentAllocationType: ['timeslot', 'flextime', 'day'],
  upsellAllocationType: ['timeslot', 'flextime', 'day'],
  allowTimeAdjustment: [true, false],
  oneOrZeroGuestTypes: [true, false],
};

const determineLayout = (combination: Combination) => {
  const [useParentDates, _, __, ___, oneOrZeroGuestTypes] = combination;

  if (!useParentDates) {
    return 'twoColumns';
  }

  if (oneOrZeroGuestTypes) {
    return 'singleQty';
  }

  return 'oneColumn';
};

// Function to generate all possible combinations dynamically
const generateKeyCombinations = (
  keys: Array<keyof UpsellConfig>,
  idx = 0,
  currentCombination: any[] = []
): Combination[] => {
  const combinations: Combination[] = [];

  if (idx === keys.length) {
    combinations.push(currentCombination as Combination);
    return combinations;
  }

  for (let value of possibleValues[keys[idx]]) {
    combinations.push(...generateKeyCombinations(keys, idx + 1, [...currentCombination, value]));
  }

  return combinations;
};

const getUiSelectors = (config: Combination, key: string): UiSelectors => {
  const [useParentDates, _, __, allowTimeAdjustment, ___] = config;

  const showSteps: ShownSteps = [];
  if (!useParentDates) {
    showSteps.push('date');
  }
  if (allowTimeAdjustment || showTimeExceptions.includes(key)) {
    showSteps.push('time');
  }
  showSteps.push('quantity');

  const render = !bypassUpsellsApiFix && keysWithBypassUpsellsApiFix.includes(key) ? false : true;

  return {
    drawerLayout: determineLayout(config),
    showSteps,
    render,
  };
};

/**
 * This is a mapping of the configuration of the upsell to the selectors that should be shown.
 * The keys refer respectively to: useParentDates, parentAllocationType, upsellAllocationType, allowTimeAdjustment, oneOrZeroGuestTypes
 */
const mapConfigToSelectors = new Map<string, UiSelectors>();
const configCombinations = generateKeyCombinations(
  Object.keys(possibleValues) as Array<keyof UpsellConfig>
);

configCombinations.forEach((config) => {
  const [
    useParentDates,
    parentAllocationType,
    upsellAllocationType,
    allowTimeAdjustment,
    oneOrZeroGuestTypes,
  ] = config;

  const configKey = `${useParentDates},${parentAllocationType},${upsellAllocationType},${allowTimeAdjustment},${oneOrZeroGuestTypes}`;
  mapConfigToSelectors.set(configKey, getUiSelectors(config, configKey));
});

export default mapConfigToSelectors;
