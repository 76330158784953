import React, { useEffect } from 'react';
import { CircularProgress, styled } from '@mui/material';
import isSameDay from 'date-fns/isSameDay';
import { max } from 'date-fns';
import { booking } from '@guest-widgets/shared/src/classes/booking';
import { Calendar as BaseCalendar } from '@guest-widgets/shared/src/components/Calendar/Calendar';

import type { TimeslotsDay } from '../../../../contexts/timeslotsContext/timeslots';
import { useTimeslots } from '../../../../contexts/timeslotsContext/timeslotsContext';
import { useSettings } from '../../../../contexts/settingsContext/settingsContext';
import { LoadingContainer } from '../../../../StyledSharedComponents';

import { CalendarDayPicker } from './CalendarDayPicker';
import { useCalendarNumberOfMonths } from './useCalendarNumberOfMonths';
import { useDayModifiers } from './useDayModifiers';
import { useAccumulatedTimeslots } from './useAccumulatedTimeslots';

interface CalendarProps {
  onSelect: (date?: TimeslotsDay) => void;
  selected?: TimeslotsDay;
  limitMonths?: number;
  autoSelect?: boolean;
  className?: string;
}

export const Calendar = ({
  onSelect,
  selected,
  limitMonths,
  className,
  autoSelect,
}: CalendarProps) => {
  const { locale } = useSettings();
  const { timeslot, setFilters, filters, product, getTimeslotDayByDate } = useTimeslots();
  const numberOfMonths = useCalendarNumberOfMonths(limitMonths);
  const trafficLightStatus = useDayModifiers();
  const allAvailableDays = useAccumulatedTimeslots();
  const sortTimeslotDays = timeslot.data?.days?.sort((a, b) => a.day.getTime() - b.day.getTime());
  const firstMonth = sortTimeslotDays?.[0]?.day ?? filters.dateFrom ?? new Date();

  const dateTo = timeslot.data?.range?.to;
  const timeslotsFound = !!timeslot.data?.days?.length;

  useEffect(() => {
    if (dateTo && timeslotsFound && dateTo.getMonth() === firstMonth.getMonth()) {
      setFilters({
        ...filters,
        dateFrom: firstMonth,
      });
    }
  }, [dateTo, firstMonth, timeslotsFound]);

  useEffect(() => {
    // If autoSelect is disabled, or this upsell of this product is already on the cart, do nothing
    if (!autoSelect || !!selected?.day) return;

    if (!allAvailableDays.length) {
      onSelect(undefined);
      // This condition avoids loading timeslots multiple times when clicking on next month
      if (!filters.dateFrom) setFilters({ nextAvailableDay: true });
      return;
    }

    // If selected day is not available, select first available
    const isSelectedAvailable =
      !!selected && allAvailableDays.some(({ day }) => isSameDay(day, selected.day));
    if (!isSelectedAvailable) {
      onSelect(allAvailableDays[0]);
    }

    setFilters({ nextAvailableDay: false });
  }, [allAvailableDays[0]?.day.valueOf()]);

  const handleSelect = (date: Date | undefined) => {
    if (!date) return;

    const selected = getTimeslotDayByDate(date);
    if (selected) onSelect(selected);
  };

  const range = product?.availability
    ? { from: max([new Date(), product.availability.from]), to: product.availability.to }
    : { from: new Date(), to: undefined };

  return (
    <Container className={className} data-testid="calendar-simple">
      <BaseCalendar
        language={locale}
        components={{
          Day: CalendarDayPicker,
        }}
        mode="single"
        onSelect={handleSelect}
        selected={selected?.day}
        modifiers={trafficLightStatus}
        month={firstMonth}
        onMonthChange={(dateFrom: Date) => setFilters({ dateFrom })}
        numberOfMonths={numberOfMonths}
        fromMonth={range.from}
        toMonth={range.to}
        className={booking.calendar}
      />
      {timeslot.isFetching && (
        <LoadingContainer pt={8}>
          <CircularProgress size={50} />
        </LoadingContainer>
      )}
    </Container>
  );
};

const Container = styled('div')({
  position: 'relative',
});
