import { Text } from '@eo-locale/react';
import { ICON_BASE_SIZE, LoadingButton } from '@guest-widgets/shared/src/components/LoadingButton';

import { NavigationContainer } from '../../StyledSharedComponents';

export interface NavigationProps {
  seconds: number;
  action: () => void;
}

export const Navigation = ({ seconds, action }: NavigationProps) => {
  return (
    <NavigationContainer>
      <LoadingButton
        loading={false}
        variant="contained"
        color="primary"
        size="large"
        iconSize={ICON_BASE_SIZE}
        fullWidth
        type="submit"
        disabled={seconds > 0}
        onClick={action}
      >
        {seconds > 0 ? (
          <Text id="proceed-to-payment-in-variable" seconds={seconds} />
        ) : (
          <Text id="proceed-to-payment" />
        )}
      </LoadingButton>
    </NavigationContainer>
  );
};
