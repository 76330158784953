import { Box, Typography } from '@mui/material';
import InformationIcon from '@mui/icons-material/InfoRounded';
import { Text } from '@eo-locale/react';

interface UpsellCardMessageProps {
  messageKey: string;
}

export const UpsellCardMessage = ({ messageKey }: UpsellCardMessageProps) => {
  return (
    <Box sx={{ display: 'flex', opacity: 0.6 }} mt={1}>
      <InformationIcon sx={{ fontSize: '1.25em', marginTop: '0.1em' }} />
      <Typography variant="caption" ml={1}>
        <Text id={messageKey} />
      </Typography>
    </Box>
  );
};
