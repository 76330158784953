import { IconButton as MuiIconButton, Link, Typography, styled, lighten } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { red } from '@mui/material/colors';
import { Text } from '@eo-locale/react';

import { useCart } from '../../contexts/cartContext/cartContext';
import { useRouter } from '../../contexts/routerContext/routerContext';

import { ItemProps } from './Item';

export const ItemRemoved = ({ cartItem }: ItemProps) => {
  const { removeItem } = useCart();
  const { setCurrentStep } = useRouter();

  const goToProductAndRemove = () => {
    setCurrentStep('product');
    removeItem.mutate(cartItem);
  };

  return (
    <Container>
      <ErrorContainer>
        <ErrorIcon sx={{ color: red[900] }} />
        <div>
          <Text id={'variable-timeslot-was-already-booked'} variable={cartItem.product.name} />
          &nbsp;
          <Link onClick={goToProductAndRemove}>
            <Text id={'date-or-quantity'} />.
          </Link>
        </div>
        <IconButton aria-label="close" onClick={() => removeItem.mutate(cartItem)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </ErrorContainer>
    </Container>
  );
};

const Container = styled('div')(({ theme: { spacing, border } }) => ({
  gridColumn: '1 / span 3',
  borderBottom: border,
  paddingTop: spacing(6),
  paddingBottom: spacing(6),
}));

const ErrorContainer = styled(Typography)(({ theme: { spacing } }) => ({
  position: 'relative',
  display: 'flex',
  gap: spacing(2),
  padding: spacing(4),
  backgroundColor: red[50],
  border: `1px solid ${red[900]}`,
  borderRadius: 8,
  '& a, & a:hover': {
    textDecoration: 'underline',
    color: 'inherit',
    transition: 'opacity 0.3s ease-in-out',
  },
  '& a:hover': {
    opacity: 0.6,
  },
}));

const IconButton = styled(MuiIconButton)({
  position: 'absolute',
  top: 4,
  right: 4,
  width: 25,
  height: 25,
  color: lighten(red[900], 0.35),
  '&:hover': {
    backgroundColor: lighten(red[100], 0.2),
  },
});
