import { styled, Typography } from '@mui/material';
import { Text } from '@eo-locale/react';

type Props = {};

export const GiftCardHeader = (props: Props) => {
  return (
    <>
      <GiftCardTitle variant="h4">
        <Text id="personalise-gift-card" />
      </GiftCardTitle>
    </>
  );
};

const GiftCardTitle = styled(Typography)(({ theme: { spacing } }) => {
  return {
    fontSize: spacing(6),
    lineHeight: '28.8px',
    textAlign: 'left',
    marginBottom: spacing(4),
  };
});
