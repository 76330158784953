import { useEffect } from 'react';
import { handleTrackingEvent } from '@guest-widgets/shared/src/utils/customerTracking/utils/trackingEventsHandler';
import { TrackingEventType } from '@guest-widgets/shared/src/utils/customerTracking/types';

import { useProduct } from '../contexts/productContext/productContext';
import { useCart } from '../contexts/cartContext/cartContext';
import { useRouter } from '../contexts/routerContext/routerContext';

export const useTrackingCart = () => {
  const { currentStep } = useRouter();
  const { cartWithSteps } = useCart();
  const { currency } = useProduct();

  useEffect(() => {
    if (
      currentStep === 'cart' &&
      Array.isArray(cartWithSteps.data?.cart?.items) &&
      cartWithSteps.data?.cart?.items.length
    ) {
      handleTrackingEvent(TrackingEventType.VIEW_CART, {
        items: cartWithSteps.data?.cart?.items ?? [],
        currency,
      });
    }
  }, [cartWithSteps.data?.cart?.items, currentStep]);
};
