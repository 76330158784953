import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { useGetUpsellMessageKeys } from '../../hooks/useGetUpsellMessageKeys';

import { BaseUpsellItem } from './BaseUpsell';

interface RequiredInheritedHandlerProps {
  item: UpsellItem;
}

export const RequiredInheritedHandler = ({ item }: RequiredInheritedHandlerProps) => {
  const { messageKeys } = useGetUpsellMessageKeys(
    item.configuration.isRequired,
    item.configuration.sameParentQuantity
  );
  return <BaseUpsellItem item={item} messageKeys={messageKeys} />;
};
