import { useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@guest-widgets/core';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';
import { TrackingEventType } from '@guest-widgets/shared/src/utils/customerTracking/types';
import { handleTrackingEvent } from '@guest-widgets/shared/src/utils/customerTracking/utils/trackingEventsHandler';

import ItemDeleteDialog from '../../common/ItemDeleteDialog';
import { CartItem } from '../../contexts/apiContext/cart/cart';
import { useProduct } from '../../contexts/productContext/productContext';
import { useCart } from '../../contexts/cartContext/cartContext';
import { useSettings } from '../../contexts/settingsContext/settingsContext';
import { useSession } from '../../contexts/apiContext/cart/session/useSession';
import { PriceText } from '../../common/ItemCard/StyledComponents';
import { DeleteButton } from '../../common/ItemCard/Buttons';
import ItemCartWrapper from '../../common/ItemCard/ItemCartWrapper';

interface CartItemProps {
  item: CartItem;
}

export const CartItemComponent = ({ item }: CartItemProps) => {
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [rateLimitError, setRateLimitError] = useState(false);
  const { currency } = useSettings();
  const { removeItem, cartWithSteps } = useCart();
  const { cartState } = useSession();
  const { companyInformation } = useProduct();
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);
  const { price, discount } = item;
  const itemPrice = formatPrice(price.total.amount, price.total.currencyCode);

  const getDiscountData = useMemo(() => {
    if (discount?.adjustmentType === 'percent' && discount.amount) {
      return `-${discount.amount * 100}%`;
    }
    if (discount?.adjustmentType === 'fixed' && discount.amount) {
      return `-${formatPrice(discount.amount, price.total.currencyCode)}`;
    }
  }, [price.total.currencyCode, discount?.adjustmentType, discount?.amount]);

  const onOpenRemoveDialog = () => {
    setOpenRemoveDialog(true);
  };
  const onCloseRemoveDialog = () => {
    setOpenRemoveDialog(false);
  };

  const onDeleteCartItem = () => {
    removeItem
      .mutateAsync(item)
      .then(() => {
        onCloseRemoveDialog();

        const itemsToRemove = cartState.items.filter(
          (cartItem) => cartItem.id === item.id || cartItem.id.startsWith(`${item.id}.`)
        );
        itemsToRemove.forEach((item) => {
          handleTrackingEvent(TrackingEventType.REMOVE_FROM_CART, {
            item,
            currency,
            totalAmount: cartWithSteps?.data?.cart?.totals.subtotal.gross.amount,
          });
        });
      })
      .catch((error) => {
        if (error.message.includes('429')) {
          setRateLimitError(true);
        }
      });
  };

  return (
    <ItemCartWrapper item={item}>
      {discount ? (
        <DiscountWrapper>
          <DiscountText variant="h6">{getDiscountData}</DiscountText>
          <PriceText variant="h3">{itemPrice} </PriceText>
        </DiscountWrapper>
      ) : (
        <PriceText variant="h3">{itemPrice} </PriceText>
      )}
      <DeleteButton onClick={onOpenRemoveDialog} />
      <ItemDeleteDialog
        open={openRemoveDialog}
        onClose={onCloseRemoveDialog}
        onConfirm={onDeleteCartItem}
        productName={item?.product.name ?? ''}
        rateLimitError={rateLimitError}
      />
    </ItemCartWrapper>
  );
};

const DiscountWrapper = styled('span')({
  display: 'flex',
  alignItems: 'center',
});

const DiscountText = styled(Typography)(({ theme: { spacing } }) => ({
  lineHeight: 1,
  fontWeight: 500,
  backgroundColor: '#64B473',
  borderRadius: spacing(2),
  padding: spacing(0.5, 1),
  marginRight: spacing(2),
}));
