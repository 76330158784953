import { QuantitySelector } from '@guest-widgets/shared/src/components/QuantitySelector/QuantitySelector';

import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { useUpsellInSelection } from '../upsellInSelectionContext';
import { AddButton } from '../../common/ItemCard/Buttons';
import { useInnerUpsell } from '../../hooks/upsell/useInnerUpsell';
import { useRemoveFromCart } from '../../hooks/cart/useRemoveFromCart';
import { useUpsellMapper } from '../../hooks/upsell/useUpsellMapper';

import { BaseUpsellItem } from './BaseUpsell';

interface SingleQTYProps {
  item: UpsellItem;
}

export const SingleQty = ({ item }: SingleQTYProps) => {
  const { totalQuantity } = item;
  const { handleQuantityChange } = useUpsellInSelection();
  const { addToCartHandler } = useInnerUpsell();
  const { mapUpsellToCartItem } = useUpsellMapper();
  const { onDeleteCartItem } = useRemoveFromCart({ item: mapUpsellToCartItem(item) });

  const onQuantityChange = (qty: number) => {
    const quantity = { [item.guestTypes[0].id]: qty };
    const updatedUpsell = handleQuantityChange({ upsell: item, quantity });
    qty ? addToCartHandler([updatedUpsell]) : onDeleteCartItem();
  };

  const FirstElement = () => <AddButton onClick={() => onQuantityChange(1)} />;

  const SecondElement = () => (
    <QuantitySelector
      quantity={totalQuantity}
      handleQuantityChange={(qty) => onQuantityChange(qty)}
    />
  );

  return <BaseUpsellItem item={item} FirstElement={FirstElement} SecondElement={SecondElement} />;
};
