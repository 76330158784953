import { useEffect } from 'react';

import { useUpsell } from '../../contexts/upsellContext/upsellContext';
import { AttachmentType } from '../../contexts/upsellContext/upsell';

import { useInnerUpsell } from './useInnerUpsell';

/**
 * add required upsells to the cart directly when required upsell list is returned
 */
export const useAddRequiredUpsells = () => {
  const { upsells } = useUpsell();
  const totalQuantities = upsells.reduce((acc, item) => acc + item.totalQuantity, 0);

  const { addToCartHandler } = useInnerUpsell();

  useEffect(() => {
    const requiredUpsells = upsells.filter(
      (upsell) => upsell.configuration.attachmentType === AttachmentType.REQUIRED
    );

    if (!requiredUpsells.length) return;

    addToCartHandler(requiredUpsells);
  }, [totalQuantities, upsells.length]);
};
