// src/hooks/useProductApi.ts
import { useQuery } from '@tanstack/react-query';
import { giftCardProductApi, productApi } from '@guest-widgets/shared/src/apis/guestExperience';
import { useGetGiftCardParamId } from '@guest-widgets/shared/src/hooks/useGetGiftCardParamId';

import { useSettings } from '../../settingsContext/settingsContext';
import { SupportedCurrencies } from '../../settingsContext/settings';
import { queryKeys } from '../../../queryKeys';

import { mapToProduct } from './mapToProduct';

export const useProductApi = () => {
  const { locale, customerCode, currency, isLoading, productId } = useSettings();
  const hasParameters = Boolean(locale && customerCode && productId && currency && !isLoading);

  const { giftCardId } = useGetGiftCardParamId();

  const queryDeps = [
    giftCardId ? queryKeys.GIFTCARD : queryKeys.PRODUCT,
    locale,
    customerCode,
    giftCardId ?? productId,
    currency,
  ];

  const queryFn = async () => {
    let response;
    if (giftCardId) {
      response = await giftCardProductApi.getGiftCardDetails(giftCardId, customerCode, locale);
    } else {
      response = await productApi.getProduct(productId, customerCode, locale);
    }
    const mappedProduct = mapToProduct(
      response.price.gross.currencyCode as SupportedCurrencies,
      response
    );
    return mappedProduct;
  };

  return useQuery({ queryKey: queryDeps, queryFn, enabled: hasParameters });
};
