import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Text } from '@eo-locale/react';
import { addDays, set } from 'date-fns';
import { booking } from '@guest-widgets/shared/src/classes/booking';
import { useWidget } from '@guest-widgets/core';

import { Calendar } from '../../Product/Guest/Calendar';
import { QuantitySelection } from '../../Product/Guest/QuantitySelection';
import { Time } from '../../Product/Guest/Time';
import { TimeslotsContextProvider } from '../../contexts/timeslotsContext/timeslotsContext';
import { useUpsellInSelection } from '../upsellInSelectionContext';
import {
  ProductInSelectionProvider,
  useProductInSelection,
} from '../../Product/Guest/productInSelectionContext';
import { UpsellItem } from '../../contexts/upsellContext/upsell';
import {
  AvailabilityTime,
  AvailabilityTimeDayTicket,
} from '../../contexts/timeslotsContext/timeslots';

import { Subtotal } from './Subtotal';
import { useInnerDrawer } from './useInnerDrawer';

interface DateTimeQtyProps {
  item: UpsellItem;
}

const DateTimeQtyInner = ({ item }: DateTimeQtyProps) => {
  const { isMobileSize } = useWidget();
  const { handleQuantityChange, setAddToCartErrors } = useUpsellInSelection();
  const {
    requiresDateRange,
    setDate,
    date,
    setTime,
    time,
    quantity,
    currency,
    requiresTimeSelection,
  } = useProductInSelection();
  const { upsellApi } = useInnerDrawer();

  const { error, data } = upsellApi;
  const { error: pricingError } = data || {};
  const { subtotal } = data?.totals || {};
  const rateLimitError = ((error as Error)?.message ?? '').includes('429');
  const hasError = !!error || !!pricingError || rateLimitError;

  const quantityDependency = quantity
    ? Object.values(quantity).reduce((acc, value) => acc + value, 0)
    : 0;
  const { allocationType, configuration, parent, start, end } = item;
  const { uiSelectors, sameParentQuantity } = configuration;
  const showTime = uiSelectors.showSteps.includes('time');
  const showQuantity = uiSelectors.showSteps.includes('quantity');

  // Sets time to same as parent time when there is no time selector (not allowed change time cases)
  useEffect(() => {
    if (requiresTimeSelection && !showTime && start && end) {
      setTime({ startTime: new Date(start), endTime: new Date(end) });
    }
  }, []);

  // Sets time to the first and last available time when date is selected in case 30
  // (check https://checkfront.atlassian.net/wiki/spaces/Product/pages/2864939022/Upsells+support+in+Next-Gen+widgets)
  useEffect(() => {
    if (
      isParentAllocationTypeDay(parent) &&
      requiresTimeSelection &&
      !showTime &&
      !time &&
      date?.from
    ) {
      const availabilitiesFrom = date.from.availabilityTimes;
      const availabilitiesTo = date?.to?.availabilityTimes ?? date.from.availabilityTimes;
      const dateStart = isTimeDayTicket(availabilitiesFrom)
        ? availabilitiesFrom[0].timeRange.startTime
        : undefined;
      const dateEnd = isTimeDayTicket(availabilitiesTo)
        ? availabilitiesTo.at(-1)?.timeRange.endTime
        : undefined;

      setTime({ startTime: dateStart, endTime: dateEnd });
    }
  }, [date?.from?.day?.valueOf()]);

  // Updates upsell when date, time or quantity are chaged in modal
  useEffect(() => {
    if (quantity === undefined) return;

    const endDateRange =
      allocationType === 'day' && date?.to?.day ? addDays(date.to.day, 1) : date?.to?.day;
    // When we set a new date and time is already set, we need to merge them
    const mergeDateAndTime = (date: Date, time: Date) =>
      set(date, { hours: time.getHours(), minutes: time.getMinutes(), seconds: time.getSeconds() });

    const start =
      date?.from && time?.startTime
        ? mergeDateAndTime(date.from.day, time.startTime)
        : date?.from?.day;
    const end =
      endDateRange && time?.endTime
        ? mergeDateAndTime(endDateRange, time.endTime)
        : date?.from && time?.endTime // When allocation type is "timeslot" of "flextime" (not multi days), there is no "endDateRange" or "date.to" to be merged
        ? mergeDateAndTime(date.from.day, time.endTime)
        : endDateRange;

    handleQuantityChange({ upsell: item, quantity, start, end, isModal: true });
  }, [quantityDependency, date?.from?.day.valueOf(), date?.to?.day.valueOf()]);

  // Resets errors when date, time or quantity is changed
  useEffect(() => {
    setAddToCartErrors([]);
  }, [
    date?.from?.day.toLocaleDateString(),
    date?.to?.day.toLocaleDateString(),
    time?.startTime?.toLocaleTimeString(),
    time?.endTime?.toLocaleTimeString(),
    Object.values(quantity ?? {}).join('_'),
  ]);

  return (
    <>
      <Box pt={isMobileSize() ? 2 : 0} className={booking.steps}>
        <Typography variant="h2" mb={4} component="h2" className={booking.step.title}>
          <Text id="select-your-preferred-date-and-time" />
        </Typography>
        <Typography variant="h4" mb={4} component="h3" className={booking.step.title}>
          <Text id="date" />
        </Typography>
        <Calendar
          requiresDateRange={requiresDateRange}
          setDate={setDate}
          date={date}
          limitOfMonths={1}
          hasError={hasError}
          error={pricingError}
        />
        {showTime && <Time hasError={hasError} error={pricingError} />}
        {showQuantity && <QuantitySelection disableButtons={sameParentQuantity} />}
      </Box>
      <Subtotal price={!!quantity && subtotal ? subtotal.amount : 0} currency={currency} />
    </>
  );
};

export const DateTimeQty = ({ item }: DateTimeQtyProps) => (
  <TimeslotsContextProvider product={item}>
    <ProductInSelectionProvider product={item}>
      <DateTimeQtyInner item={item} />
    </ProductInSelectionProvider>
  </TimeslotsContextProvider>
);

const isTimeDayTicket = (
  availabilities: AvailabilityTime[]
): availabilities is AvailabilityTimeDayTicket[] => {
  const firstAvailability = availabilities[0] as AvailabilityTimeDayTicket;
  return firstAvailability !== undefined && firstAvailability.timeRange !== undefined;
};

const isParentAllocationTypeDay = (parent: UpsellItem['parent']) => {
  const { start, end } = parent;
  const isStartAtMidnight = start.includes('T00:00:00');
  const isEndAtMidnight = end.includes('T00:00:00');

  return isStartAtMidnight && isEndAtMidnight;
};
