import { Divider, styled } from '@mui/material';

import { useGiftCardFormValidation } from '../../hooks/product/giftcard/useGiftCardFormValidation';
import { useProduct } from '../../contexts/productContext/productContext';
import { Totals } from '../../common/Totals';
import { useInnerGiftCardProduct } from '../../hooks/product/giftcard/useInnerGiftCardProduct';

import { GiftcardProductNavigation } from './GiftcardNavigation';
import { GiftCardDeliveryInfoContainer } from './GiftCardDeliveryInfoContainer';
import { GiftCardAmountInput } from './GiftCardAmountInput';
import { GiftCardHeader } from './GiftCardHeader';
import { GiftCardFields } from './GiftCardFields';
import { fields } from './giftCardProductFields';

export const GiftCardProductInner = () => {
  const giftCardProduct = useProduct();
  const { register, handleSubmit, formState, setValue, getValues, watch } =
    useGiftCardFormValidation(
      giftCardProduct?.variablePrice?.min,
      giftCardProduct?.variablePrice?.max
    );

  const { addToCartHandler, addToCart } = useInnerGiftCardProduct(getValues);

  return (
    <>
      <GiftCardHeader />
      <form onSubmit={handleSubmit(addToCartHandler)} noValidate>
        <Wrapper>
          <GiftCardAmountInput
            giftCardProduct={giftCardProduct}
            formState={formState}
            register={register}
            amountField={fields[0]}
            setValue={setValue}
          />
        </Wrapper>
        <StyledDivider />
        <GiftCardFields
          register={register}
          formState={formState}
          fields={fields.slice(1)}
          watch={watch}
          setValue={setValue}
        />
        <StyledDivider />
        <GiftCardDeliveryInfoContainer
          giftCardData={giftCardProduct}
          register={register}
          setValue={setValue}
          formState={formState}
        />
        <StyledDivider />
        <Totals />
        <StyledDivider />
        <GiftcardProductNavigation actionStatus={addToCart} />
      </form>
    </>
  );
};

const StyledDivider = styled(Divider)(({ theme: { palette } }) => ({
  border: `1px solid ${palette.grey[400]}`,
  margin: '16px 0',
}));

const Wrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'grid',
  gap: spacing(4),
}));
