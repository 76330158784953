import {
  CompanyDto,
  CompanyEcommercePaymentProviderDtoIdEnum,
} from '@checkfront/guest-experience-api-api-client-javascript-axios';

export const isNextGenCheckoutEnabled = (company?: CompanyDto): boolean => {
  const getParam = new URLSearchParams(location.search).get('ng_payments');

  if (getParam !== null) {
    return getParam === '1';
  }

  if (!company?.ecommerce?.paymentProvider) {
    return false;
  }

  const {
    ecommerce: {
      paymentProvider: { id, onboardCompleted },
    },
  } = company;

  return id === CompanyEcommercePaymentProviderDtoIdEnum.NextGenPay && onboardCompleted;
};
