import { ChangeEvent, useState } from 'react';
import { useLocalStorage } from '@guest-widgets/shared/src/hooks/useLocalStorage';

import { useCart } from '../../contexts/cartContext/cartContext';
import { cartId } from '../../contexts/apiContext/cart/session/useSession';
import { ApiInvalidStructureException } from '../../contexts/apiContext/apiContext';

export const useDiscount = () => {
  const [discountErrorMessage, setDiscountErrorMessage] = useState<string | undefined>(undefined);
  const [localDiscountCode, setLocalDiscountCode] = useState<string | undefined>();

  const [[discountCode]] = useLocalStorage<string[]>(`${cartId}-discounts`, []);

  const { applyDiscountCode, removeDiscountCode } = useCart();

  const handleChangeCode = (e: ChangeEvent<HTMLInputElement>) => {
    if (discountErrorMessage) setDiscountErrorMessage(undefined);
    setLocalDiscountCode(e.target.value);
  };

  const applyCode = async () => {
    if (!localDiscountCode) return;

    try {
      const result = await applyDiscountCode.mutateAsync({ discountCode: localDiscountCode });
      if (result.discountIsValid) {
        setDiscountErrorMessage(undefined);
        setLocalDiscountCode('');
      } else {
        setDiscountErrorMessage('enter-valid-discount-code');
      }
    } catch (error) {
      if (error instanceof ApiInvalidStructureException && error.message.includes('code 400')) {
        return setDiscountErrorMessage('enter-valid-discount-code');
      }
      setDiscountErrorMessage('generic-error-message');
    }
  };

  return {
    applyCode,
    discountErrorMessage,
    localDiscountCode,
    discountCode,
    removeDiscountCode: () => removeDiscountCode.mutate(),
    handleChangeCode,
  };
};
