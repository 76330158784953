import {
  BasicField,
  BuyerAdditionalField,
  PerAttendeeAdditionalField,
  WaiverField,
  DropdownField,
  AdditionalField,
  SupportedInvoices,
  BasicTypes,
  basicTypes,
  CheckboxField,
  MultiCheckboxField,
  HtmlField,
  RadioField,
  CountryField,
} from './contact';
import { InvoiceNormal, InvoiceFattura } from './invoice';

type Field = BuyerAdditionalField | PerAttendeeAdditionalField;

export const isBuyerAdditionalField = (field: Field): field is BuyerAdditionalField =>
  Array.isArray(field);

export const isPerAttendeeAdditionalField = (field: Field): field is PerAttendeeAdditionalField =>
  !!Object.keys(field).length && !Array.isArray(field);

export const isBasicField = (field: AdditionalField): field is BasicField =>
  basicTypes.includes(field.type as BasicTypes);

export const isWaiverField = (field: AdditionalField): field is WaiverField =>
  !!(field as WaiverField).waiverText;

export const isDropDownField = (field: AdditionalField): field is DropdownField =>
  field.type === 'dropdown';

export const isCountryField = (field: AdditionalField): field is CountryField =>
  field.type === 'country';

export const isCheckboxField = (field: AdditionalField): field is CheckboxField =>
  field.type === 'checkbox';

export const isRadioField = (field: AdditionalField): field is RadioField => field.type === 'radio';

export const isMultiCheckboxField = (field: AdditionalField): field is MultiCheckboxField =>
  field.type === 'multicheckbox';

export const isHtmlField = (field: AdditionalField): field is HtmlField => field.type === 'html';

// TOS refers to Terms of Service.
// This function checks if the given field is a TOS (Terms of Service) checkbox field.
export const isTosField = (field: AdditionalField): field is CheckboxField =>
  field.id === 'customer_policy_agree' && Boolean(field.system) && isCheckboxField(field);

export const isInvoiceNormal = (invoice: SupportedInvoices): invoice is InvoiceNormal =>
  !isInvoiceFattura(invoice);

export const isInvoiceFattura = (invoice: SupportedInvoices): invoice is InvoiceFattura =>
  (invoice as InvoiceFattura).type !== undefined;
