import { useEffect } from 'react';

import {
  GuestTypeRestriction,
  TimeslotRules,
} from '../../contexts/apiContext/timeslot/dtos/response';
import { Quantity } from '../../contexts/apiContext/product/dtos/request';
import { useProductInSelection } from '../../Product/Guest/productInSelectionContext';
import { TimeslotDayRange } from '../../Product/Steps/Day/Calendar/CalendarRange/useCalendarRange';
import { GuestType } from '../../contexts/productContext/product';

function hasGuestTypeRestrictions(
  rules: any
): rules is { guestTypeRestrictions: GuestTypeRestriction[] } {
  return rules?.guestTypeRestrictions !== undefined;
}

const initializeDefaultQuantities = (guestTypes: GuestType[]) => {
  const initialQuantity: Quantity = {};
  guestTypes.forEach((guestType, index) => {
    initialQuantity[guestType.id] = index === 0 ? 1 : 0;
  });
  return initialQuantity;
};

const distributeTotalQuantity = (total: number, guestTypes: GuestType[]) => {
  const initialQuantity: Quantity = {};
  // get the base quantity to be applied to each guest type
  const baseQuantity = Math.floor(total / guestTypes.length);
  // calculate the remainder to be distributed across the guest types
  let remainder = total % guestTypes.length;

  guestTypes.forEach((guestType) => {
    initialQuantity[guestType.id] = baseQuantity;
  });

  // takes the first remainder elements from the array and and increment each quantity by one
  guestTypes.slice(0, remainder).forEach((guestType) => {
    initialQuantity[guestType.id]++;
  });

  return initialQuantity;
};

const applyGuestTypeRestrictions = (rules: TimeslotRules, guestTypes: GuestType[]): Quantity => {
  const initialQuantity: Quantity = {};

  // Check if at least one guest type has a min quantity
  const hasMinGuests = rules.guestTypeRestrictions.some((restriction) => restriction.minGuests);

  rules.guestTypeRestrictions.forEach((restriction, index) => {
    // If at least one guest type has a min quantity, use that quantity otherwise if its the first guest type
    // min quantity is 1 esle 0
    if (hasMinGuests) {
      initialQuantity[restriction.guestTypeId] = restriction.minGuests ?? (index === 0 ? 1 : 0);
    } else {
      initialQuantity[restriction.guestTypeId] = index === 0 ? 1 : 0;
    }
  });

  return initialQuantity;
};

export const useQuantityGuestRestrictions = (
  guestTypes: GuestType[] = [],
  date?: TimeslotDayRange
) => {
  const { setQuantity, quantityChanged } = useProductInSelection();

  const setDefaultQuantity = () => {
    let initialQuantity: Quantity = {};
    const availabilityTimes = date?.from?.availabilityTimes ?? date?.to?.availabilityTimes;

    if (Array.isArray(availabilityTimes) && availabilityTimes.length > 0) {
      const rules = availabilityTimes[0].rules as TimeslotRules;

      if (hasGuestTypeRestrictions(rules)) {
        // use in case of minimum quantity is applied to the sum of guesttypes quantities
        const totalGuestQuantityRestriction = rules.guestTypeRestrictions.find(
          (item) => item.guestTypeId === '_total'
        );
        if (!rules.guestTypeRestrictions || rules.guestTypeRestrictions.length === 0) {
          initialQuantity = initializeDefaultQuantities(guestTypes);
        } else if (!!totalGuestQuantityRestriction) {
          const totalMinGuests = totalGuestQuantityRestriction.minGuests || 0;
          initialQuantity = distributeTotalQuantity(totalMinGuests, guestTypes);
        } else {
          initialQuantity = applyGuestTypeRestrictions(rules, guestTypes);
        }
      }

      setQuantity(initialQuantity);
    }
  };
  useEffect(() => {
    // set default quantity only when quantity is not changed from quantity selector or minimum quantity
    if (!quantityChanged) setDefaultQuantity();
  }, [date, setQuantity, guestTypes, quantityChanged]);
};
