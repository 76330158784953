import { UpsellItem as UpsellItemType } from '../../contexts/upsellContext/upsell';
import { useRouter } from '../../contexts/routerContext/routerContext';

import { UpsellInCart } from './UpsellInCart';
import { SingleQty } from './SingleQty';
import { BaseUpsellItem } from './BaseUpsell';
import { RequiredInheritedHandler } from './RequiredInheritedHandler';

interface ItemUpsellPriceProps {
  item: UpsellItemType;
}

export const UpsellItem = ({ item }: ItemUpsellPriceProps) => {
  const { currentStep } = useRouter();

  if (currentStep === 'cart') {
    return <UpsellInCart item={item} />;
  }
  const { uiSelectors, sameParentQuantity, isRequired } = item.configuration;

  if (sameParentQuantity || isRequired) {
    return <RequiredInheritedHandler item={item} />;
  }

  if (uiSelectors.drawerLayout == 'singleQty') {
    return <SingleQty item={item} />;
  }

  return <BaseUpsellItem item={item} />;
};
