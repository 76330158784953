import { Box, TextField, styled, InputAdornment, Divider } from '@mui/material';
import { Text } from '@eo-locale/react';
import CalendarToday from '@mui/icons-material/CalendarToday';
import { Popper } from '@guest-widgets/shared/src/components/Calendar/Popper';
import { classes } from '@guest-widgets/shared/src/classes';
import { bindToggle, bindPopper } from 'material-ui-popup-state/hooks';
import { Calendar } from '@guest-widgets/shared/src/components/Calendar/Calendar';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormStateReturn,
  FieldValues,
  FieldError,
} from 'react-hook-form';
import { DetailGiftCardProductDtoStartDateTypeEnum } from '@checkfront/guest-experience-api-api-client-javascript-axios';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import { useSettings } from '../../contexts/settingsContext/settingsContext';
import { CalendarDayPicker } from '../Steps/Day/Calendar/CalendarDayPicker';
import { GuestProduct } from '../../contexts/productContext/product';
import { useGiftCardDelivery } from '../../hooks/product/giftcard/useGiftCardDelivery';
import { GiftCardFormState } from '../../hooks/product/giftcard/useGiftCardFormValidation';

import { DeliveryInfoGrid } from './DeliveryInfoGrid';

interface GiftCardProps {
  giftCardData: GuestProduct | null;
  register: UseFormRegister<GiftCardFormState>;
  setValue?: UseFormSetValue<GiftCardFormState>;
  formState: UseFormStateReturn<GiftCardFormState>;
  error?: FieldError;
}

export const GiftCardDeliveryInfoContainer = ({
  giftCardData,
  register,
  setValue,
  formState,
}: GiftCardProps) => {
  const { locale } = useSettings();
  const {
    selectedDate,
    popupState,
    onSelectDate,
    deliveryDurationInDays,
    getStartDate,
    getExpiration,
  } = useGiftCardDelivery(giftCardData, setValue);

  if (!giftCardData) return null;

  const deliveryInfos = [
    { title: <Text id="valid-from" />, value: getStartDate() },
    { title: <Text id="expired" />, value: getExpiration() },
  ];

  const endDateInfos = [
    { title: <Text id="valid-from" />, value: selectedDate ?? null },
    {
      title: <Text id="expired" />,
      value: (
        <>
          {deliveryDurationInDays()} <Text id={'after-delivery-date'} />
        </>
      ),
    },
  ];

  return (
    <>
      {giftCardData.startDateType === DetailGiftCardProductDtoStartDateTypeEnum.Delivery ? (
        <>
          <TextField
            required
            label={<Text id="select-delivery-date" />}
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={selectedDate ?? ''}
            {...bindToggle(popupState)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarToday />
                </InputAdornment>
              ),
            }}
            {...register('deliveryDate')}
            error={!!formState.errors.deliveryDate}
            helperText={formState.errors?.deliveryDate?.message || ''}
          />
          {selectedDate && (
            <>
              <StyledDivider />
              <DeliveryInfoGrid deliveryInfos={endDateInfos} />
            </>
          )}
          <Popper
            className={classes.booking.product.giftcard.deliveryDate}
            placement="bottom"
            popupState={popupState}
            {...bindPopper(popupState)}
          >
            <Box width={336}>
              <Calendar
                components={{
                  Day: (dayProps) => (
                    <CalendarDayPicker
                      disabled={differenceInCalendarDays(dayProps.date, new Date()) < 0}
                      {...dayProps}
                    />
                  ),
                }}
                language={locale}
                numberOfMonths={1}
                selected={(selectedDate as unknown) as Date}
                mode="single"
                onSelect={onSelectDate}
              />
            </Box>
          </Popper>
        </>
      ) : (
        <DeliveryInfoGrid deliveryInfos={deliveryInfos} />
      )}
    </>
  );
};

const StyledDivider = styled(Divider)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[400]}`,
  marginBlock: theme.spacing(4),
}));
