import { format, addMonths, parseISO } from 'date-fns';

import { TimeslotsRange } from '../../timeslotsContext/timeslots';
import { CartItem, CartState } from '../cart/cart';
import { LineItem } from '../product/dtos/request';

import { TimeslotRequest, UpsellsTimeslotRequest } from './dtos/request';

interface TimeslotRequestProps {
  productId: string;
  range: TimeslotsRange;
  showSoldOut: boolean;
  showUpsells?: boolean;
  cart?: CartState;
}

const mapCartItemToLineItem = (item: CartItem): LineItem => ({
  lineId: item.id.toString(),
  productId: Number(item.product.productId),
  start: item.start,
  end: item.end,
  guestTypes: item.guestTypes,
});

export const mapToTimeslotRequest = ({
  productId,
  range,
  showSoldOut,
  showUpsells,
  cart,
}: TimeslotRequestProps): TimeslotRequest => {
  return {
    start: format(range.from, 'yyyy-MM-dd'),
    end: format(range.to, 'yyyy-MM-dd'),
    productIds: [Number(productId)],
    showSoldout: showSoldOut,
    showUpsells,
    cart: cart && {
      lineItems: cart.items.map(mapCartItemToLineItem),
    },
  };
};

interface UpsellsTimeslotRequestProps {
  productId: string;
  range: TimeslotsRange;
  cart: CartState;
  parentLineId: string;
}

export const mapToUpsellsTimeslotRequest = ({
  productId,
  range,
  cart,
  parentLineId,
}: UpsellsTimeslotRequestProps): UpsellsTimeslotRequest => {
  return {
    start: format(range.from, 'yyyy-MM-dd'),
    end: format(range.to, 'yyyy-MM-dd'),
    upsellIds: [Number(productId)],
    parentLineId,
    cart: {
      lineItems: cart.items.map(mapCartItemToLineItem),
    },
  };
};

export const getRange = (dateFrom: string | Date, numberOfMonths: number) => {
  const startDate = typeof dateFrom === 'string' ? parseISO(dateFrom) : dateFrom;
  const endDate = addMonths(startDate, numberOfMonths);

  return {
    from: startDate,
    to: endDate,
  };
};
