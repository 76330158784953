import { Button, Collapse } from '@mui/material';
import { Text } from '@eo-locale/react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { classes } from '@guest-widgets/shared/src/classes';
import { ICON_BASE_SIZE, LoadingButton } from '@guest-widgets/shared/src/components/LoadingButton';

import { NavigationContainer, StyledErrorContainer } from '../../StyledSharedComponents';
import { useRouter } from '../../contexts/routerContext/routerContext';
import { ActionStatus, ApiInvalidStructureException } from '../../contexts/apiContext/apiContext';
import { ErrorMessage } from '../../Error';
import { useCart } from '../../contexts/cartContext/cartContext';
export interface NavigationProps {
  actionStatus: ActionStatus;
}

export const GiftcardProductNavigation = ({ actionStatus }: NavigationProps) => {
  const { setCurrentStep } = useRouter();
  const { hasItems } = useCart();

  const renderErrors = () => {
    if (actionStatus.error instanceof ApiInvalidStructureException)
      return (
        <ErrorMessage show={actionStatus.isError} message={actionStatus.error.message} mt={0} />
      );
    return <ErrorMessage show={actionStatus.isError} messageKey="server-unreachable" mt={0} />;
  };
  return (
    <NavigationContainer>
      <LoadingButton
        variant="contained"
        color="primary"
        size="large"
        iconSize={ICON_BASE_SIZE}
        loading={actionStatus?.isLoading}
        fullWidth
        type="submit"
        className={`${classes.booking.buttons.button} ${classes.booking.buttons.checkout}`}
      >
        <Text id="add-to-shopping-cart" />
      </LoadingButton>

      {actionStatus?.isError && <StyledErrorContainer>{renderErrors()}</StyledErrorContainer>}
      <Collapse in={hasItems} unmountOnExit>
        <Button
          startIcon={<ShoppingCartIcon />}
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => setCurrentStep('contact')}
          className={classes.booking.buttons.goToCart}
        >
          <Text id="go-to-cart" />
        </Button>
      </Collapse>
    </NavigationContainer>
  );
};
