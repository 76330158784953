import { styled, useWidget } from '@guest-widgets/core';
import { IconButton, Drawer as MuiDrawer, Theme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getFirstShadowDomChild } from '@guest-widgets/shared/src/utils/getFirstShadowDomChild';

import { useUpsellInSelection } from '../upsellInSelectionContext';

import { SimpleLayout } from './SimpleLayout';
import { DateTimeLayout } from './DateTimeLayout';
import { useInnerDrawer } from './useInnerDrawer';

interface DesktopDrawerProps {
  open: boolean;
}

export const DesktopDrawer = ({ open }: DesktopDrawerProps) => {
  const { setIsDrawerOpen } = useUpsellInSelection();
  const { withCalendar } = useInnerDrawer();
  const { element } = useWidget();

  return (
    <Drawer
      open={open}
      anchor="bottom"
      container={getFirstShadowDomChild(element)}
      showCalendar={withCalendar}
      disableEnforceFocus
    >
      <CloseButton onClick={() => setIsDrawerOpen(false)}>
        <CloseIcon />
      </CloseButton>
      {withCalendar ? <DateTimeLayout /> : <SimpleLayout />}
    </Drawer>
  );
};

const PAPER_CORNER_RADIUS = 16;

interface DrawerProps {
  showCalendar: boolean;
  theme: Theme;
}

const Drawer = styled(({ showCalendar, ...props }) => <MuiDrawer {...props} />)(
  ({ showCalendar, theme: { spacing, breakpoints } }: DrawerProps) => ({
    display: 'flex',
    position: 'relative',
    '& [class*="MuiDrawer-paper"]': {
      padding: spacing(4, 4, 0),
      height: `calc(100% - ${spacing(42)})`,
      margin: 'auto',
      borderTopLeftRadius: PAPER_CORNER_RADIUS,
      borderTopRightRadius: PAPER_CORNER_RADIUS,
      [breakpoints.up('md')]: {
        width: '66%',
        maxWidth: showCalendar ? 1256 : 616,
        minWidth: 616,
      },
      [breakpoints.down('md')]: {
        width: 'unset',
        maxWidth: 'unset',
        minWidth: 'unset',
      },
    },
  })
);

const CloseButton = styled(IconButton)(({ theme: { palette, shadows } }) => ({
  position: 'sticky',
  zIndex: 1,
  width: 'fit-content',
  top: 8,
  left: 8,
  backgroundColor: palette.background.paper,
  boxShadow: shadows[4],
  transition: 'box-shadow ease 0.3s',
  '&:hover': {
    backgroundColor: palette.background.paper,
    boxShadow: 'unset',
  },
}));
