import { PropsWithChildren, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider, UseQueryResult } from '@tanstack/react-query';

const buildQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

export const ApiContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [queryClient, setQueryClient] = useState<QueryClient | undefined>();

  useEffect(() => {
    setQueryClient(buildQueryClient());
  }, []);

  if (!queryClient) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
    </QueryClientProvider>
  );
};

/**Subset from UseQueryResult for handling only error, loading and success states */
export type ActionStatus = Pick<UseQueryResult, 'isError' | 'isSuccess' | 'isLoading' | 'error'> & {
  isFetching?: boolean;
};

/**exception generated due to invalid api request or response structure */
export class ApiInvalidStructureException extends Error {
  constructor(errorMessage?: string) {
    super(errorMessage);
  }
}
