import { InputAdornment, Box, styled, TextField } from '@mui/material';
import { UseFormRegister, UseFormSetValue, UseFormStateReturn } from 'react-hook-form';
import { useTranslator } from '@eo-locale/react';
import { useEffect } from 'react';

import { BasicField } from '../contexts/contactContext/contact';
import { GiftCardFormState } from '../hooks/product/giftcard/useGiftCardFormValidation';

interface CurrencyInputProps {
  field: BasicField;
  error?: boolean;
  helperText?: string;
  currency?: string;
  formState: UseFormStateReturn<GiftCardFormState>;
  register: UseFormRegister<GiftCardFormState>;
  setValue: UseFormSetValue<GiftCardFormState>;
  language?: string;
  amount: number;
  disabled?: boolean;
}

export const AmountCurrencyInput = ({
  currency = '$',
  field,
  error,
  language,
  amount,
  disabled,
  register,
  setValue,
  ...props
}: CurrencyInputProps) => {
  const { translate } = useTranslator();

  const sanitizeAmount = (value: number) => {
    return Math.floor(Number(value));
  };
  useEffect(() => {
    setValue('amount', sanitizeAmount(amount));
  }, [setValue, amount]);

  return (
    <InputWrapper>
      <StyledTextView
        type="number"
        error={error}
        {...register((field.name as any) ?? 'amount', {
          valueAsNumber: true,
        })}
        {...props}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
        onKeyDown={(e) => {
          if (e.key === 'e' || e.key === '.' || e.key === ',' || e.key === '-') {
            e.preventDefault();
          }
        }}
        {...props}
        InputLabelProps={{
          sx: () => ({
            fontWeight: 600,
            color: 'black !important',
          }),
        }}
        label={translate(field.label ?? '')}
        disabled={disabled}
        InputProps={{
          sx: () => ({
            '& input[type="number"]::-webkit-outer-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            '& input[type="number"]::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            '& input[type="number"]': {
              MozAppearance: 'textfield',
            },
          }),

          endAdornment: (
            <InputAdornment position="end">
              <CurrencyBox>{currency}</CurrencyBox>
            </InputAdornment>
          ),
        }}
      />
    </InputWrapper>
  );
};

const StyledTextView = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-root': {
    paddingRight: 0,
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: theme.palette.grey[100],
    cursor: 'not-allowed',
  },
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const CurrencyBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '41px',
  backgroundColor: theme.palette.grey[100],
  borderLeft: `1px solid ${theme.palette.divider}`,
  color: theme.palette.grey[500],
  paddingTop: '0.78em',
  paddingBottom: '0.78em',
}));
