import React, { useState } from 'react';
import {
  FormHelperText,
  Box,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  styled,
} from '@mui/material';
import { TextField as MuiTextField } from '@mui/material';
import { classes } from '@guest-widgets/shared/src/classes';

import { useContact } from '../../contexts/contactContext/contactFormContext';
import { RadioField } from '../../contexts/contactContext/contact';

interface RadioViewProps {
  field: RadioField;
}

export const RadioView = ({
  field: { id, tip, value, options, label, others, required, hidden, onChange, defaultValue },
}: RadioViewProps) => {
  const {
    form: { formState, setValue, register, getValues },
  } = useContact();

  const [otherValue, setOtherValue] = useState<string | null>(null);
  const [otherFieldChecked, setOtherFieldChecked] = useState(false);
  // if value is not in options, setup other value
  let formValue = String(getValues(`contact.${id}`));
  const [checkedValue, setCheckedValue] = useState<string | null>(formValue || null);

  const error = Boolean(formState.errors.contact?.[id]);
  const helperText = formState.errors.contact?.[id]?.message || tip;

  if (hidden) return null;

  if (formValue != 'undefined' && !options.map((item) => item.value).includes(formValue)) {
    setOtherValue(formValue);
    setOtherFieldChecked(true);
  } else {
    if (formValue != 'undefined') {
      setCheckedValue(formValue);
    } else if (defaultValue) {
      setCheckedValue(defaultValue);
    } else {
      setCheckedValue(options[0].value);
    }
  }

  return (
    <Box>
      <FormControl fullWidth variant="outlined" error={error}>
        <FormLabel color="secondary" required={required}>
          {label}
        </FormLabel>
        <RadioGroup defaultValue={value}>
          {options.map(({ value: optionValue, label }) => (
            <FormControlLabel
              key={optionValue}
              control={
                <Radio
                  color="primary"
                  checked={checkedValue === optionValue && !otherFieldChecked}
                  value={optionValue}
                  {...register(`contact.${id}`, {
                    onChange: (event) => {
                      // react hook form use classes internally and override 'this'
                      // so we need to bind the onChange function to the original 'this'
                      onChange?.onChange.bind(onChange)(event.target.value);
                      setValue(`contact.${id}`, event.target.value);
                      setCheckedValue(event.target.value);
                      setOtherFieldChecked(false);
                    },
                  })}
                />
              }
              label={label}
            />
          ))}
          {others && (
            <FormControlLabel
              control={
                <Radio
                  value={`${id}_other`}
                  color="primary"
                  checked={otherFieldChecked}
                  onChange={() => {
                    setOtherValue(otherValue || '');
                    setValue(`contact.${id}`, otherValue || '');
                    setCheckedValue(otherValue || '');
                    setOtherFieldChecked(true);
                  }}
                />
              }
              label={
                <TextField
                  variant="outlined"
                  className={classes.booking.field.field}
                  value={otherValue}
                  onChange={(event) => {
                    setValue(`contact.${id}`, event.target.value.trim());
                    setOtherValue(event.target.value.trim());
                    setOtherFieldChecked(true);
                  }}
                  InputProps={{
                    inputProps: {
                      className: classes.booking.input,
                    },
                  }}
                />
              }
            />
          )}
        </RadioGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

const TextField = styled(MuiTextField)({
  '& input::-webkit-calendar-picker-indicator': {
    filter: 'invert(0.5)',
  },
});
