import { ReactNode } from 'react';
import { styled } from '@guest-widgets/core';
import { Box } from '@mui/material';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

interface SelectorSwitcherProps {
  type: 'QuantitySelector' | 'Edit' | 'Add';
  children: ReactNode;
}

export const SelectorSwitcher = ({ type, children }: SelectorSwitcherProps) => {
  return (
    <SelectorWrapper>
      <SwitchTransition>
        <CSSTransition
          key={type}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
          classNames="slide"
        >
          {children}
        </CSSTransition>
      </SwitchTransition>
    </SelectorWrapper>
  );
};
const SelectorWrapper = styled(Box)({
  position: 'relative',
  width: 110,
  display: 'flex',
  justifyContent: 'flex-end',
  '& .slide-enter': {
    transform: 'translateX(100%)',
    opacity: 0,
  },
  '& .slide-enter-active': {
    transform: 'translateX(0)',
    opacity: 1,
    transition: 'transform 350ms, opacity 170ms',
  },
  '& .slide-exit': {
    transform: 'translateX(0)',
    opacity: 1,
  },
  '& .slide-exit-active': {
    transform: 'translateX(120%)',
    opacity: 0,
    transition: 'transform 350ms, opacity 170ms',
  },
});
