import { StoreApi } from 'zustand';
import { getNames, registerLocale } from 'i18n-iso-countries';
import enMessages from 'i18n-iso-countries/langs/en.json';
import { config } from '@guest-widgets/shared/src/config';
import { LanguageLocaleCode } from '@guest-widgets/shared/src/i18n/i18n';

import { namesMapper, LanguageMap, LanguageCode } from './namesMapper';
import { CountryStore } from './types';

const languageMap: LanguageMap = {
  en: () => Promise.resolve(enMessages),
  ...namesMapper,
};
export const getAvailableLanguage = (
  languageMap: LanguageMap,
  locale: LanguageLocaleCode
): LanguageCode => {
  const languageCode = locale.split('-')[0];
  if (languageCode in languageMap) return languageCode as LanguageCode;

  //otherwise fallbacks to default language
  return config.fallbackLanguage.split('-')[0] as LanguageCode;
};
export const createCountryActions = (set: StoreApi<CountryStore>['setState']) => ({
  fetchCountries: async (locale: LanguageLocaleCode) => {
    const language: LanguageCode = getAvailableLanguage(languageMap, locale);
    registerLocale(await languageMap[language]());
    const countries = Object.entries(getNames(language)).map(([value, label]) => ({
      label,
      value,
    }));
    set({ countries });
  },
});
