import { handleTrackingEvent } from '@guest-widgets/shared/src/utils/customerTracking/utils/trackingEventsHandler';
import { TrackingEventType } from '@guest-widgets/shared/src/utils/customerTracking/types';
import { UseFormGetValues } from 'react-hook-form';

import { useCart } from '../../../contexts/cartContext/cartContext';
import { useSession } from '../../../contexts/apiContext/cart/session/useSession';
import { CartItem } from '../../../contexts/apiContext/cart/cart';
import { useGiftCardProductInSelection } from '../../../Product/GiftCard/giftCardInSelectionContext';
import { nextStep } from '../../../stores/router/actions';
import { formatDate } from '../../../contexts/apiContext/product/formatDate';

import { GiftCardFormState } from './useGiftCardFormValidation';

export const useInnerGiftCardProduct = (getValues: UseFormGetValues<GiftCardFormState>) => {
  const { addToCart } = useCart();
  const { generateItemId } = useSession();
  const giftcard = useGiftCardProductInSelection();

  const addToCartHandler = () => {
    const obj: CartItem = {
      id: generateItemId(),
      start: formatDate(new Date()),
      end: formatDate(new Date()),
      guestTypes: { qty: 1 },
      product: {
        name: giftcard.name,
        productId: giftcard.productId,
        brand: giftcard.companyInformation?.name || '',
        image: giftcard.images?.[0],
        type: 'giftcard',
      },
      giftCardFields: getValues(),
      price: {
        total: {
          amount: giftcard.price || 0,
          currencyCode: giftcard.currency,
        },
      },
    };

    addToCart.mutate([obj], {
      onSuccess: ({ cartWithSteps }) => {
        const targetedItem = cartWithSteps.cart.items.find(
          (item) => item.product.productId === obj.product.productId
        );

        if (!targetedItem) return;

        const item = { ...targetedItem, guestTypes: obj.guestTypes };
        handleTrackingEvent(TrackingEventType.ADD_TO_CART, {
          item,
          currency: giftcard.currency,
          totalAmount: cartWithSteps?.cart?.totals?.subtotal?.gross?.amount,
        });
        nextStep();
      },
    });
  };

  return {
    addToCartHandler,
    name: giftcard.name,
    currency: giftcard.currency,
    addToCart,
  };
};
