import {
  Step as MuiStep,
  StepConnector as MuiStepConnector,
  Stepper as MuiStepper,
  Theme,
  StepButton as MuiStepButton,
  Grow,
  Typography,
} from '@mui/material';
import { Text, useTranslator } from '@eo-locale/react';
import { booking } from '@guest-widgets/shared/src/classes/booking';
import {
  ActiveStepIcon,
  InactiveStepIcon,
  CompleteStepIcon,
} from '@guest-widgets/shared/src/components/Icons/StepperIcons';
import { ContainersArea, styled, useWidget } from '@guest-widgets/core';

import { useRouter } from './contexts/routerContext/routerContext';
import { Step } from './contexts/routerContext/router';

export const Stepper = () => {
  const { steps, currentStep, setCurrentStep, stepBefore } = useRouter();
  const { widgetArea } = useWidget();
  const navigableSteps = steps.filter(
    (step) => !['upsell', 'product', 'purchaseFailure', 'purchaseSuccess'].includes(step)
  );
  const { translate } = useTranslator();
  const currentStepIndex = navigableSteps.indexOf(currentStep);

  // Mapping to css class
  const getCssClassName = (stepName: string): string => {
    return stepName === 'checkout'
      ? booking.progress.payment
      : stepName === 'cart'
      ? booking.progress.cart
      : stepName === 'contact'
      ? booking.progress.contact
      : '';
  };

  const CustomStepIcon = (active: boolean, completed: boolean) => {
    if (active) {
      return (
        <ActiveStepIcon className={`${booking.progress.icon} ${booking.progress.iconActive}`} />
      );
    }
    if (completed) {
      return (
        <CompleteStepIcon
          className={`${booking.progress.icon} ${booking.progress.iconCompleted}`}
        />
      );
    }
    return (
      <InactiveStepIcon className={`${booking.progress.icon} ${booking.progress.iconDisabled}`} />
    );
  };

  const renderMobileLabel = (
    <StepLabel variant="body2" className={booking.progress.label}>
      <Text
        id="step-variable-of-variable"
        current={currentStepIndex + 1}
        total={navigableSteps.length}
      />
      :&nbsp;<strong>{translate(mapToTranslateKey(currentStep))}</strong>
    </StepLabel>
  );

  const renderStepper = (
    <>
      <CustomStepper
        activeStep={currentStepIndex}
        alternativeLabel={!widgetArea.isSmall}
        connector={<StepConnector />}
        className={booking.checkout.progress}
      >
        {navigableSteps.map((step, index) => (
          <MuiStep key={step} className={getCssClassName(step)}>
            <StepButton
              active={step === currentStep}
              icon={CustomStepIcon(step === currentStep, index < currentStepIndex)}
              onClick={() => setCurrentStep(step)}
            >
              {!widgetArea.isSmall && (
                <div className={booking.progress.label}>{translate(mapToTranslateKey(step))}</div>
              )}
            </StepButton>
          </MuiStep>
        ))}
      </CustomStepper>
      {widgetArea.isSmall && renderMobileLabel}
    </>
  );

  /** Check if loaded steps are already set by setSteps */
  const stepsAreLoaded = steps.length > 3;

  return stepBefore === 'product' || stepBefore === currentStep ? (
    <Grow in={stepsAreLoaded}>
      <div style={{ display: 'contents' }}>{renderStepper}</div>
    </Grow>
  ) : (
    renderStepper
  );
};

const mapToTranslateKey = (step: Step) => {
  const mapper: Partial<Record<Step, string>> = {
    checkout: 'payment',
  };

  return mapper[step] || step;
};

type StepButtonProps = {
  active: boolean;
  theme: Theme;
} & ContainersArea;

const CustomStepper = styled(MuiStepper)(({ widgetArea: { basedOnSize }, theme: { spacing } }) => ({
  padding: spacing(4, 0, 0, 0),
  '& > div': {
    paddingLeft: basedOnSize(2, 8),
    paddingRight: basedOnSize(2, 8),
  },
  '& > div:first-of-type': {
    paddingLeft: basedOnSize(0, 8),
  },
  '& > div:last-child': {
    paddingRight: basedOnSize(0, 8),
  },
}));

const StepButton = styled(MuiStepButton)(
  ({ active, widgetArea, theme: { typography, palette } }: StepButtonProps) => ({
    '& svg': {
      fontSize: Number(typography.body1.fontSize) * 2 || 32,
    },
    [`& .${booking.progress.icon}`]: {
      color: palette.primary.main,
      marginRight: widgetArea.basedOnSize(-8, 'unset'),
    },
    [`& .${booking.progress.iconDisabled}`]: {
      color: 'inherit',
      opacity: 0.28,
    },
    [`& .${booking.progress.label}`]: {
      marginTop: -8,
      color: 'inherit',
      opacity: active ? 0.78 : 0.68,
      fontWeight: active ? '600' : '400',
    },
  })
);

const StepConnector = styled(MuiStepConnector)(({ theme: { typography } }) => ({
  top: Number(typography.body1.fontSize) || 16,
}));

const StepLabel = styled(Typography)(({ theme: { spacing } }) => ({
  marginTop: spacing(2),
}));
