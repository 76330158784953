import axios, { AxiosError, AxiosResponse } from 'axios';

export const getApiErrorMessage = (e: unknown): string => {
  console.warn('error', e);
  if (e instanceof AxiosError && e.message) {
    return e.message;
  }
  if (e instanceof AxiosError && e.response?.data?.error?.message) {
    return e.response?.data.error.message;
  }
  if (e instanceof Error) {
    return e.message;
  }
  return 'Unknown error';
};

export const unwrap = async <T>(responsePromise: Promise<AxiosResponse<T>>): Promise<T> =>
  (await responsePromise).data;

// todo Temporary solution
// Remove after all requests to Checkfront API will be migrated to guest-experience-api
export const customerCodeToUrlRegistry: Record<string, string> = {};

/**
 * @deprecated Remove after all requests to Checkfront API will be migrated to guest-experience-api except booking-create.
 */
export const createAxiosInstance = (customerCode: string) => {
  return axios.create({
    baseURL: `https://${customerCodeToUrlRegistry[customerCode]}/api/4.0`,
  });
};

export const createAxiosInstanceGuest = (customerCode: string) => {
  return axios.create({
    baseURL: `${import.meta.env.VITE_GUEST_EXPERIENCE_URL}/api/v1`,
    headers: {
      'x-partner-code': customerCode,
    },
  });
};
