import { styled, useWidget } from '@guest-widgets/core';
import { alpha, Box, IconButton, Drawer as MuiDrawer, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';
import { getFirstShadowDomChild } from '@guest-widgets/shared/src/utils/getFirstShadowDomChild';

import { useUpsellInSelection } from '../upsellInSelectionContext';
import { useUpsell } from '../../contexts/upsellContext/upsellContext';
import ItemDeleteDialog from '../../common/ItemDeleteDialog';
import { ChipInfo } from '../../common/ChipInfo';

import { Navigation } from './Navigation';
import { ImageWithFallback } from './ImageWithFallback';
import { SimpleQty } from './SimpleQty';
import { DateTimeQty } from './DateTimeQty';
import { useInnerDrawer } from './useInnerDrawer';
import { TimeQty } from './TimeQty';

interface MobileDrawerProps {
  open: boolean;
}

export const MobileDrawer = ({ open }: MobileDrawerProps) => {
  const { hasError } = useUpsell();
  const { element } = useWidget();
  const {
    upsellInModal,
    setIsDrawerOpen,
    addToCartErrors,
    isUpsellInCart,
  } = useUpsellInSelection();
  const {
    withCalendar,
    rateLimitError,
    removeFromCart,
    addToCart,
    openRemoveDialog,
    onCloseRemoveDialog,
    onOpenRemoveDialog,
  } = useInnerDrawer();
  const {
    totalQuantity,
    price,
    images,
    name,
    currency,
    companyInformation,
    information,
    configuration,
  } = upsellInModal;
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);

  const { uiSelectors, sameParentQuantity, isRequired } = configuration;
  const { body } = information?.description || {};
  const itemPrice = price ? formatPrice(price, currency) : '';
  const { isInCart, hasChanged } = isUpsellInCart(upsellInModal);
  const disableAddToCart =
    hasError || !totalQuantity || (sameParentQuantity && isInCart && !hasChanged);

  return (
    <Drawer open={open} anchor="bottom" container={getFirstShadowDomChild(element)}>
      <Header>
        <Typography variant="h3" my={2} pr={4}>
          {name}
        </Typography>
        <CloseButton onClick={() => setIsDrawerOpen(false)}>
          <CloseIcon />
        </CloseButton>
      </Header>
      <Box sx={{ flexGrow: 1 }}>
        <ChipInfo isInherited={sameParentQuantity} isRequired={isRequired} />
        <Box mb={4}>
          <ImageWithFallback src={images?.[0]?.url ?? ''} alt={images?.[0]?.alt} />
        </Box>
        {body && <Description variant="body1" pb={4} dangerouslySetInnerHTML={{ __html: body }} />}
        {withCalendar ? (
          <DateTimeQty item={upsellInModal} />
        ) : uiSelectors.drawerLayout === 'singleQty' && !sameParentQuantity ? (
          <SimpleQty item={upsellInModal} itemPrice={itemPrice} />
        ) : (
          <TimeQty item={upsellInModal} />
        )}
      </Box>
      <Navigation
        parentErrors={addToCartErrors}
        disabled={disableAddToCart}
        rateLimitError={rateLimitError}
        showRemoveButton={isInCart}
        onAddToCart={addToCart}
        onRemoveFromCart={onOpenRemoveDialog}
      />
      <ItemDeleteDialog
        open={openRemoveDialog}
        onClose={onCloseRemoveDialog}
        onConfirm={removeFromCart}
        productName={upsellInModal.name}
        rateLimitError={rateLimitError}
      />
    </Drawer>
  );
};

const PAPER_CORNER_RADIUS = 16;

const Drawer = styled(MuiDrawer)(({ theme: { spacing } }) => ({
  position: 'relative',
  '& [class*="MuiDrawer-paper"]': {
    padding: spacing(0, 4),
    height: `calc(100% - ${spacing(20)})`,
    borderTopLeftRadius: PAPER_CORNER_RADIUS,
    borderTopRightRadius: PAPER_CORNER_RADIUS,
  },
}));

const Header = styled(Box)(({ theme: { spacing, palette } }) => ({
  position: 'sticky',
  top: 0,
  left: 0,
  backgroundColor: palette.background.paper,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  padding: spacing(2, 0),
  zIndex: 1,
}));

const CloseButton = styled(IconButton)(({ theme: { palette, shadows } }) => ({
  backgroundColor: alpha(palette.background.paper, 0.5),
  transition: 'box-shadow ease 0.3s',
  '&:hover': {
    backgroundColor: palette.background.paper,
    boxShadow: shadows[4],
  },
}));

const Description = styled(Typography)({
  '& p': {
    marginBlockStart: 'unset',
    marginBlockEnd: 'unset',
  },
});
