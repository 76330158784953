import { Chip } from '@mui/material';
import InformationIcon from '@mui/icons-material/InfoRounded';
import { useTranslator } from '@eo-locale/react';
import { styled } from '@guest-widgets/core';
import { useGetUpsellMessageKeys } from '../hooks/useGetUpsellMessageKeys';
type Props = {
  isRequired?: boolean;
  isInherited?: boolean;
};
export const ChipInfo = ({ isRequired = false, isInherited = false }: Props) => {
  const { translate } = useTranslator();
  const { messageKeys } = useGetUpsellMessageKeys(isRequired, isInherited);

  if (!Array.isArray(messageKeys)) {
    return null;
  }
  return (
    <KeysWrapper>
      {messageKeys.map((label) => (
        <InfoChip
          key={label}
          label={translate(label)}
          size="small"
          color="info"
          icon={<InformationIcon />}
        />
      ))}
    </KeysWrapper>
  );
};

const KeysWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: spacing(2),
  marginBottom: 8,
}));
const InfoChip = styled(Chip)(({ theme }) => ({
  paddingInline: 4,
  borderRadius: 4,
  color: theme.palette.text.secondary,
}));
