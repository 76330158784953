import { Box, Typography, styled } from '@mui/material';

interface DeliveryInfo {
  title: React.ReactNode;
  value: React.ReactNode;
}

interface DeliveryInfoGridProps {
  deliveryInfos: DeliveryInfo[];
}

export const DeliveryInfoGrid = ({ deliveryInfos }: DeliveryInfoGridProps) => {
  return (
    <Wrapper>
      {deliveryInfos.map((info, index) => (
        <Box display="grid" key={index} gap={4} height="100%">
          <PrimaryText fontWeight="bold">{info.title}</PrimaryText>
          <SecondaryText color="text.secondary">{info.value}</SecondaryText>
        </Box>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: spacing(2),
  alignItems: 'center',
}));

const SecondaryText = styled(Typography)(({ theme: { typography } }) => ({
  fontSize: typography.subtitle1.fontSize,
  fontWeight: 400,
}));

const PrimaryText = styled(Typography)(({ theme: { typography } }) => ({
  fontSize: typography.body1.fontSize,
  fontWeight: 600,
}));
