import { useEffect } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { Text } from '@eo-locale/react';
import { booking } from '@guest-widgets/shared/src/classes/booking';
import { useWidget } from '@guest-widgets/core';

import { useUpsell } from '../contexts/upsellContext/upsellContext';
import { Totals } from '../common/Totals';
import { useInnerUpsell } from '../hooks/upsell/useInnerUpsell';
import { LoadingContainer } from '../StyledSharedComponents';
import { useAddRequiredUpsells } from '../hooks/upsell/useAddRequiredUpsells';
import { useRouter } from '../contexts/routerContext/routerContext';

import { Navigation } from './Navigation';
import { UpsellInSelectionProvider, useUpsellInSelection } from './upsellInSelectionContext';
import { MobileDrawer } from './Drawer/MobileDrawer';
import { DesktopDrawer } from './Drawer/DesktopDrawer';
import { UpsellItem } from './ItemUpsellPrice/UpsellItem';

const UpsellInner = () => {
  const { upsells, cartStatus, hasUpsells, hasError, currentItem } = useUpsell();
  const { nextStep, previousStep, stepBefore } = useRouter();
  const { upsellInModal, isDrawerOpen, addToCartErrors } = useUpsellInSelection();
  const { isMobileSize } = useWidget();

  // Show upsell step or not
  const { isSelectedUpsell, isLoading, upsellStatus } = useInnerUpsell();
  //add required upsells to the cart when its supported
  useAddRequiredUpsells();

  useEffect(() => {
    if (!currentItem) return;

    const { isSuccess, isFetching } = cartStatus;
    const hasNoUpsellsToShow = isSuccess && !isFetching && !hasUpsells;

    // If cart is successfully updated and there are no upsells to show, go to the next step
    if (hasNoUpsellsToShow) {
      stepBefore === 'product' ? nextStep() : previousStep();
    }
  }, [cartStatus.isSuccess, cartStatus.isFetching, currentItem]);

  const hasUpsellInModal = !!Object.values(upsellInModal).length;
  const rateLimitError = (upsellStatus?.error as Error)?.message.includes('429');

  return !!upsells.length ? (
    <Box pt={4} className={`${booking.upsell.root}`}>
      <Typography variant="h2" mb={4}>
        <Text id="complete-your-booking" />
      </Typography>
      <Grid container spacing={4}>
        {upsells.map((item) => (
          <Grid key={item.productId} item xs={12} sx={{ position: 'relative' }}>
            <UpsellItem item={item} />
            {isSelectedUpsell(item) && isLoading && (
              <LoadingContainer pt={4}>
                <CircularProgress size={50} />
              </LoadingContainer>
            )}
          </Grid>
        ))}
      </Grid>
      <Totals />
      <Navigation
        actionStatus={[cartStatus, upsellStatus]}
        parentErrors={addToCartErrors}
        disabled={hasError}
        rateLimitError={rateLimitError}
      />
      {hasUpsellInModal &&
        (isMobileSize() ? (
          <MobileDrawer open={isDrawerOpen} />
        ) : (
          <DesktopDrawer open={isDrawerOpen} />
        ))}
    </Box>
  ) : null;
};

export const Upsell = () => (
  <UpsellInSelectionProvider>
    <UpsellInner />
  </UpsellInSelectionProvider>
);
