import { addDays, startOfDay } from 'date-fns';

export interface Interval {
  start: string;
  end: string;
}

export const mapAllocationToInterval = (
  key: string,
  parentStart: string,
  parentEnd: string,
  sameParentDate: boolean
): Interval | undefined => {
  const setZeroTime = (date: string) => startOfDay(new Date(date)).toISOString();
  const addOneDay = (date: string) => addDays(new Date(date), 1).toISOString();

  const defaultInterval: Interval = { start: parentStart, end: parentEnd };

  const parentDateSpecialCases: Record<string, Interval> = {
    'timeslot,day,false': {
      start: setZeroTime(parentStart),
      end: addOneDay(setZeroTime(parentEnd)),
    },
    'flextime,day,false': {
      start: setZeroTime(parentStart),
      // TODO: Check (using product cases) if it is valid for "night" allocation type
      end: addOneDay(setZeroTime(parentEnd)),
    },
  };

  const noParentDateSpecialCases: Record<string, Interval> = {
    'timeslot,timeslot,false': defaultInterval,
    'timeslot,flextime,false': defaultInterval,
    'flextime,flextime,false': defaultInterval,
  };

  return sameParentDate
    ? parentDateSpecialCases[key] ?? defaultInterval
    : noParentDateSpecialCases[key];
};
