import { MutationKey, useMutation } from '@tanstack/react-query';

import { AddToCartParams } from './useAddToCart';

interface SetBookingParams extends AddToCartParams {}

export const useSetBookingId = ({ cartSession }: SetBookingParams) => {
  const mutationKey: MutationKey = ['bookingId'];

  const mutationFn = async (bookingId: string | undefined) => {
    return cartSession.setBookingId(bookingId);
  };

  return useMutation({ mutationKey, mutationFn });
};
