import React from 'react';
import {
  FormHelperText,
  Box,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
} from '@mui/material';

import { useContact } from '../../contexts/contactContext/contactFormContext';
import { MultiCheckboxField } from '../../contexts/contactContext/contact';

interface MultiCheckboxViewProps {
  field: MultiCheckboxField;
}

export const MultiCheckboxView = ({
  field: { id, tip, options, label, required, value, hidden },
}: MultiCheckboxViewProps) => {
  const {
    form: { formState, setValue, getValues },
  } = useContact();

  const error = Boolean(formState.errors.contact?.[id]);
  const helperText = formState.errors.contact?.[id]?.message || tip;

  if (hidden) return null;

  return (
    <Box>
      <FormControl fullWidth variant="outlined" error={error}>
        <FormLabel required={required}>{label}</FormLabel>
        <FormGroup color="secondary">
          {options.map(({ value: optionValue, label }) => (
            <FormControlLabel
              key={optionValue}
              control={
                <Checkbox
                  color="primary"
                  value={optionValue}
                  defaultChecked={value?.includes(optionValue)}
                  onChange={(e) => {
                    const value = e.target.value;
                    let newValues = getValues(`contact.${id}`) as Array<string>;
                    if (e.target.checked && !newValues.includes(value)) {
                      newValues = [...newValues, value];
                    } else if (!e.target.checked && newValues.includes(value)) {
                      newValues = newValues.filter((val) => val !== value);
                    }
                    setValue(`contact.${id}`, newValues, { shouldValidate: true });
                  }}
                />
              }
              label={label}
            />
          ))}
        </FormGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};
