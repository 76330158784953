import { useState } from 'react';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { format } from 'date-fns';
import { UseFormSetValue } from 'react-hook-form';
import { useTranslator } from '@eo-locale/react';
import {
  DetailGiftCardProductDtoEndDateTypeEnum,
  DetailGiftCardProductDtoStartDateTypeEnum,
} from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { mapMomentToDateFormat } from '@guest-widgets/shared/src/mappers/momentToDateFnsMapper';
import { useCalendarLanguage } from '@guest-widgets/shared/src/components/Calendar/useCalendarLanguage';

import { GuestProduct } from '../../../contexts/productContext/product';
import { useSettings } from '../../../contexts/settingsContext/settingsContext';

import { GiftCardFormState } from './useGiftCardFormValidation';

export const useGiftCardDelivery = (
  giftCardData: GuestProduct | null,
  setValue?: UseFormSetValue<GiftCardFormState>
) => {
  const { translate } = useTranslator();
  const { locale } = useSettings();
  const calendarLanguage = useCalendarLanguage(locale);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const popupState = usePopupState({ variant: 'popper', popupId: 'giftcard-delivery' });

  const dateFormat = giftCardData?.companyInformation?.locale?.dateFormatMoment
    ? mapMomentToDateFormat(giftCardData.companyInformation.locale.dateFormatMoment)
    : 'yyyy-MM-dd';

  const formatDate = (date?: Date | string) =>
    date ? format(new Date(date), dateFormat, { locale: calendarLanguage }) : null;

  const deliveryDurationInDays = () => giftCardData?.endDateDuration?.value || 0;

  const onSelectDate = (selectedDay?: Date) => {
    if (!selectedDay) return;

    const formattedDate = formatDate(selectedDay);
    setSelectedDate(formattedDate);
    setValue?.('deliveryDate', format(selectedDay, "yyyy-MM-dd'"));
    popupState.setOpen(false);
  };

  const getStartDate = () => {
    if (giftCardData?.startDateType === DetailGiftCardProductDtoStartDateTypeEnum.Fixed) {
      return formatDate(giftCardData.startDateFixed);
    }
    if (giftCardData?.startDateType === DetailGiftCardProductDtoStartDateTypeEnum.Purchase) {
      return translate('purchase');
    }
    return null;
  };

  const getExpiration = () => {
    if (giftCardData?.endDateType === DetailGiftCardProductDtoEndDateTypeEnum.Never) {
      return translate('never');
    }
    if (giftCardData?.endDateType === DetailGiftCardProductDtoEndDateTypeEnum.Fixed) {
      return formatDate(giftCardData.endDateFixed);
    }
    return null;
  };

  return {
    selectedDate,
    popupState,
    onSelectDate,
    deliveryDurationInDays,
    getStartDate,
    getExpiration,
  };
};
