import create from 'zustand';

import { CustomerPlatformStore, PlatformEnum } from './types';

export const useCustomerPlatformStore = create<CustomerPlatformStore>()((set) => ({
  customerPlatform: PlatformEnum.UNKNOWN,
}));

export const setCustomerPlatform = (platform: PlatformEnum) =>
  useCustomerPlatformStore.setState(() => ({ customerPlatform: platform }));
